import { useState } from "react";
import auth from "../../helpers/auth";
import review from "../../assets/images/referendum/review.svg";
import follow from "../../assets/images/referendum/follow.svg";
import kebabMenu from "../../assets/images/referendum/kebab-menu.svg";
import timerAmber from "../../assets/images/home/timer-amber.svg";
import timerGreen from "../../assets/images/home/timer-green.svg";
import timerGrey from "../../assets/images/home/timer-grey.svg";
import timerRed from "../../assets/images/home/timer-red.svg";

//Components
import Modal from "../Modal";
import ModalReview from "./ModalReview";
import ModalDelete from "./owner/ModalDelete";
import ModalExtendVoting from "./owner/ModalExtendVoting";
import axiosConfig from "../../helpers/axiosConfig";
import serverTimeHelper from "../../helpers/serverTimeHelper";
import {
  getVotingDuration,
  dateDisplayString,
  isPast,
  isPastByServer,
  dateNowByServer,
  getVotingDurationByServer,
  dateDisplayStringByServer,
} from "../../helpers/date";

import { useSelector } from "react-redux";

function ReferendumHeader(props) {

  const serverTime = serverTimeHelper.get();
  const initServerTime = serverTimeHelper.clientTime();

  const { referendum, setReferendum, isPreview } = props;
  const timers = {
    amber: timerAmber,
    green: timerGreen,
    grey: timerGrey,
    red: timerRed,
  }

  const [showReview, setShowReview] = useState(false);
  const [showOwnerAction, setShowOwnerAction] = useState(false);
  const [showDeleteAction, setShowDeleteAction] = useState(false);
  const [showExtendAction, setShowExtendAction] = useState(false);

  const [following, setFollowing] = useState(false);
  const [errorFollow, setErrorFollow] = useState(null);

  const [showSuggestedCategory, setShowSuggestedCategory] = useState(false);
  const isDue = isPreview ? isPastByServer(referendum.end_at, dateNowByServer(serverTime, initServerTime)) : referendum.is_past;
  
  const voting_duration = isDue ? { time_left: dateDisplayStringByServer(referendum.end_at), icon: "black" } : getVotingDurationByServer(referendum.end_at, serverTime, initServerTime);

  function handleCategories() {
    if (referendum.categories.length > 1) {
      setShowSuggestedCategory(!showSuggestedCategory);
    }
  }

  function handleReview() {

    resetHeader(['owner', 'categories'])
    if (!isPreview) {
      if (!auth.isOnline()) {
        document.getElementsByClassName("join-btn")[0].click();
      } else {
        setShowReview(!showReview);
      }
    }
  }

  function handleFollow(e) {
    resetHeader(['review', 'categories', 'owner'])
    if (!isPreview) {
      let errorTracker = errorFollow;

      if (!auth.isOnline()) {
        document.getElementsByClassName("join-btn")[0].click();
      } else {
        if (following) return;
        setFollowing(true);
        let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${referendum.slug}/follow`:`https://api.onepeople.online/op/v1/referendums/${referendum.slug}/follow`;
        axiosConfig
          .post(
            url,
            {},
            {
              headers: {
                Authorization: `Bearer ${auth.getToken()}`,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              // eslint-disable-next-line
              let followed = referendum.followed;
              setReferendum((old_ref) => {
                let new_ref = { ...old_ref };
                new_ref.followed = !old_ref.followed;
                followed = !old_ref.followed;
                return new_ref;
              });
            }
          })
          .catch((errs) => {
            let ers = errs.response.data.errors;
            auth.checkErrors(errs);
            if (ers) {
              ers.map((er) => {
                errorTracker = er.msg;
                return er;
              });
            } else {
              errorTracker = `There was a problem ${
                referendum.followed ? "unfollowing" : "following"
              } the referendum. Please referesh the page and try again.`;
            }
            setErrorFollow(errorTracker);
          })
          .finally(() => {
            setFollowing(false);
          });
      }
    }
  }

  function handleOwnerAction() {
    if (!isPreview) {
      setShowOwnerAction(!showOwnerAction);
    }
    resetHeader(['review', 'categories'])
  }

  function handleDeleteAction() {
    if (!isPreview) {
      setShowDeleteAction(true);
      setShowOwnerAction(false);
    }
  }

  function handleExtendAction() {
    if (!isPreview && !isDue) {
      setShowExtendAction(true);
      setShowOwnerAction(false);
    }
  }

  function resetHeader(headerActions = []) {
    if(headerActions.includes('review')) {
      setShowReview(false)
    }
    if(headerActions.includes('owner')) {
      setShowOwnerAction(false)
    }
    if(headerActions.includes('categories')) {
      setShowSuggestedCategory(false)
    }
  }
  return (
    <>
      <div className="ref-view-header px-2 px-xl-0 px-xxl-4">
        <div className="ref-date d-flex align-items-center text-wrap">
          <div className="ref-created me-2 me-lg-2 me-xl-2 text-black text-center">
            Created {dateDisplayStringByServer(referendum.created_at)}
          </div>
          <div className="ref-ended text-center">
            {!isDue && (
              <img
                src={timers[voting_duration.icon]}
                className="me-2 icon"
                alt="timer icon"
              />
            )}
            <span className={`text-${voting_duration.icon} ${isDue ? 'ref-past' : 'ref-active'}`}>
              {(isDue ? "Closed " : "") + voting_duration.time_left}
            </span>
          </div>
        </div>
        <div className="ref-cc d-flex align-items-center text-nowrap"  style={{
            "position": "relative", "left": "-.5rem"
          }}>
          <a
            href={`/explore/${
              referendum.country.value
                ? referendum.country.value
                : referendum.country.slug
            }/`}
            className="ref-country ms-2 px-2 px-md-2 px-lg-3 py-1 me-1"
          >
            {referendum.country.label
              ? referendum.country.label
              : referendum.country.country}
          </a>
          <div
            className={`ref-categories ${
              referendum.categories.length === 1 ? "ref-categories-solo" : ""
            } ${
              showSuggestedCategory ? "active" : ""
            } my-0 p-0 position-relative`}
          >
            <ul className="categories my-3" onClick={handleCategories}>
              {referendum.categories.map((category, index) => {
                return (
                  <li key={index} className="px-2 px-md-2 px-lg-3 py-1 m-0">
                    <a
                      href={`/explore/${
                        category.value ? category.value : category.slug
                      }/`}
                      className=""
                    >
                      {category.label ? category.label : category.category}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="ref-actions text-nowrap ms-auto ms-md-0">
          <button
            onClick={handleReview}
            className="ms-2 me-2 me-lg-1 me-xl-1 ps-2 pe-1 py-1 bounce"
          >
            <img
              src={review}
              className="me-1 icon"
              alt="review icon"
            />
          </button>
          <button
            onClick={handleFollow}
            className={`mx-2 mx-md-1 ps-2 pe-1 py-1 bounce ${
              referendum.followed ? "active" : ""
            }`}
            style={
              following
                ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                : {}
            }
          >
            <img
              src={follow}
              className="me-1 icon"
              alt="follow icon"
            />
          </button>
          {referendum.owner && (
            <div className="d-inline ref-action">
              <button
                type="button"
                onClick={handleOwnerAction}
                className="menu-icon mx-2 mx-md-1 px-1 py-1 bounce"
              >
                <img
                  src={kebabMenu}
                  className="icon"
                  alt="referendum menu icon"
                />
              </button>
              {showOwnerAction && (
                <div className={`ref-action-menu ${isDue ? 'past' : 'active'}`}>
                  <ul>
                    <li
                      className={`bounce ${isDue ? "mt-0" : ""}`}
                      onClick={handleDeleteAction}
                    >
                      Delete Referendum
                    </li>
                    {!isDue && (
                      <li className="bounce" onClick={handleExtendAction}>
                        Update Closing Date/Time
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showReview && (
        <Modal>
          
          <ModalReview
            titleSlug={referendum.slug}
            defaultCategory={referendum.category ?? undefined}
            setShowReview={setShowReview}
            flagged = {referendum.flagged}
          ></ModalReview>
        </Modal>
      )}

      {referendum.owner && showExtendAction && (
        <Modal>
          <ModalExtendVoting
            referendum={referendum}
            setReferendum={setReferendum}
            setShowExtendAction={setShowExtendAction}
          ></ModalExtendVoting>
        </Modal>
      )}

      {referendum.owner && showDeleteAction && (
        <Modal>
          <ModalDelete
            titleSlug={referendum.slug}
            setShowDeleteAction={setShowDeleteAction}
          ></ModalDelete>
        </Modal>
      )}
    </>
  );
}

export default ReferendumHeader;
