import { useState, useEffect } from "react";
import auth from "../../../helpers/auth";
import { motion } from "framer-motion";
import categoryCacheHelper from "../../../helpers/categoryCacheHelper";

//Components
import SelectReferendum from "../SelectReferendum";
import axiosConfig from "../../../helpers/axiosConfig";
function SuggestCategory(props) {
  // const { setReviewStatus } = props;
  const { titleSlug, defaultCategory } = props;
  const [status, setStatus] = useState("select");
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [suggesting, setSuggesting] = useState(false);

  const initErrors = {
    category: null,
    others: null,
  };
  const [errors, setErrors] = useState(initErrors);

  useEffect(() => {
    fetchCategories();
  }, 
  // eslint-disable-next-line
  []);

  async function fetchCategories() {
    let cacheCategory = categoryCacheHelper.get();
    if (categoryCacheHelper.get().length !== 0) {
      let cg = processCategories(cacheCategory);
      setCategories(cg);
      return;
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3001/op/v1/options/categories`: "https://api.onepeople.online/op/v1/options/categories";
    axiosConfig
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let cg = processCategories(res.data.categories);
          setCategories(cg);
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        setErrors({
          ...errors,
          others: "Something went wrong. Please try again.",
        });
      });
  }

  function processCategories(categories_response) {
    return categories_response.map((category, index) => {
      return { value: category.slug, label: category.category };
    });
  }

  function onChange(e) {
    setCategory(e);
    setErrors({ ...errors, category: null });
  }

  function onSubmit(e) {
    e.preventDefault();
    setErrors(initErrors);

    let errorTrackers = { ...errors };
    let hasErrors = false;

    if (category === null) {
      errorTrackers.category = "Please select a category";
      hasErrors = true;
    } else if (
      defaultCategory !== undefined &&
      category.value === defaultCategory.slug
    ) {
      errorTrackers.category =
        "You may not suggest an already default category.";
      hasErrors = true;
    }

    setErrors(errorTrackers);
    if (hasErrors) return;

    if (suggesting) return;
    setSuggesting(true);

    const ref_data = {
      category: category.value,
    };
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${titleSlug}/suggest-category`: `https://api.onepeople.online/op/v1/referendums/${titleSlug}/suggest-category`;
    axiosConfig
      .post(
        url,
        ref_data,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setStatus("selected");
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        let errs = err.response.data.errors;
        if (errs) {
          errs.map((er, index) => {
            let param = er.path;
            if (param === "category") {
              errorTrackers.category = er.msg;
            } else {
              errorTrackers.others = er.msg;
            }

            return er;
          });
        } else {
          errorTrackers.others = "Something went wrong. Please try again.";
        }
        setErrors(errorTrackers);
      })
      .finally(() => {
        setSuggesting(false);
      });
  }

  return (
    <>
      <form
        className={`position-relative d-flex align-items-center ${
          status === "selected"
            ? "justify-content-start"
            : "justify-content-center"
        } flex-column  w-100 h-100 pe-2 pe-md-2 pe-lg-5 border-box`}
      >
        <h4 className="px-1 text-black modal-sub-header">
          Suggest another category
        </h4>

        {status === "select" && (
          <>
            <div className="mt-3 w-100">
              <SelectReferendum
                inputId="ref_category"
                customClass="customizeSelect"
                options={categories}
                onChange={onChange}
                menuIsOpen={true}
                placeholder="Select Category"
                maxMenuHeight={"9.8rem"}
                defaultValue={category}
              />
            </div>
            <div className="text-center w-100 mt-2">
              {(errors.category || errors.others) && (
                <div className="text-start">
                  <div className="m-0 p-0">
                    {errors.category && (
                      <p className="m-0 p-0 input-text input-error text-red">
                        {errors.category}
                      </p>
                    )}
                    {errors.others && (
                      <p className="m-0 p-0 input-text input-error text-red">
                        {errors.others}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="text-center  w-100" 
            style={{ marginTop: "10rem" }}
            >
              <motion.input
                initial={{}}
                animate={
                  suggesting
                    ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                    : {}
                }
                onClick={onSubmit}
                type="submit"
                name="submit"
                value="Submit Suggestion"
                className={`op-btn suggestion-btn bounce mt-3`}
              />
            </div>
          </>
        )}
        {status === "selected" && (
          <div className="mt-3">
            <p className="text-black">Thank you for your suggestion.</p>
          </div>
        )}
      </form>
    </>
  );
}

export default SuggestCategory;
