function Checkbox({ name, label, active = false, onChange = undefined }) {
  return (
    <div className="position-relative text-start checkbox-container">
      <label htmlFor={label}>{label}
        <input
          onChange={onChange}
          type="checkbox"
          name={name}
          id={label}
          checked={active}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}

export default Checkbox;
