import { motion } from "framer-motion";
import { useState } from "react";
import auth from "../../../helpers/auth";

//Components
import ModalHeader from "../../others/ModalHeader";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../../helpers/axiosConfig";
function ModalDelete(props) {
  const { titleSlug, setShowDeleteAction } = props;
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  const [isOpen, setIsOpen] = useState(true);
  const [step, setStep] = useState("delete");

  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const toggleOffInContainer = () => {
    setIsOpen(false);
    setTimeout(function () {
      if (step === "deleted") {
        navigate("/");
      } else {
        setShowDeleteAction(false);
      }
    }, 100);
  };

  function onSubmit(e) {
    e.preventDefault();

    let errorTracker = error;

    if (deleting) return;
    setDeleting(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${titleSlug}`:`https://api.onepeople.online/op/v1/referendums/${titleSlug}`;
    axiosConfig
      .delete(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setStep("deleted");
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        let ers = errs.response.data.errors;
        if (ers) {
          ers.map((er) => {
            errorTracker = er.msg;
            return er;
          });
        } else {
          errorTracker = `There was a problem deleting the referendum. Please referesh the page and try again.`;
        }
        setError(errorTracker);
      })
  }

  const StepFunction = ({ step }) => {
    switch (step) {
      case "deleted":
        return (
          <motion.div>
            <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="op-box p-3 py-5 delete-ref OPModalBox position-absolute mx-auto"
              style={{ minHeight: "auto" }}
            >
              <ModalHeader
                title="Delete Referendum"
                toggleOffInContainer={toggleOffInContainer}
              />
              <div
                className={`row pt-3 px-3 align-items-center justify-content-center`}
              >
                <div className={`col-12 px-5 text-center`}>
                  <p className="op-text mt-3 text-black">
                    This referendum has been deleted.
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
      case "delete":
        return (
          <motion.div>
            <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="op-box p-3 py-5 delete-ref OPModalBox position-absolute mx-auto"
            >
              <ModalHeader
                title="Delete Referendum"
                toggleOffInContainer={toggleOffInContainer}
              />
              <div
                className={`row pt-3 px-3 align-items-center justify-content-center`}
              >
                <form className="px-4">
                  <div className={`col-12 px-2 px-md-5 px-lg-5 text-center`}>
                    <p className="op-text mt-3 text-black">
                      Do you wish to delete this referendum?
                    </p>
                    <p className="op-text mt-3 text-black">
                      Note that deletion is permanent and cannot be undone.
                    </p>
                    <p className="op-text mt-3 text-black">
                      The link to this referendum will no longer be accessible.
                    </p>
                  </div>

                  {error && (
                    <div className="m-0 p-0 text-center">
                      <p className="m-0 p-0 input-text input-error text-red">
                        {error}
                      </p>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-5">
                    <motion.input
                      initial={{}}
                      animate={
                        deleting
                          ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                          : {}
                      }
                      onClick={onSubmit}
                      type="submit"
                      name="submit"
                      value="Delete Referendum"
                      className="bounce op-btn delete-btn"
                    />
                  </div>
                </form>
              </div>
            </motion.div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="OPModal position-absolute" onClick={toggleOffInContainer}>
        <StepFunction step={step} />
      </div>
    </>
  );
}
export default ModalDelete;
