import { motion } from "framer-motion";
import { useEffect, useState,useRef } from "react";
import OPSpinner from "../others/OPSpinner";
import auth from "../../helpers/auth";
import parse from "html-react-parser";
import moment from "moment";
import { setMessageValue } from "../../helpers/messageCounter";
import { useDispatch } from "react-redux";
import messageHelper from "../../helpers/messageHelper";
import axiosConfig from "../../helpers/axiosConfig"
import rightArrow from "../../assets/images/home/right-arrow.svg";

function MessagesDropDown({ setShowQuickMessage, showQuickMessage }) {
  const [loading, SetLoading] = useState(true);
  const [messages, SetMessages] = useState([]);
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [loadMore, setLoadMore] = useState(false);
  const [itemLoader, SetItemLoader] = useState(false);
  // const [pageNum,setPageNum] = useState(1);
  const messageContainer =  useRef();
  const dispatch = useDispatch();

  let itNum = 1;
  const getmessageCount = ()=>{
    
    try {
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3002/op/v1/messages/count":"https://api.onepeople.online/op/v1/messages/count";
      axiosConfig.get(url, {
      params: { id: auth.getId(),action:'refresh' },
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
      },
    }).then((e)=>{      
      dispatch(setMessageValue(e.data.count))
    }).catch((e)=>{
      
    })
    } catch (error) {
      console.log(error)
    }
  }

  const loadMessages = () => {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3002/op/v1/messages":"https://api.onepeople.online/op/v1/messages";
    axiosConfig
      .get(url, {
        params: { id: auth.getId(),page:itNum },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        // setPageNum(pageNum+1)
        itNum = itNum+1;
        SetLoading(false);

        SetMessages(e.data);
        getmessageCount()
        messageHelper.setUserMessage(e.data)
    
      })
      .catch((e) => {
        auth.checkErrors(e);
        SetLoading(false);
        setError(true);
      });
  };
  const clicked = () => {
    setShowQuickMessage(!showQuickMessage);
  };

  let isLastRow = false;
  const handleInfiniteScroll = async ()=>{
    let lastElTop = messageContainer.current.lastChild.offsetTop;
    let scrollTop = messageContainer.current.scrollTop + messageContainer.current.clientHeight + messageContainer.current.offsetTop 
    // console.log(lastElTop)
    if(scrollTop>= lastElTop){
      if(!isLastRow){
        await load_more();
      }
        
    } 
  
  }

  useEffect(() => {
    let savedMessage = messageHelper.getUserMessage();
     
    messageContainer.current.addEventListener('scroll',handleInfiniteScroll);
    if(savedMessage.length !== 0){
      
      SetLoading(false);
      SetMessages(savedMessage);
      dispatch(setMessageValue(0))
    }
    
    loadMessages()
    if (showQuickMessage === true) {
      setTimeout(function () {
        window.addEventListener("click", clicked);
      }, 300);
    }
    return () => {
      window.removeEventListener("click", clicked);
      // messageContainer.current.removeEventListener('scroll',handleInfiniteScroll);
    };
  }, 
  // eslint-disable-next-line
  []);

  let isLoading = false;
  
  const load_more = () => {
    SetItemLoader(true);
    
    if(isLastRow){
      return;
    }
    if(isLoading === true){
      return;
    }
    isLoading = true;
    try{
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "https//127.0.0.1:3002/op/v1/messages":"https://api.onepeople.online/op/v1/messages";
      axiosConfig
      .get(url, {
        params: { id: auth.getId(),page:itNum },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        isLoading = false;
        itNum = itNum+1;
        SetItemLoader(false);
        if(!e.data.length === 0){
          setLoadMore(true);
        }
         let data = e.data

         data.map((e)=>{
          if(e.hasNext === false){
            setLoadMore(false)
            isLastRow = true;
          }
          return e;
        })
        SetMessages((old_message)=>[...old_message,...data]);
        getmessageCount()
      })
      .catch((e) => {
        auth.checkErrors(e);
        SetLoading(false);
        setError(true);
      });
    }catch(e){
      console.log(e)
    }

    
  };
  const LoaderElement = () => {
    return (
      <div className="inbox-message" >
        <div className="text-center FS12 d-flex flex-column justify-content-center align-items-center py-2">
          <div><OPSpinner size={"xtrasmall"} /></div>
        </div>
      </div>
    );
  };
  const Loader = () => {
    return (
      <>
        <div className="flex-fill d-flex flex-column justify-content-center">
          <div
            className="text-center d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "12.5rem" }}
          >
            <OPSpinner width={30} />
            <div>Loading...</div>
          </div>
        </div>
      </>
    );
  };

  const ZeroMessages = () => {
    return (
      <div className="inbox-message">
        <div className="text-error text-gray text-center" style={{"marginTop" : '43%'}}>
          No Messages Found
        </div>
      </div>
    )
  }
  const MessageList = () => {
    let tmp = [];
   
  
    messages.map((e) => {
      if(e.hasNext !== undefined || e.hasNext === false){
       
       }else{
        tmp.push(
          <div className="inbox-message" key={e.id}>
            <div className="message FS14 fblack ">{parse(e.Messages)}</div>
            <div className="status fGray FS12">{e.status === 1 ? "new":moment(e.created_at).format("DD MMMM YYYY")}</div>
          </div>
        );
       }
       return e;
    });

    return tmp;
  };
  const InBox = () => {
    return (
      <>
        {error ? (
          <div className="inbox-message">
            <div className="text-error text-gray fblack mt-5 text-center">
              Oops! something went wrong!
            </div>
          </div>
        ) : messages.length > 1 ? (
          <>
            <MessageList />
            {itemLoader ? <LoaderElement /> : ""}
          </>
        ) : (
          <>
            <ZeroMessages />
            {itemLoader ? <LoaderElement /> : ""}
          </>
        )}
      </>
    );
  };
  return (
    <>
      <motion.div
        animate={{ height: "auto", top: "100%" }}
        className="quick-message-container op-box p-3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="d-flex flex-column  align-items-stretch">
          <div className="d-flex ">
            <div className="text-black">📬 Messages</div>
            <div className="d-flex flex-fill ps-2 justify-content-end align-items-end">
              <a
                href="/messages/"
                className="gp-header-btn d-flex align-items-center"
              >
                <img
                  src={rightArrow}
                  alt="group header btn"
                ></img>
              </a>
            </div>
          </div>
          <div className="d-flex flex-column flex-fill ">
            <div className="message-quick-box flex-fill mt-3 pe-0 mz-scroll" ref={messageContainer}>
              {loading ? <Loader /> : <InBox />}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
export default MessagesDropDown;
