import { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { dateDisplayStringByServer, getVotingDurationByOrigServer, dateNowByServer } from "../../helpers/date";
import { motion } from "framer-motion";
import auth from "../../helpers/auth";
import MetadataSetup from "../../components/MetadataSetup.js";

import { setSelectedCategory, setSelectedCountry} from "../../helpers/exploreSelection";
import { useDispatch, useSelector} from "react-redux";
//Components
import Suggestions from "../referendums/Suggestions";
import OPSpinner from "../../components/others/OPSpinner";
import axiosConfig from "../../helpers/axiosConfig";
import SortReferendumButton from "../../components/others/SortReferendumButton.js";
import Footer from "../../components/layouts/footer.js";
import { hasMainContentScroll, getSearchResultBodyHeight } from "../../helpers/jsHelper";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
function Follows() {
  const dispatch = useDispatch();
  


  const category = useSelector(state => state.catandcountry.category)
  // const country = useSelector(state => state.catandcountry.country)
  const [searchStatus, setSearchStatus] = useState("searching");
  // const searchRef = useRef(null);
  const initSuggestedCategory = {
    category: "random",
    slug: "random",
  };
  
  window.onbeforeunload = ()=>{
    dispatch(setSelectedCategory(null))
    dispatch(setSelectedCountry(null))
  }
  const [suggestedCategory, setSuggestedCategory] = useState(
    initSuggestedCategory
  );

  const [suggestionRefs, setSuggestionRefs] = useState([]);
  const [suggestionRefsStatus, setSuggestionRefsStatus] = useState("updating");

  // eslint-disable-next-line
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [loadRefStatus, setLoadRefStatus] = useState(null);
  const [loadRefIteration, setLoadRefIteration] = useState(null);
  const [prevRefLength, setPrevRefLength] = useState(null);

  const maxRefs = 15;

  const [showResultRef, setShowResultRef] = useState([]);
  // const [filterResult, setFilterResult] = useState([]);
  // const [showFocusFilterIcons, setShowFocusFilterIcons] = useState(false);

  const resultRef = useRef(null);
  
  //suggestions
  //hot follows
  const [hotFollowsStatus, setHotFollowsStatus] = useState("updating");
  const [hotFollowRefs, setHotFollowRefs] = useState([]);

  const [hasError, setHasError] = useState(false)

  const [sortByStarted, setSortByStarted] = useState('desc');
  const [sortByClosed, setSortByClosed] = useState('desc');

  const [activeSort, setActiveSort] = useState('started');

  window.addEventListener("resize", () => {
    hasMainContentScroll("search");
    getSearchResultBodyHeight();
  })
   
  useEffect(
    () => {
      resetSort();
      getSearchResultBodyHeight();
      getUserFollowedReferendums({});
      getHotFollowReferendums();
      setLoadedOnce(true);
    },
    // eslint-disable-next-line
    []
  );

  function resetSort() {
    setSortByStarted("desc");
    setSortByClosed("desc");
    setActiveSort("started");
  }
  
  function sortListBy (sortColumn) {
    if(searchStatus === 'updated') {
      let sortBy = sortColumn;
      let sort = sortColumn === 'started' ? sortByStarted : sortByClosed;
      setActiveSort(sortColumn);

      sort = sort === 'desc' ? 'asc' : 'desc';
      if(sortColumn === 'closed') {
        setSortByClosed(sort)
        setSortByStarted('desc')
      } else {
        setSortByStarted(sort)
        setSortByClosed('desc')
      }
      
      getUserFollowedReferendums({sort, sortBy});
    }
  }

  async function getUserFollowedReferendums({
    iteration = null, 
    sort = 'desc', 
    sortBy = 'started'
  }) {
    setHasError(false)
    if (iteration === null) {
      setSearchStatus("searching");
    } else {
      setLoadRefStatus("updating");
    }

    let query = "";

    if (iteration !== null) {
      // query += "&iteration=" + iteration +"&sort="+sort+"&sortBy="+sortBy;
      query += `&iteration=${iteration}&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}`
    } else  {
      setShowResultRef([]);
      // query += "&sort="+sort+"&sortBy="+sortBy;
      query += `&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}`;
    }

    let refLength = 0;
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3004/op/v1/referendums/search?user=followed" + query: "https://api.onepeople.online/op/v1/referendums/search?user=followed" + query;
    axiosConfig
      .get(url,
      {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoadRefIteration(iteration);
          let refs = res.data.referendums;
          refs.forEach((ref) => {
            let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
            let duration = getVotingDurationByOrigServer(ref.end_at, dateNow);
            ref.duration = duration;
          });
          setPrevRefLength(res.data.referendums.length);
          refLength = res.data.referendums.length;
          if (iteration !== null) {
            setShowResultRef((old_refs) => [...old_refs, ...refs]);
          } else {
            setShowResultRef(refs);
            getSuggestions(refs, "category", category);
            resetLoader();
          }
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        if(iteration !== null) {
          getSuggestions([], "category");
        } else {
          getSuggestions([], "category");
          setShowResultRef([])
        }
        setHasError(true)
      })
      .finally(() => {
        if (iteration !== null) {
          setLoadRefStatus("updated");
        } else {
          setSearchStatus("updated");
          if(refLength === maxRefs) {
            loadMore({bypass: true, sort, sortBy});
          }
        }
        hasMainContentScroll("search");
      });
  }

  async function getHotFollowReferendums() {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?   "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=hot_follows&limit=4": "https://api.onepeople.online/op/v1/referendums/suggestions?group=hot_follows&limit=4";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setHotFollowRefs);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        setupSuggestions([], setHotFollowRefs)
      })
      .finally(() => {
        setHotFollowsStatus("updated");
        hasMainContentScroll("search");
      });
  }

  const setupSuggestions = (refs, setRef) => {
    refs = refs.slice(0, 4).map((ref, index) => {
      let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
      let tempStatus = getVotingDurationByOrigServer(ref.end_at, dateNow);
      ref = { ...ref, ...tempStatus };
      return ref;
    });
    setRef(refs);
  };

  //Search through Title
  // function onKeyFilter(e) {
  //   const search_keywords = searchRef.current.value;
  //   if (search_keywords.length > 0) {
  //     let new_result = showResultRef.filter((ref) => {
  //       return (
  //         ref.title.toLowerCase().includes(search_keywords.toLowerCase()) ||
  //         ref.result.toLowerCase().includes(search_keywords.toLowerCase()) ||
  //         (ref.option &&
  //           ref.option.toLowerCase().includes(search_keywords.toLowerCase()))
  //       );
  //     });
  //     setFilterResult(new_result);
  //     setShowFocusFilterIcons(true);
  //   } else {
  //     setShowFocusFilterIcons(false);
  //     setFilterResult([]);
  //   }
  // }

  function loadMore({bypass = false, sort = null, sortBy = 'started'}) {
    if(!hasError) {
      if (bypass || prevRefLength === maxRefs) {
        if (loadRefStatus === "updating") return;
        setLoadRefStatus("updating");
  
        let iteration = bypass ? null : loadRefIteration;
        if (iteration === null) {
          iteration = 1;
        } else {
          iteration = iteration + 1;
        }
        let sort_obj = getSort();
        getUserFollowedReferendums({
          iteration, 
          sort: bypass ? sort : sort_obj.sort, 
          sortBy
        });
  
        // onKeyFilter();
      }
    }
  }

  function getSort() {
    let sort, sortBy = activeSort;
    if(activeSort === 'started') {
      sort = sortByStarted;
    } else {
      sort = sortByClosed;
    }
    return {sort, sortBy}
  }

  function retryLoadMore() {
    setHasError(false);
    loadMore(getSort());
  }

  function resetLoader() {
    setLoadRefIteration(null);
  }

  function getSuggestions(refs, action, query_category = null) {
    let request_suggestion = "random";
    let suggestedSlugInArray = [];
    let suggestedNameKey = {};
    
    if (refs.length > 0) {
      refs.forEach((ref) => {
        ref.categories.forEach((category) => {
          suggestedSlugInArray.push(category.slug);
          suggestedNameKey[category.slug] = category.category;
        });
      });
      const suggestedCtr = suggestedSlugInArray.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});

      const sortedCategories = Object.keys(suggestedCtr).sort(
        (a, b) => suggestedCtr[b] - suggestedCtr[a]
      );

      // console.log(sortedCategories);

      let suggested_category_slug;
      let suggested_category_name;

      if (action === "category") {
        if (sortedCategories.length > 0) {
          //get the dominant category after the selected category
          let highest = sortedCategories[0];

            suggested_category_slug = highest;
            suggested_category_name = suggestedNameKey[suggested_category_slug];
            setSuggestedCategory({
              category: suggested_category_name,
              slug: suggested_category_slug,
              url:
                "/explore?category=" +
                suggested_category_slug,
            });

            // request_suggestion = suggested_category_slug;
            request_suggestion = sortedCategories.toString();
           
        } else {
          setSuggestedCategory({
            category: "random",
            slug: "random",
            url: "/search/",
          });
        }
      } else {
        setSuggestedCategory({
          category: "random",
          slug: "random",
          url: "/search",
        });
      }
    } else {
      setSuggestedCategory({
        category: "random",
        slug: "random",
        url: "/search",
      });
    }
    requestSuggestion(request_suggestion);
  }

  function requestSuggestion(slug) {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?   "https://127.0.0.1:3004/op/v1/referendums/suggestions?categories=" +slug +"&limit=4": "https://api.onepeople.online/op/v1/referendums/suggestions?categories=" +slug +"&limit=4";
    axiosConfig
      .get(
        url,
          {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          }
      )
      .then((res) => {
        if (res.data.success) {
          let refs = res.data.referendums;
          setupSuggestions(refs, setSuggestionRefs);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        setupSuggestions([], setSuggestionRefs)
      })
      .finally(() => {
        setSuggestionRefsStatus("updated");
        hasMainContentScroll("search");
      });
  }

  function onScroll() {
    const scrollTop = resultRef.current.scrollTop;
    const scrollHeight = resultRef.current.scrollHeight;
    const clientHeight = resultRef.current.clientHeight;

    const isBottom = ((scrollTop + clientHeight) + ((scrollTop + clientHeight) * .05)) >= scrollHeight;
    if (isBottom) {
      if(searchStatus === 'updated') {
        loadMore(getSort());
      }
    }
  }

  // function onSubmitFilterSearch(e) {
  //   e.preventDefault();
  //   let search_val = searchRef.current.value;
  //   window.location.href = `/search${
  //     search_val.trim().length > 0 ? `?q=${search_val}` : "/"
  //   }`;
  // }

  return (
    <>
      <MetadataSetup 
        title="My Follows | OnePeople" 
        canonical="https://onepeople.online/my-follows/"
        description="My Followed Referendum Page of OnePeople Online"
      />
      <div className="main search">
        <div className="center row pb-3">
          <motion.div
            className="col-12 col-xxl-9 mt-1"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              style={{
                minHeight: "6.25rem",
                borderRadius: "1.875rem",
              }}
              className="search-container border-0 op-box ps-4 py-4 pe-2 overflow-hidden justify-content-center d-flex w-100"
            >
              <Card.Body className="p-0">
                <div className="search-header my-follows pe-5">
                  <div className="d-flex align-items-center">
                    <span
                      className="icon1 d-flex align-items-center justify-content-center me-2"
                      style={{ color: "white", fontSize: "1.5rem" }}
                    >
                      <>&#128276;</>
                    </span>
                    <h4 className="mb-0 fw-semibold gp-header-text">
                      My Follows
                    </h4>
                  </div>
                  {/* <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <form
                        className="search-form"
                        onSubmit={onSubmitFilterSearch}
                      >
                        <div>
                          {!showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src="/assets/images/home/search.svg"
                              alt="search icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src="/assets/images/others/filter-list.svg"
                              alt="filter icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}

                          <input
                            type="text"
                            className="search-input-xs"
                            placeholder="Filter by keywords or enter new search"
                            ref={searchRef}
                            onChange={onKeyFilter}
                          />
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src="/assets/images/home/search.svg"
                              alt="search icon"
                              className="icon icon-right icon-xs"
                            ></motion.img>
                          )}
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
                <div className="result-container mt-4 ps-0 ps-lg-3">
                  <div className="result-header row m-0 pe-result-header w-100">
                    
                    <div className="col-2 dt-start-head">
                      <SortReferendumButton 
                        sortText={"Started"}
                        activeSort={activeSort}
                        sortColumn={'started'}
                        sort={sortByStarted}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"} 
                      />
                    </div>
                    <div className="col-2 dt-end-head">
                      <SortReferendumButton 
                        sortText={"Closed"}
                        activeSort={activeSort}
                        sortColumn={'closed'}
                        sort={sortByClosed}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"}
                      />
                    </div>
                    {/* <div className="col-2 dt-start-head">
                      <button onClick={() => sortListBy("started")} type="button" className={`sort-btn sort-${sortByStarted} bounce ${activeSort === 'started' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Started<img className="mx-2" src={activeSort === 'started' ? caretBlue : caretDarkGrey} /></button>
                    </div>
                    <div className="col-2 dt-end-head">
                      <button onClick={() => sortListBy("closed")} type="button" className={`sort-btn sort-${sortByClosed} bounce ${activeSort === 'closed' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Closed<img className="mx-2" src={activeSort === 'closed' ? caretBlue : caretDarkGrey} /></button>
                    </div> */}
                    <div className="col-5 px-table-item">Referendum</div>
                    <div className="col-2 result-text">Result</div>
                    <div className="col-1">Vote %</div>
                  </div>
                  <div
                    ref={resultRef}
                    onScroll={onScroll}
                    className="result-body pe-3 mz-scroll"
                  >
                    {((showResultRef.length > 0 && !hasError) || (showResultRef.length > 0 && hasError)) 
                      // && searchRef.current.value.length === 0 
                      && showResultRef.map((ref, index) => {
                        return (
                          <div
                            className={`row m-0 mt-${index === 0 ? "3" : "1"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="text-black">
                                {dateDisplayStringByServer(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="text-black">
                                  {dateDisplayStringByServer(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </span>
                              ) : (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </span>
                              )}
                            </div>
                            <div className={`result-percentage col-1`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    {/* {filterResult.length > 0 &&
                      filterResult.map((ref, index) => {
                        return (
                          <div
                            className={`row mt-${index === 0 ? "2" : "4"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="ps-1 text-black">
                                {dateDisplayString(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="ps-1 text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="ps-1 text-black">
                                  {dateDisplayString(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <p
                                  className={`ps-1 text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </p>
                              ) : (
                                <p
                                  className={`ps-1 text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </p>
                              )}
                            </div>
                            <div className={`col-1 ps-3`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })} */}

                    {prevRefLength === maxRefs &&
                      loadRefStatus === "updating" && (
                        <div className="result-loader d-flex justify-content-center align-items-center flex-column my-3">
                          <OPSpinner />
                        </div>
                      )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && !hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        No Referendums Found
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        An error has occured! Please refresh the page.
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length > 0 && hasError) && (
                      <button onClick={retryLoadMore} className="retry-btn text-center py-2 text-gray w-100 border-0 bounce rounded" type="button">
                        An error occured! Please click to try again.</button>
                    )}
                    {searchStatus === "searching" && (
                      <div className="d-flex justify-content-center align-items-center flex-column my-3">
                        <OPSpinner />
                        <span>Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
          <div className="suggestions-main-container col-12 col-xxl-3 mt-4 mt-xxl-1">
            <div className="row">
              <motion.div
                className="col-12"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  // title={
                  //   suggestedCategory.category === "random"
                  //     ? "Interested in these?"
                  //     : "More in " + suggestedCategory.category
                  // }
                  title={`Interested in these?`}
                  unicode="&#128499;"
                  url={suggestedCategory.url}
                  updateStatus={suggestionRefsStatus}
                  referendums={suggestionRefs}
                  // errorMsg={`No ${suggestedCategory.category} Referendums Found`}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
              <motion.div
                className="col-12 mt-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  url="/hot-follows/"
                  updateStatus={hotFollowsStatus}
                  minHeight="18.875rem"
                  title="Hot Follows"
                  unicode="&#128276;"
                  referendums={hotFollowRefs}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer customClass={"inside-footer"} />
    </>
  );
}

export default Follows;
