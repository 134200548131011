import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
   name:'serverTime',
   initialState:{
      origTime:null,
      currentTime:null,
      clientTime: null,
      retrievingTime:false,
   },
   reducers:{
      setOrigServerTime:(state,action)=>{
         Promise.resolve(
            state.origTime = action.payload
         )
      },
      setServerTime:(state,action)=>{
         Promise.resolve(
            state.currentTime = action.payload
         )
      },
      setReceivedTime:(state,action)=>{
         Promise.resolve(
            state.clientTime = action.payload
         )
      },
      retrievingTime:(state,action)=>{
         Promise.resolve(
            state.retrievingTime = action.payload
         )
      }
   }
})

export const {setOrigServerTime, setServerTime, setReceivedTime} = counterSlice.actions

export default counterSlice.reducer