
const hasMainContentScroll = (content_class) => {
    setTimeout(() => {
        let main_content = document.getElementsByClassName("main-content");
        var hasVerticalScrollbar = main_content[0].scrollHeight > main_content[0].clientHeight;
        let inside_footer = document.getElementsByClassName("inside-footer");
        if(inside_footer.length > 0) {
            if(!hasVerticalScrollbar) {
                inside_footer[0].style.position = "absolute";
            } else {
                inside_footer[0].style.position = "relative";
            }
        }
    }, 50);
}


const getComputedStyling = (element) => {
    let styling = null;
    if(element) {
        styling = getComputedStyle(element, null);
    }
    return styling;
}

const getSearchResultBodyHeight = () => {
    let pathnameArr = window.location.pathname.split("/", 2);
    let allowed_pathnames = ['explore', 'my-follows', 'search', 'top', 'contested', 'trending', 'help-them-out', 'hot-follows'];
    let allow = allowed_pathnames.some((path) => pathnameArr[1] === path);
    if(allow) {
        // console.log('allowed')
        let mainContent = document.getElementsByClassName("main-content")[0];
        let mainContentComputed = getComputedStyling(mainContent);
        //get the height available for the main content
        let mainContentHeight = mainContent.clientHeight - parseFloat(mainContentComputed ? mainContentComputed.paddingTop : 24);
        // console.log(mainContentHeight);
    
        let searchContainer = document.getElementsByClassName("search-container")[0]
        let searchContainerComputed = getComputedStyling(searchContainer);
        let searchContainerPaddings = parseFloat(searchContainerComputed ? searchContainerComputed.paddingTop : 24) + parseFloat(searchContainerComputed ? searchContainerComputed.paddingBottom : 24);
        // console.log(searchContainerPaddings)
    
        let searchHeader = document.getElementsByClassName("search-header")[0];
        // console.log(searchHeader.clientHeight)
    
        let resultContainer = document.getElementsByClassName("result-container")[0];
        let resultContainerComputed = getComputedStyling(resultContainer)
        let resultContainerMarginTop = resultContainerComputed ? parseFloat(resultContainerComputed.marginTop) : 24;
        // console.log(parseFloat(resultContainerComputed.marginTop));
    
        let resultHeader = document.getElementsByClassName("result-header")[0];
        // console.log(resultHeader.clientHeight);
    
        let footer = document.getElementsByClassName("footer");
        let footerHeight = 0;
        if(footer.length > 1) {
            if(footer[0].clientHeight > 0) {
            footerHeight = footer[0].clientHeight;
            } else {
            footerHeight = footer[1].clientHeight;
            }
        } else {
            footerHeight = footer[0].clientHeight;
        }
    
        let availableHeight = 0;
        if(window.innerWidth > 1400) {
            availableHeight = mainContentHeight - (searchContainerPaddings + searchHeader.clientHeight + resultContainerMarginTop + resultHeader.clientHeight + (footerHeight));
        } else if(window.innerWidth > 1000) { 
            availableHeight = mainContentHeight - (searchContainerPaddings + searchHeader.clientHeight + resultContainerMarginTop + resultHeader.clientHeight + (footerHeight * 2));
        } else {
            availableHeight = window.innerHeight - (searchContainerPaddings + searchHeader.clientHeight + resultContainerMarginTop + resultHeader.clientHeight + (footerHeight * 4));
        }
        // console.log(availableHeight);
        let resultBody = document.getElementsByClassName("result-body")[0];
    
        resultBody.style.maxHeight = availableHeight +"px";
    }
}

const getMyReferendumBodyHeight = () => {
    //get the height available for the main content
    let pathnameArr = window.location.pathname.split("/", 2);
    if(pathnameArr[1] === "my-history") {
        let mainContent = document.getElementsByClassName("main-content")[0];
        let mainContentComputed = getComputedStyling(mainContent);
        let mainContentHeight = mainContent.clientHeight - parseFloat(mainContentComputed ? mainContentComputed.paddingTop : 24);
    
        let myHistoryContent = document.getElementsByClassName("my-history-content")[0];
        let myHistoryContentComputed = getComputedStyling(myHistoryContent);
        let myHistoryContentPaddingBottom = parseFloat(myHistoryContentComputed ? myHistoryContentComputed.paddingBottom : 16);
    
        let myStatistics = document.getElementsByClassName("my-statistics")[0];
        let myRefs = document.getElementsByClassName("my-refs")[0];
        let myRefsComputed = getComputedStyling(myRefs);
        let myRefsMarginTop = parseFloat(myRefsComputed ? myRefsComputed.marginTop : 24);
    
        let boxContainer = myRefs ? myRefs.firstChild : null;
        let boxComputed = getComputedStyling(boxContainer);
        let boxPaddingsY = parseFloat(boxComputed ? boxComputed.paddingTop : 24) + parseFloat(boxComputed ? boxComputed.paddingBottom : 24);
    
        let myRefHeader = document.getElementsByClassName("my-ref-header")[0];
    
        let resultContainer = document.getElementsByClassName("result-container")[0];
        let resultContainerComputed = getComputedStyling(resultContainer)
        let resultContainerMarginTop = parseFloat(resultContainerComputed ? resultContainerComputed.marginTop : 24);
    
        let resultHeader = document.getElementsByClassName("result-header")[0];
    
        let footer = document.getElementsByClassName("footer");
        let footerHeight = 0;
        if(footer.length > 1) {
            if(footer[0].clientHeight > 0) {
            footerHeight = footer[0].clientHeight;
            } else {
            footerHeight = footer[1].clientHeight;
            }
        } else {
            footerHeight = footer[0].clientHeight;
        }
    
        let availableHeight = 0;
        
        if(window.innerWidth > 1400) {
            availableHeight = mainContentHeight - (
                myHistoryContentPaddingBottom + 
                myStatistics.clientHeight + 
                myRefsMarginTop +
                boxPaddingsY + 
                myRefHeader.clientHeight + 
                resultContainerMarginTop + 
                resultHeader.clientHeight + 
                (footerHeight / 2)
                );
        } else if(window.innerWidth > 1000) { 
            availableHeight = window.innerHeight - (
                myHistoryContentPaddingBottom + 
                myStatistics.clientHeight + 
                myRefsMarginTop +
                boxPaddingsY + 
                myRefHeader.clientHeight + 
                resultContainerMarginTop + 
                resultHeader.clientHeight +
                (footerHeight * 2)
                );
        } else {
            availableHeight = window.innerHeight - (
                myHistoryContentPaddingBottom + 
                (myStatistics.clientHeight / 2)
                // myRefsMarginTop +
                // boxPaddingsY +
                // myRefHeader.clientHeight + 
                // resultContainerMarginTop + 
                // resultHeader.clientHeight
            );
        }
        // console.log(availableHeight);
        let resultBody = document.getElementsByClassName("result-body")[0];
    
        resultBody.style.maxHeight = availableHeight +"px";   
    }
}

export {
    hasMainContentScroll,
    getSearchResultBodyHeight,
    getMyReferendumBodyHeight
}