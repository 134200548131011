import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
function MyBadgesNoneUser(){
   return (
      <>
          <Card className="mybadgesnoneuser border-0 op-box  px-4 py-2 overflow-hidden justify-content-center d-flex flex-wrap  card " style={{borderRadius:"3.125rem",minHeight:"31.25rem"}} >
            
            <Card.Body>
               <div className="d-flex flex-wrap align-items-center">
                  <span style={{fontSize:"1.375rem"}} className="icon1 d-flex align-items-center justify-content-center me-3" >
                  🙋‍♀️
                  </span>
                  <h4 className="mb-0 fw-semibold"> My Badges</h4>
               </div>
               <div className=" pt-4 text-center">
                 <Row>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card  border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card  border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     <Col lg={4} className="mt-3">
                        <Card className="disabled badge-card border-0 op-box  px-4 overflow-hidden justify-content-center d-flex flex-wrap  card p-3 ">
                          <h5 className="mb-3"> Veteran of the Clone Wars</h5>
                          <div className="text">
                              Bravely tagged and linked 100 similar questions in similar active periods leading to a better experience for everyone
                          </div>
                        </Card>
                        
                     </Col>
                     
                 </Row>
               
               </div>
            </Card.Body>
          </Card>
      </>
   )
}

export default MyBadgesNoneUser;