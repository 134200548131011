
function LoginRegisterHeader(props) {
  return (
    <>
      <h4
        className="position-absolute d-inline-block px-1 text-black modal-sub-header"
        style={{ top: "-3.125rem", left: "0" }}
      >
        <span className="me-2">{props.uni}</span>
        {props.text}
      </h4>
    </>
  );
}

export default LoginRegisterHeader;
