import axios from "axios";
import csrfHelper from "./csrfHelper";
import serverTimeHelper from "./serverTimeHelper";
const instance = axios.create();
let serverTime = serverTimeHelper.get();

instance.interceptors.request.use(async function(conf){
   await csrfHelper.get().then((e)=>{
      conf.headers['X-CSRF-TOKEN'] = e;
   });
   return conf;
})

export default instance;