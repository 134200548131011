import InputCounter from "../../../pages/referendums/InputCounter";
import deleteIcon from "../../../assets/images/referendum/delete-row.svg"
import addIcon from "../../../assets/images/referendum/add-row.svg"

function InputChoices(props) {
  const { choices, onChangeChoice } = props;

  function onRemoveInput(id) {
    if (choices.length > 2) {
      let position;
      choices.forEach((choice, index) => {
        if (choice.key === id) {
          position = index;
        }
      });
      choices.splice(position, 1);
      onChangeChoice([...choices]);
    }
  }
  function onAddInput() {
    if (choices.length < 5) {
      let new_choices = [
        ...choices,
        { key: choices[choices.length - 1].key + 1, value: "" },
      ];
      onChangeChoice(new_choices);
    }
  }

  function onChange(id, event) {
    let new_choices = [...choices];
    // let key = event.keyCode || event.charCode
    new_choices.forEach((choice, index) => {
      if (choice.key === id && event.target.value.length <= 50) {
        new_choices[index].value = event.target.value;
      }
    });
    onChangeChoice(new_choices);
  }

  return (
    <div className="cr-ref-choices">
      {choices &&
        choices.map((choice, index) => {
          return (
            <div
              className="cr-ref-choice position-relative d-flex justify-content-between align-items-center"
              key={index}
            >
              <input
                className="w-100 mb-2 ps-2 pe-5 hl-border"
                type="text"
                name="choices"
                value={choice.value}
                onChange={(e) => onChange(choice.key, e)}
              />
              <button
                type="button"
                onClick={() => onRemoveInput(choice.key)}
                className={`cr-ref-btn cr-ref-rem-btn ${
                  choices.length === 2 ? "disabled" : ""
                } ms-3`}
              >
                <img
                  src={deleteIcon}
                  alt="remove button"
                />
              </button>
              <InputCounter max={50} inputText={choice.value} center={true}/>
            </div>
          );
        })}

      <div className="d-flex justify-content-center">
        <button
          type="button"
          onClick={onAddInput}
          className={`cr-ref-btn ${choices.length === 5 ? "disabled" : ""}`}
        >
          <img src={addIcon} alt="add button" />
        </button>
      </div>
    </div>
  );
}

export default InputChoices;
