import { motion } from "framer-motion";
import { useState } from "react";
import validator from "validator";

import ModalHeader from "../others/ModalHeader";
import axiosConfig from "../../helpers/axiosConfig";
import NotificationHelper from "../../helpers/notificationsHelper.js";

function ModalResetPassword(props) {
  const { setStep, setShowMainModal, isAlpha } = props;

  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };

  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);

  const [sending, setSending] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const [email, setEamil] = useState("");
  const [error, setError] = useState(null);

  const toggleOffInContainer = () => {
    if(isAlpha) {
      setStep(null)
    } else {
      setShowMainModal(false);
    }
  };

  function onEmailChange(e) {
    setEamil(e.target.value);
    setError(null);
  }

  function onBack() {
    setStep(null);
  }

  function onSubmit(e) {
    e.preventDefault();
    setError(null);

    let hasError = false;
    let errorTracker = null;

    if (validator.isEmpty(email)) {
      errorTracker = "Email is required";
      hasError = true;
    } else if (!validator.isEmail(email)) {
      errorTracker = "Email is invalid";
      hasError = true;
    }

    setError(errorTracker);

    if (hasError) return;
    if (sending) return;
    setSending(true);

    if(isEmailDisabled) {
      NotificationHelper.disabledEmailNotif();
      setError("Sending reset password link failed");
      setSending(false);
      return;
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  `http://127.0.0.1:3003/op/v1/reset-password?email=${email}`:`https://api.onepeople.online/op/v1/reset-password?email=${email}`;
    axiosConfig
      .get(url)
      .then((res) => {
     
        if (res.data.success) {
          setStep("reset_password_confirmed");
        }
      })
      .catch((err) => {
        
        let datas = err.response.data;
        let errors = datas.errors;

        if(errors!== undefined && errors.length > 0) {
          if(datas.disabled !== undefined && datas.disabled) {
            NotificationHelper.disabledEmailNotif();
            setIsEmailDisabled(true);
          }
          setError(errors[0].msg);
        } else {
          setError("Oops! Something went wrong.");
        }
        
      })
      .finally(() => {
        setSending(false);
      });
  }

  return (
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="auth-container op-box p-0 p-md-3 py-5 py-md-5 OPModalBox position-absolute mx-auto"
        // style={{ width: "100%", maxWidth: "54.6875rem", height: "26.25rem" }}
      >
        <ModalHeader
          title="Trouble signing in?"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3  login-register align-items-center justify-content-center`}
        >
          <div className={`login-container col-12 col-md-6 col-lg-6 px-5`}>
            <form className="position-relative mt-5">
              <h4
                className="position-absolute d-inline-block px-1 text-black modal-sub-header"
                style={{ top: "-2.1875rem", left: "-0.5rem" }}
              >
                <span className="me-2">&#128260;</span>
                Reset your password
              </h4>
              <div className="mt-5">
                <p className="auth-text">
                  Enter your email and we'll send you a link to reset your
                  password
                </p>
              </div>
              <div className="pt-3">
                <div className="m-0 p-0 d-flex justify-content-between">
                  <p className="m-0 p-0 input-text input-error text-gray">
                    Email
                  </p>
                  {error && (
                    <p className="m-0 p-0 input-text text-red">{error}</p>
                  )}
                </div>
                <input
                  value={email}
                  onChange={onEmailChange}
                  type="email"
                  name="email"
                  className="form-control hl-border"
                />
              </div>
              <div className="mt-5 text-center d-flex justify-content-evenly">
                <motion.input
                  initial={{}}
                  animate={
                    sending
                      ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                      : {}
                  }
                  onClick={onSubmit}
                  type="submit"
                  name="submit"
                  value={`Confirm`}
                  className="op-btn confirm-btn bounce"
                />
                <button onClick={(e) => onBack()} className="btn back-btn">
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
export default ModalResetPassword;
