import { useState } from "react";
import auth from "../../../helpers/auth";
import { motion } from "framer-motion";
import validator from "validator";
import axiosConfig from "../../../helpers/axiosConfig";

//Components

function FlagReferndum(props) {
  // const { setReviewStatus } = props;
  const { titleSlug } = props;
  const [report, setReport] = useState("");

  const [status, setStatus] = useState("reporting");
  const [reporting, setReporting] = useState(false);

  const initErrors = {
    report: null,
    others: null,
  };
  const [errors, setErrors] = useState(initErrors);

  function onChange(e) {
    setReport(e.target.value);
    setErrors({ report: null, others: null });
  }

  function onSubmit(e) {
    e.preventDefault();

    let errorTrackers = { ...errors };
    let hasErrors = false;

    if (validator.isEmpty(report)) {
      errorTrackers.report = "Review message is required";
      hasErrors = true;
    } else if (!validator.isLength(report, { min: 1, max: 255 })) {
      errorTrackers.report = "Your provided review message is too long";
      hasErrors = true;
    }

    setErrors(errorTrackers);
    if (hasErrors) return;
    if (reporting) return;
    setReporting(true);

    const ref_data = {
      report,
    };
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${titleSlug}/report`: `https://api.onepeople.online/op/v1/referendums/${titleSlug}/report`;
    axiosConfig
      .post(
        url,
        ref_data,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setStatus("reported");
        }
      })
      .catch((ers) => {
        auth.checkErrors(ers);
        let errs = ers.response.data.errors;
        if (errs) {
          errs.map((er, index) => {
            if (er.path === "report") {
              errorTrackers.report = er.msg;
            } else {
              errorTrackers.others = er.msg;
            }
            return er;
          });
        } else {
          errorTrackers.others = "Something went wrong. Please try again.";
        }
        setErrors(errorTrackers);
      })
      .finally(() => {
        setReporting(false);
      });
  }

  return (
    <>
      <form
        className={`d-flex ${
          status === "reported"
            ? "justify-content-start"
            : "justify-content-center"
        } flex-column  w-100 h-100 ps-3 ps-md-3 ps-lg-5`}
        autoComplete="off"
      >
        <h4 className="px-1 text-black modal-sub-header text-center">
          Flag for review
        </h4>

        {status === "reporting" && (
          <>
            <p className="text-start flag-text mt-3 text-black">
              You may flag this referendum if it is in violation of{" "}
              <a target="_blank" href="/legal-notice/">our terms and conditions</a>, and our moderation team
              will review your feedback as soon as we can. Note that{" "}
              false reporting may be penalised.
            </p>

            <textarea
              value={report}
              onChange={onChange}
              name="report"
              id="report"
              placeholder="Why is this referendum in violation of our terms and conditions?"
              rows={5}
              className="form-control mt-2 hl-border"
            ></textarea>
            {(errors.report || errors.others) && (
              <div className="m-0 p-0">
                {errors.report && (
                  <p className="m-0 p-0 input-text text-red">{errors.report}</p>
                )}
                {errors.others && (
                  <p className="m-0 p-0 input-text text-red">{errors.others}</p>
                )}
              </div>
            )}
            <div className="d-flex justify-content-center mt-4 mx-auto">
              <motion.input
                initial={{}}
                animate={
                  reporting
                    ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                    : {}
                }
                onClick={onSubmit}
                type="submit"
                name="submit"
                value="Report"
                className="op-btn report-btn bounce"
              />
            </div>
          </>
        )}
        {status === "reported" && (
          <>
            <p className="text-start flag-text mt-3 text-black">
              Your report has been submitted. Action will be taken if the referendum is indeed in violation of our terms and conditions.
            </p>
            <p className="text-start flag-text mt-3 text-black">
              Thank you for keeping our community safe.
            </p>
          </>
        )}
      </form>
    </>
  );
}

export default FlagReferndum;
