import { useEffect, useRef, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// import MobileMenu from "../others/MobileMenu";
import categoryCacheHelper from "../../helpers/categoryCacheHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategory,
  // setSelectedCountry,
} from "../../helpers/exploreSelection";
import axiosConfig from "../../helpers/axiosConfig";
import auth from "../../helpers/auth";

//import images
import agriculture from "../../assets/images/home/agriculture.svg";
import artAndDesign from "../../assets/images/home/art-and-design.svg";
import businessAndEconomics from "../../assets/images/home/business-and-economics.svg";
import crisesAndDisasters from "../../assets/images/home/crises-and-disasters.svg";
import cross from "../../assets/images/home/cross.svg";
import education from "../../assets/images/home/education.svg";
import employmentAndJobs from "../../assets/images/home/employment-and-jobs.svg";
import engineering from "../../assets/images/home/engineering.svg";
import entertainment from "../../assets/images/home/entertainment.svg";
import family from "../../assets/images/home/family.svg";
import fashionAndBeauty from "../../assets/images/home/fashion-and-beauty.svg";
import film from "../../assets/images/home/film.svg";
import finance from "../../assets/images/home/finance.svg";
import foodAndDrink from "../../assets/images/home/food-and-drink.svg";
import games from "../../assets/images/home/games.svg";
import goodsAndServices from "../../assets/images/home/goods-and-services.svg";
import health from "../../assets/images/home/health.svg";
import history from "../../assets/images/home/history.svg";
import hobbiesAndCollectibles from "../../assets/images/home/hobbies-and-collectibles.svg";
import languageAndLiterature from "../../assets/images/home/language-and-literature.svg";
import law from "../../assets/images/home/law.svg";
import lifestyleAndInterests from "../../assets/images/home/lifestyle-and-interests.svg";
import luxury from "../../assets/images/home/luxury.svg";
import mathematics from "../../assets/images/home/mathematics.svg";
import militaryAndWar from "../../assets/images/home/military-and-war.svg";
import music from "../../assets/images/home/music.svg";
import newsAndMedia from "../../assets/images/home/news-and-media.svg";
import petsAndAnimals from "../../assets/images/home/pets-and-animals.svg";
import philosophy from "../../assets/images/home/philosophy.svg";
import politics from "../../assets/images/home/politics.svg";
import psychology from "../../assets/images/home/psychology.svg";
import realEstate from "../../assets/images/home/real-estate.svg";
import relationships from "../../assets/images/home/relationships.svg";
import religionAndBeliefs from "../../assets/images/home/religions-and-beliefs.svg";
import science from "../../assets/images/home/science.svg";
import shopping from "../../assets/images/home/shopping.svg";
import societyAndCulture from "../../assets/images/home/society-and-culture.svg";
import sports from "../../assets/images/home/sports.svg";
import technology from "../../assets/images/home/technology.svg";
import television from "../../assets/images/home/television.svg";
import thingsAndStuff from "../../assets/images/home/things-and-stuff.svg";
import transport from "../../assets/images/home/transport.svg";
import travel from "../../assets/images/home/travel.svg";
import world from "../../assets/images/home/world.svg";

// import onePeopleHorizontalColor from "../../assets/images/home/onepeople-logo-horizontal-color.svg"
import onePeopleHorizontalColorBeta from "../../assets/images/home/onepeople-open-beta-logo.svg"
//end of import images

function Sidebar(props) {
  const { sidebarOpen, openSidebarFunction } = props;
  const year = new Date().getFullYear();
  const [icons,setIcons] = useState([
    {
      category: 'Agriculture', 
      src: agriculture,
    },
    {
      category: 'Art and Design', 
      src: artAndDesign,
    },
    {
      category: 'Business and Economics', 
      src: businessAndEconomics,
    },
    {
      category: 'Crises and Disasters', 
      src: crisesAndDisasters,
    },
    {
      category: 'Education', 
      src: education,
    },
    {
      category: 'Employment and Jobs', 
      src: employmentAndJobs,
    },
    {
      category: 'Engineering', 
      src: engineering,
    },
    {
      category: 'Entertainment', 
      src: entertainment,
    },
    {
      category: 'Family and Relationships', 
      src: relationships,
    },
    {
      category: 'Fashion and Beauty', 
      src: fashionAndBeauty,
    },
    {
      category: 'Film', 
      src: film,
    },
    {
      category: 'Finance', 
      src: finance,
    },
    {
      category: 'Food and Drink', 
      src: foodAndDrink,
    },
    {
      category: 'Games and Toys', 
      src: games,
    },
    {
      category: 'Goods and Services', 
      src: goodsAndServices,
    },
    {
      category: 'Health and Medicine', 
      src: health,
    },
    {
      category: 'History', 
      src: history,
    },
    {
      category: 'Hobbies and Collectibles', 
      src: hobbiesAndCollectibles,
    },
    {
      category: 'Homes and Interior', 
      src: family,
    },
    {
      category: 'Language and Literature', 
      src: languageAndLiterature,
    },
    {
      category: 'Law', 
      src: law,
    },
    {
      category: 'Lifestyle and Interests', 
      src: lifestyleAndInterests,
    },
    {
      category: 'Luxury', 
      src: luxury,
    },
    {
      category: 'Mathematics', 
      src: mathematics,
    },
    {
      category: 'Military and War', 
      src: militaryAndWar,
    },
    {
      category: 'Music', 
      src: music,
    },
    {
      category: 'News and Media', 
      src: newsAndMedia,
    },
    {
      category: 'Pets and Animals', 
      src: petsAndAnimals,
    },
    {
      category: 'Philosophy', 
      src: philosophy,
    },
    {
      category: 'Politics', 
      src: politics,
    },
    {
      category: 'Psychology', 
      src: psychology,
    },
    {
      category: 'Real Estate', 
      src: realEstate,
    },
    {
      category: 'Religions and Beliefs', 
      src: religionAndBeliefs,
    },
    {
      category: 'Science', 
      src: science,
    },
    {
      category: 'Shopping', 
      src: shopping,
    },
    {
      category: 'Society and Culture', 
      src: societyAndCulture,
    },
    {
      category: 'Sports', 
      src: sports,
    },
    {
      category: 'Technology', 
      src: technology,
    },
    {
      category: 'Television', 
      src: television,
    },
    {
      category: 'Things and Stuff', 
      src: thingsAndStuff,
    },
    {
      category: 'Transport', 
      src: transport,
    },
    {
      category: 'Travel', 
      src: travel,
    },
    {
      category: 'World', 
      src: world,
    },

  ])

  const selectedCategory = useSelector((state) => {
    return state.catandcountry.category;
  });
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [categoriesStatus, setCategoriesStatus] = useState("updating");
  // eslint-disable-next-line
  const [toggleMenu, setToggleMenu] = useState(true);

  const catlist = useRef();
  const pathname = window.location.pathname;

  
  function handleFireFox () {
    let slug = pathname.replaceAll("/", "");
    let ed_root_elem = document.getElementById("ed-root");
    if(['', 'my-history', 'search','my-follows', 'top', 'hotly-contested', 'trending', 'help-them-out', 'hot-follows', 'leaderboards', 'create'].includes(slug) || slug.includes("explore")) {
      ed_root_elem.classList.add("mz");
      ed_root_elem.classList.remove("mz-ref-view");
      let footer_outside = document.getElementsByClassName("outside-footer");
      if(footer_outside.length > 0) {
        footer_outside[0].classList.remove("d-none");
      }
    } else if(slug.includes("referendums")) {
      ed_root_elem.classList.add("mz-ref-view");
    } else {
      ed_root_elem.classList.remove("mz");
    }
    ed_root_elem.classList.remove("user-voted");
  }


  useEffect(() => {
    const setupSidebar = async () => {
      getCategories().then((res) => {
        let cgs = res;
        let category = null;
        let paths_arr = window.location.pathname.split('/').filter(str  => str.length > 0);

        if(paths_arr.length > 1) {
          paths_arr.map((path) => {
            if(path !== 'explore') {
              let category_obj = cgs.filter((cg) => cg.slug === path);
              if(category_obj.length > 0) {
                category = path
              }
            }
            return path;
          })
        }

        dispatch(setSelectedCategory(category));
        window.onload = () => {};
    
        if (selectedCategory !== null) {
          setTimeout(function () {
            let cat = document.getElementsByClassName(`cat-${category}`);
            cat.length > 0 &&
              cat[0].scrollIntoView({ behavior: "smooth", block: "nearest" });
          }, 500);
        }
      }).catch((err) => {
        setCategoriesStatus("updated");
      })
    }
    setupSidebar();
    handleFireFox();
    return () => {};
  }, 
  // eslint-disable-next-line
  [selectedCategory, pathname]);

  function setupIcons (categories) {
    let result = icons.map((icon, index) => {
      let cat = categories.filter((category) => category.category === icon.category);
      if(cat.length > 0) {
        icon.id = cat[0].id
        icon.slug = cat[0].slug
      } else {
        icon.id = index
        icon.slug = "#";
      }
      return icon;
    })

    setIcons(result)
  }

  function getCategories() {
    return new Promise((resolve, reject) => {
      let cacheCategory = categoryCacheHelper.get();
      if (categoryCacheHelper.get().length !== 0) {
        setCategoriesStatus("updated");
        setCategories(cacheCategory);
        setupIcons(cacheCategory);
        return resolve(cacheCategory);
      }

      let datas = [];
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3001/op/v1/options/categories":"https://api.onepeople.online/op/v1/options/categories";

      axiosConfig
        .get(url, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setCategories(res.data.categories);
            categoryCacheHelper.set(res.data.categories);
            datas = res.data.categories;
            setupIcons(datas); 
            
          }
        })
        .catch((errs) => {
          auth.checkErrors(errs);
          reject("Error retrieving categories")
        })
        .finally(() => {
          setCategoriesStatus("updated");
          resolve(datas);
        });
    })
  }
  // const toggleCategories = (e) => {
  //   setToggleMenu(!toggleMenu);
  // };
  return (
    <div className={"sidebar" + (sidebarOpen ? " open" : "")}>
      <div className="top-section">
        <h4 className="pe-4">
          <a href="/" className="d-block p-0">
            <img
              src={onePeopleHorizontalColorBeta}
              alt="OnePeopleOnline Logo"
              className="header-logo bounce d-block d-lg-none logo-menu-mobile img-fluid"
            />
          </a>
          
          <div className="d-flex align-items-center justify-content-between sidebar-top-option">
            <span
              className={"sidebar-text " + (toggleMenu ? "" : "close")}
              // onClick={toggleCategories}
            >
              CATEGORIES
              {/* <FontAwesomeIcon icon={solid("caret-down")} /> */}
            </span>
            <a href="/explore/" className="d-inline px-0 OP-link">
              See all
            </a>
          </div>
          <button className="mobile-close bounce" type="button" onClick={openSidebarFunction}>
            <img src={cross} alt="exit mobile menu" />
          </button>
        </h4>

        <div className="sidebar-container-menu mz-scroll">
          <div>
          {/* <div className={"collapse-menu" + (toggleMenu ? "" : " close")}> */}
            <ul className="cat-list mz-scroll" ref={catlist}>
              {categories.length > 0 &&
                categoriesStatus === "updated" &&
                icons.map((category, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        `cat-${category.slug} ` +
                        (selectedCategory === category.slug ? `active` : "")
                      }
                    >
                      <a href={`/explore/${category.slug}/`}>
                        {" "}
                        <img
                          src={category.src}
                          alt={category.category + " Logo"}
                        />
                        <span>{category.category}</span>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          {/* <MobileMenu
            sidebarOpen={sidebarOpen}
            openSidebarFunction={openSidebarFunction}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
          /> */}
        </div>
      </div>

      <p className="copyright">&copy;{year} OnePeople All rights reserved</p>
    </div>
  );
}

export default Sidebar;
