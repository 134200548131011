import localStorageHelper from "./localStorageHelper";
import auth from "./auth";
import {AES,enc,SHA256} from "crypto-js"; 
var trendingRefsCacheHelper = {
  set:(data)=>{
   const encrypted = AES.encrypt(JSON.stringify(data), process.env.REACT_APP_MESSAGE_SECRET).toString();

   localStorageHelper.setItem(SHA256("trendingRefsCacheHelper-"+auth.getId()).toString(),encrypted);
   },
   get:()=>{
   const data = localStorageHelper.getItem(SHA256("trendingRefsCacheHelper-"+auth.getId()).toString())
   if(data == null ){
      return [];
   }
   const decrypted = AES.decrypt(data,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
   return JSON.parse(decrypted);
   }

 };

 export default trendingRefsCacheHelper;