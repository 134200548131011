//Components
import Card from "react-bootstrap/Card";
import OPSpinner from "../../components/others/OPSpinner";

import rightArrow from "../../assets/images/home/right-arrow.svg";
import timerAmber from "../../assets/images/home/timer-amber.svg";
import timerGreen from "../../assets/images/home/timer-green.svg";
import timerGrey from "../../assets/images/home/timer-grey.svg";
import timerRed from "../../assets/images/home/timer-red.svg";

function Suggestions(props) {
  const { small = true, url, updateStatus, referendums, limit, title, unicode, errorMsg } = props;

  const timers = {
    amber: timerAmber,
    green: timerGreen,
    grey: timerGrey,
    red: timerRed,
  }

  return (
    <Card
      className={`suggestion-container ${small ? 'sm' : ''} border-0 ps-4 py-4 overflow-hidden justify-content-center d-flex flex-wrap op-box`}
    >
      <Card.Body className="p-0">
        <div className={`d-flex align-items-center justify-content-between pe-2 pe-md-3`}>
          <div className="d-flex align-items-center">
            <span
              className="icon1 d-flex align-items-center justify-content-center me-2"
              style={{ fontSize: "1.5rem" }}
            >
              {unicode}
            </span>
            <h4 className="mb-0 fw-semibold gp-header-text">{title}</h4>
          </div>
          <a href={`${url}`} className="gp-header-btn d-flex align-items-center">
            <img
              src={rightArrow}
              alt="group header btn"
            ></img>
          </a>
        </div>

        {referendums.length > 0 && (
          <div className={`suggestions-list ${small ? 'small' : 'large'} mz-scroll`}>
            {updateStatus === 'updated' && referendums.map((ref, index) => {
              return (
                <a
                  className={`gp-ref d-flex justify-content-between align-items-center ${small ? 'sm' : ''} ${index === 0 ? 'mt-3' : 'mt-1'}`}
                  key={index}
                  href={`/referendums/${ref.slug}/`}
                >
                  <p className="gp-ref-title pe-0">{ref.title}</p>
                  <p className="gp-ref-timer d-flex align-items-center justify-content-center flex-column pe-0  ms-3">
                    <img
                      src={timers[ref.icon]}
                      alt="timer icon"
                      className="gp-ref-timer-icon"
                    />
                    <span className={`gp-ref-timer-text  timer-${ref.icon}`}>
                      {ref.time_left}
                    </span>
                  </p>
                </a>
              );
            })}
          </div>
        )}
        
        { updateStatus === 'updating' && (
          <div className="d-flex flex-fill justify-content-center align-items-center" style={{height: limit === 8 ? "25rem" : "12.5rem"}}>
            <div
              className="text-center d-flex flex-column justify-content-center align-items-center"
            >
              <OPSpinner />
              <span>Loading...</span>
            </div>
          </div>
        )}

        { (updateStatus === 'updated' && referendums.length === 0) && (
          <div className={`d-flex flex-fill justify-content-center align-items-center no-ref-suggested ${ limit === 8 ? "lg" : ""}`}>
            <div
              className="text-center d-flex flex-column justify-content-center align-items-center"
            >
              <div className={`text-gray ${!small ? 'pb-0 pb-md-4' : ''}`}>{errorMsg && errorMsg !== 'No random Referendums Found' ? errorMsg : "No Referendums Found" }</div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default Suggestions;
