import { useEffect, useState, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import Card from "react-bootstrap/Card";
// eslint-disable-next-line
import { motion, animate } from "framer-motion";
import { isPast } from "../../helpers/date.js";

//Components
import VotingForm from "../../components/referendums/voting/VotingForm";
import PreviewForm from "../../components/referendums/preview/PreviewForm";
import Results from "../../components/referendums/result/Results";
import ReferendumHeader from "../../components/referendums/ReferendumHeader";

import MetadataSetup from "../../components/MetadataSetup.js";

import voteBoxNoDrop from "../../assets/images/referendum/vote-box-no-drop.svg"
// import Footer from "../../components/layouts/footer.js"

import PrintSetting from "../../components/referendums/printing/PrintSetting.js";

function Referendum() {
  const [referendum, setReferendum] = useState(useLoaderData());
  const [submittedReason, setSubmittedReason] = useState(null);
  const [showReason, setShowReason] = useState(false);
  // eslint-disable-next-line
  const [hasPast, setHasPast] = useState(referendum.is_past);

  const [published, setPublished] = useState(false);
  const [copied, setCopied] = useState(false);
  const scrollContainer = useRef();
  let footer =document.getElementsByClassName('footer');

  const handleScroll = (el)=>{
    let {target} = el;
    let current_scroll = target.scrollTop + (target.offsetHeight + 20);
      if(current_scroll >= target.scrollHeight){
        footer[0]?.classList.remove('d-none')
      }else{
        footer[0]?.classList.add('d-none')
      }
  }

  const handleFooter = ()=>{
    if(scrollContainer.current?.scrollHeight === scrollContainer.current?.offsetHeight){
      footer[0]?.classList.remove('d-none')
    }else{
      footer[0]?.classList.add('d-none')
    }
  }

  useEffect(() => {
    if(referendum.owner) {
      setPublished(true);
    }
    if(referendum ){
      handleFooter();
    }

    // let footers = document.getElementsByClassName("footer");
    // if(footers.length > 1) {
    //   footers[1].classList.add("d-none");
    // }
    return ()=>{
    }
  }, 
  // eslint-disable-next-line
  [ referendum ]);

  function onCopy() {
    navigator.clipboard.writeText(
      `https://onepeople.online/referendums/${referendum.slug}/`
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500)
  }

  return (
    <>
      <div
        className="main px-0 ref-view-main d-grid ref-view overflow-hidden"
        style={{
          height: "100%",
          alignItems: "flex-start",
          // position: "relative",
        }}
      >
        <MetadataSetup 
          title={`${referendum.title} | OnePeople`} 
          canonical={`https://onepeople.online/referendums/${referendum.slug}/`}
          description={`${referendum.title} Referendum Page of OnePeople Online`}
        />
        <div ref={scrollContainer} className="center row h-100 w-100  mx-auto px-0 px-lg-4 ref-main mz-scroll"
          // style={{position: "relative"}} 
          onScroll={handleScroll} 
        >
          {/* <div className=" col-12 col-md-12 col-lg-12 col-xl-11 col-xxl-9 px-0"> */}
          <div className="ref-main-container px-0" >
            <Card
              style={{
                marginTop:
                  showReason ||
                  submittedReason !== null ||
                  (published && referendum.owner) ||
                  hasPast
                    ? "0.625rem"
                    : "10%",
              }}
              className="ref-view-container border-0 op-box px-1 overflow-hidden d-flex flex-column justify-content-center zindex1 mb-3 mb-lg-5 ref"
            >
              <div className="ref-view-main-content ps-4 pe-3 mz-scroll ">
                <ReferendumHeader
                  referendum={referendum}
                  setReferendum={setReferendum}
                  isPreview={false}
                />
                <Card.Body className={`p-0 ref-view-body ${hasPast ? 'past' : ''} row justify-content-center mz-scroll-none px-lg-3 px-xl-5`}>
                  <div className="col-12 col-md-12 col-lg-12 ref-title-choices-container">
                    <h1 className="ref-title text-center mt-3 mt-md-3 mt-lg-5">
                      <span className="ref-title-icon me-3">&#128499;</span>
                      <span
                        dangerouslySetInnerHTML={{ __html: referendum.title }}
                      ></span>
                    </h1>
                    <div className={`ref-voting mt-5 pb-${(published && !showReason && !submittedReason && !hasPast) ? '0' : '5'}`}>
                      {hasPast && <PreviewForm referendum={referendum} />}
                      {!hasPast && (
                        <VotingForm
                          referendum={referendum}
                          setReferendum={setReferendum}
                          setSubmittedReason={setSubmittedReason}
                          showReason={showReason}
                          setShowReason={setShowReason}
                        />
                      )}
                    </div>
                  </div>

                  {published &&
                    !showReason &&
                    !submittedReason &&
                    !hasPast &&
                    referendum.owner && (
                      <div className="share-container">
                        <div className="col-11 col-md-11 col-lg-10 mx-auto">
                          <hr className="mt-5" />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 mx-auto">
                          <div className="mt-4 mb-4 text-center">
                            <p className="text-black op-text">
                              Your referendum has been published!
                            </p>
                            <p className="text-black op-text">
                              Now sit back, relax, and wait for the results.
                            </p>
                            <p className="text-black op-text">
                              Share the link to this referendum with anyone to get
                              their private perspective.
                            </p>
                            <div className="mt-3 mb-5">
                              <div className="mt-5 text-center d-flex justify-content-center">
                                <button onClick={onCopy} className="op-btn">
                                  {!copied ? "Copy Link" : "Copied!"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {hasPast && (
                    <Results
                      referendum={referendum}
                      setReferendum={setReferendum}
                    />
                  )}
                </Card.Body>
              </div>
            </Card>
          </div>
          {submittedReason !== null && !showReason && (
            <>
              <div className="w-100"></div>
              <div className="col-12 ref-sub-reason position-relative text-center dropping-container-parent" >
                <div className="cut-image">
                <img src={voteBoxNoDrop} className="img-fluid drop-box-bg"  alt="Vote Box"/>
                </div>
                <div className="dropping-container">
                  <div className="outer-dropping-paper">
                    <motion.div
                      initial={{ y: 0, x: 0 }}
                      animate={{
                        y: ["0%", "0%", "0%", "0%", "110%"],
                        x: [0, -5, 5, 0, 0, 0],
                      }}
                      transition={{ duration: 1, delay: 3 }}
                    >
                      <Card
                        style={{
                          
                        }}
                        className="px-4 zindex1 mx-auto border-0 op-box pt-3 overflow-hidden justify-content-center d-flex flex-wrap"
                      >
                        <h2 className="ref-sub-reason-title text-black text-center mb-3">
                          Your Top Reason
                        </h2>
                        <Card.Body className="p-0 row justify-content-center">
                          <p className="px-2 py-0 m-0 ref-sub-reason-text text-black text-center d-flex justify-content-center align-items-center">{ submittedReason }</p>
                        </Card.Body>
                        <Card.Footer className="card-footer-custom">
                          <p className="p-0 m-0 ref-vote-change text-center">
                            Your vote has been received. You may{" "}
                            <button onClick={() => setShowReason(true)}>
                              change your vote
                            </button>{" "}
                            before the closing time. Results will be released when
                            voting is closed.
                          </p>
                        </Card.Footer>
                      </Card>
                    </motion.div>
                  </div>
                  <div className="dropping-hole"></div>
                  <div className="dropping-half"></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <PrintSetting referendum={referendum} handleFooter={handleFooter} />
    </>
  );
}

export default Referendum;
