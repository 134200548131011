import { useState } from "react";
import SearchGroup from "./SearchGroup";
import { useEffect } from "react";
import { useNavigationType } from "react-router";

function SearchGrouping(props) {
  const { searchGroupAction, onGroupAction } = props;
  const navigateType = useNavigationType();
  const initSearchGrouping = {
    top: {
      title: "top",
      unicode: "&#x1F3C6;",
      active: searchGroupAction === "top",
    },
    hot: {
      title: "hot",
      unicode: "&#128293;",
      active: searchGroupAction === "hot",
    },
    trending: {
      title: "trending",
      unicode: "&#128200;",
      active: searchGroupAction === "trending",
    },
    help: {
      title: "help",
      unicode: "&#128075;",
      active: searchGroupAction === "help",
    },
    hot_follows: {
      title: "hot_follows",
      unicode: "&#128276;",
      active: searchGroupAction === "hot_follows",
    },
  };
  const [searchGrouping, setSearchGrouping] = useState(initSearchGrouping);
  const [loadedOnce, setLoadedOnce] = useState(false);


  useEffect(() => {
    if(loadedOnce && navigateType === 'POP') {
      setSearchGrouping((old_grouping) => {
        let new_grouping = { ...old_grouping };
        new_grouping.top = {
          ...new_grouping.top,
          active:
          searchGroupAction === "top" ? (new_grouping.top.active ? false : true) : false,
        };
        new_grouping.hot = {
          ...new_grouping.hot,
          active:
          searchGroupAction === "hot" ? (new_grouping.hot.active ? false : true) : false,
        };
        new_grouping.trending = {
          ...new_grouping.trending,
          active:
          searchGroupAction === "trending"
              ? new_grouping.trending.active
                ? false
                : true
              : false,
        };
        new_grouping.help = {
          ...new_grouping.help,
          active:
          searchGroupAction === "help" ? (new_grouping.help.active ? false : true) : false,
        };
        new_grouping.hot_follows = {
          ...new_grouping.hot_follows,
          active:
          searchGroupAction === "hot_follows" ? (new_grouping.hot_follows.active ? false : true) : false,
        };

        return new_grouping;
      });
    }
    setLoadedOnce(true)
    
  }, 
  // eslint-disable-next-line
  [searchGroupAction])

  function onSearchGrouping(title) {
    setSearchGrouping((old_grouping) => {
      let new_grouping = { ...old_grouping };
      new_grouping.top = {
        ...new_grouping.top,
        active:
          title === "top" ? (new_grouping.top.active ? false : true) : false,
      };
      new_grouping.hot = {
        ...new_grouping.hot,
        active:
          title === "hot" ? (new_grouping.hot.active ? false : true) : false,
      };
      new_grouping.trending = {
        ...new_grouping.trending,
        active:
          title === "trending"
            ? new_grouping.trending.active
              ? false
              : true
            : false,
      };
      new_grouping.help = {
        ...new_grouping.help,
        active:
          title === "help" ? (new_grouping.help.active ? false : true) : false,
      };
      new_grouping.hot_follows = {
        ...new_grouping.hot_follows,
        active:
          title === "hot_follows" ? (new_grouping.hot_follows.active ? false : true) : false,
      };

      return new_grouping;
    });
    searchGroupAction === title ? onGroupAction(null) : onGroupAction(title);
  }

  return (
    <ul className="search-grouping">
      <SearchGroup
        title={"top"}
        active={searchGrouping.top.active}
        unicode="&#x1F3C6;"
        onSearchGrouping={onSearchGrouping}
      />
      <SearchGroup
        title={"hot"}
        active={searchGrouping.hot.active}
        unicode="&#128293;"
        onSearchGrouping={onSearchGrouping}
      />
      <SearchGroup
        title={"trending"}
        active={searchGrouping.trending.active}
        unicode="&#128200;"
        onSearchGrouping={onSearchGrouping}
      />
      <SearchGroup
        title={"help"}
        active={searchGrouping.help.active}
        unicode="&#128075;"
        onSearchGrouping={onSearchGrouping}
      />
      <SearchGroup
        title={"hot_follows"}
        active={searchGrouping.hot_follows.active}
        unicode="&#128276;"
        onSearchGrouping={onSearchGrouping}
      />
    </ul>
  );
}

export default SearchGrouping;
