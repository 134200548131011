import { motion } from "framer-motion";
import { useState } from "react";

//Components
import SuggestCategory from "./review/SuggestCategory";
import FlagReferendum from "./review/FlagReferendum";
import ModalHeader from "../others/ModalHeader";

function ModalReview(props) {
  const { setShowReview, titleSlug, defaultCategory, flagged } = props;
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  const [isOpen, setIsOpen] = useState(true);
  // eslint-disable-next-line
  const [step, setStep] = useState(1);

  // eslint-disable-next-line
  const [reviewStatus, setReviewStatus] = useState({
    suggested: false,
    reported: false,
  });

  const toggleOffInContainer = () => {
    setIsOpen(false);
    setTimeout(function () {
      setShowReview(false);
    }, 100);
  };

  const StepFunction = ({ step }) => {
    switch (step) {
      default:
        return (
          <motion.div>
            <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="op-box p-3 pt-5 pb-0 pb-md-0 pb-lg-5
               review OPModalBox position-absolute mx-auto border-box"
            >
              <ModalHeader
                title="Review"
                toggleOffInContainer={toggleOffInContainer}
              />
              <div
                className={`row sc-flag justify-content-center border-box px-3 px-md-3 px-lg-0 mz-scroll`}
              >
                <div
                  className={`sc-container col-12 col-md-12 col-lg-6 
                  ps-3 ps-md-3 ps-lg-5 py-2 
                  pb-5 pb-md-5 pb-lg-0
                  border-box border-bottom-divider`}
                >
                  <SuggestCategory
                    titleSlug={titleSlug}
                    defaultCategory={defaultCategory}
                    setReviewStatus={setReviewStatus}
                  />
                </div>
                {flagged === 0 && (
                  <div className="flag-container col-12 col-md-12 col-lg-6 border-left-divider ps-0 pe-lg-5 mt-5 mt-md-5 mt-lg-0 pb-5 pb-md-5 pb-lg-0 border-box">
                    <FlagReferendum
                      titleSlug={titleSlug}
                      setReviewStatus={setReviewStatus}
                    />
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
    }
  };

  return (
    <>
      <div className="OPModal position-absolute" onClick={toggleOffInContainer}>
        <StepFunction step={step} />
      </div>
    </>
  );
}
export default ModalReview;
