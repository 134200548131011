import MessagesDropDown from "../messages/MessagesDropdown.js";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { setMessageValue } from "../../helpers/messageCounter.js";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCategory } from "../../helpers/exploreSelection";
import messageHelper from "../../helpers/messageHelper";
import { setOrigServerTime } from "../../helpers/serverTime.js";
import caretBlue from "../../assets/images/others/caret-blue.svg";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
function CenterMenu(props) {
  const { centerMenu, 
    setCenterMenu, 
    // eslint-disable-next-line
    setIsOnline, 
    showQuickMessage, 
    setShowQuickMessage, 
    setShowProfileAction } = props;

  const count = useSelector((state) => state.messagecounter.value);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const pathname = window.location.pathname.replace("/", "");


  useEffect(() => {
    let path = window.location.pathname.replace("/", "");
    if (path === "") {
      onClick("home", false);
    } else {
      path = path.replace("/", "");
      onClick(path, false);
    }


    messageHelper.getMessageCount(getMessageUpdate).then((e)=>{

      if(e !== undefined){
   
        dispatch(setOrigServerTime(e))
      }
    });
  }, 
  // eslint-disable-next-line
  [ pathname ]);

  function onClick(label, countMessage = true) {
    
    let pathnameArr = window.location.pathname.split("/", 2);
    let allowed_pathnames = ['','create', 'my-history', 'messages', 'leaderboards'];
    let allow = allowed_pathnames.some((path) => pathnameArr[1] === path);
    if(!allow) {
      label = null;
    }
    
    let root_elem = document.getElementById("ed-root");
    root_elem.classList.remove("bg-img-error-404");
    root_elem.classList.add('bg-img')
    dispatch(setSelectedCategory(null));
    setShowQuickMessage(false)
    setShowProfileAction(false);
    // if(countMessage) {
    //   messageHelper.getMessageCount(getMessageUpdate);
    // }
    if (
      centerMenu.filter((obj, index) => {
        return obj.label === label;
      })
    ) {
      setCenterMenu((old_menu) => {
        let new_menu = old_menu.map((obj) => {
          if (obj.label === label) {
            obj.active = true;
          } else {
            obj.active = false;
          }

          return obj;
        });
        return new_menu;
      });
    }
  }

  function getMessageUpdate(ctr) {
      dispatch(setMessageValue(ctr))
  }

  return (
    <nav className="center-nav">
      <ul>
        <li className={`${centerMenu[0].active ? "active" : ""}`}>
          <Link
            onClick={() => onClick("home")}
            className="d-inline-block"
            to="/"
          >
            Home
          </Link>
        </li>
        <li className={`${centerMenu[1].active ? "active" : ""}`}>
          <Link
            to="/create/"
            onClick={() => onClick("create")}
            className="d-inline-block"
          >
            Create Referendum
          </Link>
        </li>
        <li
          id="history-nav"
          className={`${centerMenu[2].active ? "active" : ""}`}
        >
          <Link onClick={() => onClick("my-history")} to="/my-history/">
            My History
          </Link>
        </li>
        <li
          id="messages-nav"
          className={`position-relative ${centerMenu[3].active ? "active" : ""}`}
          onClick={(e) => {
            setShowQuickMessage(!showQuickMessage);
          }}
        >
          Messages{" "}
          {count > 0 && (
            <span className="badge badge-nav badge-msg">{count}</span>
          )}
          <img
            src={caretBlue}
            style={{
              width: "0.625rem",
              height: "0.625rem",
              marginTop: "-0.125rem",
            }}
            className="ms-2"
            alt="dropdown menu"
          />
          {showQuickMessage && (
            <MessagesDropDown
              setShowQuickMessage={setShowQuickMessage}
              showQuickMessage={showQuickMessage}
            />
          )}
        </li>
        <li className={`${centerMenu[4].active ? "active" : ""}`}>
          <Link onClick={() => onClick("leaderboards")} to="/leaderboards/">
            Leaderboards
          </Link>
          {/* <a onClick={() => onClick("leaderboards")} href="/leaderboards">
            Leaderboards
          </a> */}
        </li>
      </ul>
    </nav>
  );
}

export default CenterMenu;
