import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
   name:'messageCounter',
   initialState:{
      value:0
   },
   reducers:{
      setMessageValue:(state,action)=>{
         state.value = action.payload
      }
   }
})

export const {setMessageValue} = counterSlice.actions

export default counterSlice.reducer