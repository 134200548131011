import caretDarkGrey from "../../assets/images/others/caret-dark-grey.svg";
import caretBlue from "../../assets/images/others/caret-blue.svg";
import { useEffect, useState } from "react";

function SortReferendumButton({
  activeSort,
  sortColumn,
  sort,
  sortListBy,
  searchStatus,
  searchStatusText,
  sortText,
}) {
  const [onHoverStarted, setOnHoverStarted] = useState(false);
  const [onHoverClosed, setOnHoverClosed] = useState(false);

  useEffect(() => {
    setOnHoverStarted(false);
    setOnHoverClosed(false);
  }, []);

  const onMouseOver = (column) => {
    if (column === "started") {
      setOnHoverStarted(true);
    } else {
      setOnHoverClosed(true);
    }
  };

  const onMouseOut = (column) => {
    if (column === "started") {
      setOnHoverStarted(false);
    } else {
      setOnHoverClosed(false);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          sortListBy(sortColumn);
        }}
        type="button"
        className={`sort-btn sort-${sort} bounce ${
          activeSort === sortColumn ? "sort-active" : ""
        } ${searchStatus === searchStatusText ? "sort-disabled" : ""}`}
        onMouseOver={() => onMouseOver(sortColumn)}
        onMouseOut={() => onMouseOut(sortColumn)}
      >
        {sortText}
        <img
          className="mx-2"
          src={
            sortColumn === "started"
              ? onHoverStarted
                ? caretBlue
                : activeSort === sortColumn
                ? caretBlue
                : caretDarkGrey
              : onHoverClosed
                ? caretBlue
                : activeSort === sortColumn
                ? caretBlue
                : caretDarkGrey
          }
          alt="sort button"
        />
      </button>
    </>
  );
}

export default SortReferendumButton;
