import { motion } from "framer-motion";
import { useState } from "react";

//Components
import ModalHeader from "../others/ModalHeader";
import qrCodeSample from "../../assets/images/others/qrcode_www.google.com_sample.png";

function ModalRegisterQR(props) {
  const { setStep, qrCodeUrl, manualCode, setShowMainModal, isAlpha } = props;
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);

  const toggleOffInContainer = () => {
    if(isAlpha) {
      setStep(null)
    } else {
      setShowMainModal(false);
    }
  };

  function onContinue() {
    setStep("register_verify");
  }

  return (
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="op-box p-3 py-5 auth-container OPModalBox position-absolute mx-auto"
      >
        <ModalHeader
          title="Set your authentication app up for 2FA"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3  login-register align-items-center justify-content-center`}
        >
          <div className="register-container col-12 col-md-6 col-lg-6 px-5">
            <div className="position-relative">
              <h4 className="d-block px-1 text-black text-center modal-sub-header">
                <span className="me-2">&#128221;</span>
                Set up 2FA
              </h4>
              <div className="mt-4">
                <p className="text-center auth-text">
                  Scan the following QR code with your authentication app (e.g.
                  Google Authenticator)
                </p>
              </div>
              <div className="mt-3 text-center">
                <div className="qrcode_container">
                  {qrCodeUrl !== null && (
                    <img
                      src={qrCodeUrl}
                      style={{
                        width: "11.5625rem",
                        height: "11.5625rem",
                        border: "0.0625rem solid #000",
                      }}
                      alt="QRCode"
                    />
                  )}
                  {qrCodeUrl == null && (
                    <img
                      src={qrCodeSample}
                      style={{
                        width: "11.5625rem",
                        height: "11.5625rem",
                        border: "0.0625rem solid #000",
                      }}
                      alt="QRCode"
                    />
                  )}
                </div>
              </div>
              <div className="form-check mt-3">
                <p className="text-center auth-text">
                  {`Or manually enter this code into the app ${
                    manualCode !== null ? manualCode : `XXXX-XXXX-XXXX-XXXX`
                  }`}
                </p>
              </div>
              <div className="mt-5 text-center">
                <button
                  onClick={onContinue}
                  className="op-btn confirm-btn bounce"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
export default ModalRegisterQR;
