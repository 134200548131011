function InputCounter(props) {
  const { max, inputText, center = false } = props;
  
  return (
    <span
      className={`input-ctr input-ctr-${center ? 'center' : 'bottom'}`}
    >
      {inputText.length}/{max}
    </span>
  );
}

export default InputCounter;
