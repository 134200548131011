import { useEffect, useRef, useState } from "react";
import auth from "../../helpers/auth";
import { motion } from "framer-motion";
import validator from "validator";
//Components
import LoginRegisterHeader from "./login_register_header.js";
import csrfHelper from "../../helpers/csrfHelper";
import axiosConfig  from "../../helpers/axiosConfig"

import hidePw from "../../assets/images/profile/hide-password.svg";
import showPw from "../../assets/images/profile/show-password.svg";

function Login(props) {
  const { setStep, setEmail, setPassword, setQrCodeUrl, setManualCode } = props;
  const [loggingIn, setLoggingIn] = useState(false);
  const [passwordToggled, setPasswordToggled] = useState(false);
  const [emailIn, setEmailIn] = useState("");
  const [passwordIn, setPasswordIn] = useState("");
  const rememberRef = useRef(null);

  const initErrors = {
    emailIn: null,
    passwordIn: null,
    others: null,
  };
  const [errors, setErrors] = useState(initErrors);

  function handleEmail(e) {
    setEmailIn(e.target.value);
    setErrors({ ...errors, emailIn: null, others: null });
  }

  function handlePassword(e) {
    setPasswordIn(e.target.value);
    setErrors({ ...errors, passwordIn: null, others: null });
  }

  function onResetPassword() {
    setStep("reset_password");
  }

  function onSubmit(e) {
    e.preventDefault();

    setErrors({emailIn: null, passwordIn: null, others: null});

    let userdata = {
      email: emailIn,
      password: passwordIn,
    };

    let errorTracker = errors;
    let hasError = false;

    //Empty Checking
    if (validator.isEmpty(userdata.email)) {
      errorTracker = { ...errorTracker, emailIn: "Email is required" };
      hasError = true;
    } else if (!validator.isEmail(userdata.email)) {
      errorTracker = { ...errorTracker, emailIn: "Email is invalid" };
      hasError = true;
    }

    if (validator.isEmpty(userdata.password)) {
      errorTracker = { ...errorTracker, passwordIn: "Password is required" };
      hasError = true;
    } else if (!validator.isLength(userdata.password, { min: 8 })) {
      errorTracker = {
        ...errorTracker,
        passwordIn: "Your password must be at least 8 characters",
      };
      hasError = true;
    }

    setErrors(errorTracker);

    if (hasError) return;
    if (loggingIn) return;

    setLoggingIn(true);
   
    // axios
    //   .post("https://api.onepeople.online/op/v1/login2", userdata, {
    //     withCredentials: true,
    //     headers: {
    //       'Content-Type': 'text/plain'
    //     }
    //   })
    //   .then((res) => res)
    //   .catch((res) => res);

    // fetch("https://api.onepeople.online/op/v1/login2", {
    //   method: "POST",
    //   credentials: "include",
    //   body: JSON.stringify(userdata),
    // })
    //   .then((response) => response.json())
    //   .then((res) => {
    //     setLoggingIn(false);
    //     if (res.success) {
    //       setEmail(userdata.email);
    //       setPassword(userdata.password);
    //       if (!res.verified) {
    //         setQrCodeUrl(res.qrcode);
    //         setManualCode(res.code);
    //         setStep("register_qr");
    //       } else {
    //         setStep("login_verify");
    //       }
    //       auth.setToken(res.token);
    //     }
    //   })
    //   .catch((res) => console.log(res))
    //   .finally(() => {
    //     setLoggingIn(false);
    //   });
    // userdata._csrf = csrf;
    let url = process.env.REACT_APP_ENVIRONMENT ==="development" ? "http://127.0.0.1:3003/op/v1/login":"https://api.onepeople.online/op/v1/login";

    axiosConfig
      .post(url, userdata)
      .then((res) => {
        setLoggingIn(false);
        if (res.data.success) {
          setEmail(userdata.email);
          setPassword(userdata.password);
          if (!res.data.verified) {
            setQrCodeUrl(res.data.qrcode);
            setManualCode(res.data.code);
            setStep("register_verify_email");
          } else {
            setStep("login_verify_email");
          }
          auth.setToken(res.data.token);
        }
      })
      .catch((err) => {
        console.log(err)
       
        let errors = err.response.data.errors;

        if (errors) {
          if (errors[0].msg === "connect ETIMEDOUT") {
            errorTracker.others = "Something went wrong. Please try again.";
          } else {
            errors.map((obj, index) => {
              let params = obj.path;

              if (params) {
                if (params === "email") {
                  if (obj.msg === "The provided credentials are incorrect") {
                    errorTracker.emailIn = obj.msg;
                  } else {
                    errorTracker.others = obj.msg;
                  }
                } else {
                  //password
                  errorTracker.passwordIn = obj.msg;
                }
              } else {
                errorTracker.others = obj.msg;
              }
              return obj;
            });
          }
        } else {
          errorTracker.others = "Something went wrong. Please try again.";
        }
        setErrors(errorTracker);
      })
      .finally(() => {
        setLoggingIn(false);
      });
  }
  useEffect(()=>{
  //  load csrf 
  const loadcsrf = async()=>{
  await csrfHelper.initialize()

  }
  const handlePopState=()=>{
    if(auth.isOnline){
      window.location.href="/"
    }
  }

  window.addEventListener('popstate',handlePopState);


  loadcsrf();
  return () => {
    // remove resize listener
    window.removeEventListener('popstate',handlePopState);
   
  };


  },[])
  return (
    <>
      <form className="position-relative">
        <LoginRegisterHeader text="Sign In" uni="&#128100;" />

        <div className="mt-5">
          <div className="m-0 p-0 d-flex justify-content-between">
            <p className="m-0 p-0 input-text text-gray">Email</p>
            {errors.emailIn && (
              <p className="m-0 p-0 input-text input-error text-red text-end">
                {errors.emailIn}
              </p>
            )}
          </div>
          <input
            value={emailIn}
            onChange={handleEmail}
            type="text"
            name={`email`}
            className="form-control mt-1 hl-border"
            disabled={loggingIn}
            autoComplete="off"
          />
        </div>
        <div className="mt-2">
          <div className="m-0 p-0 d-flex justify-content-between">
            <p className="m-0 p-0 input-text text-gray">Password</p>
            {errors.passwordIn && (
              <p className="m-0 p-0 input-text input-error text-red text-end">
                {errors.passwordIn}
              </p>
            )}
          </div>
          <div className="d-flex position-relative input-with-toggle">
            <input
              value={passwordIn}
              onChange={handlePassword}
              type={passwordToggled ? "text" : "password"}
              className="form-control hl-border"
              id="password"
              name="password"
              autoComplete="off"
            ></input>
            <button
              type="button"
              className="border-0 position-absolute"
              onClick={() => setPasswordToggled(!passwordToggled)}
            >
              {passwordToggled ? (
                <motion.img
                  whileHover={{ scale: 1 }}
                  src={hidePw}
                  style={{ width: "1.25rem", height: "1.25rem", scale: 0.9 }}
                />
              ) : (
                <motion.img
                  whileHover={{ scale: 1 }}
                  src={showPw}
                  style={{ width: "1.25rem", height: "1.25rem", scale: 0.9 }}
                />
              )}
            </button>
          </div>
        </div>
        {loggingIn && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-2"
          >
            <p className="m-0 p-0 input-text text-center text-gray">
              Logging in...
            </p>
          </motion.div>
        )}
        <div className="form-check mt-3 d-flex justify-content-between">
          <div className="m-0 p-0 d-flex align-items-center">
            <input
              className="form-check-input position-relative me-2 hl-border"
              style={{ top: "-0.125rem" }}
              ref={rememberRef}
              name="remember-me"
              type="checkbox"
              id="remember_me"
              disabled={loggingIn}
            />
            <label
              className="form-check-label input-text text-black"
              htmlFor="remember_me"
            >
              Remember Me
            </label>
          </div>

          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={onResetPassword}
              className="op-link-btn input-text bounce"
            >
              I forgot my password
            </button>
          </div>
        </div>
        {errors.others && (
          <div className="m-0 p-0 mt-3 input-text input-error text-red">
            {errors.others}
          </div>
        )}
        <div className="mt-4 text-center">
          <motion.input
            initial={{}}
            animate={
              loggingIn
                ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                : {}
            }
            onClick={onSubmit}
            type="submit"
            name="submit"
            value="Sign In"
            className="op-btn login-btn bounce"
          />
        </div>
      </form>
    </>
  );
}

export default Login;
