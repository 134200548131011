import { useState, useEffect } from "react";

function HeaderSearchHook() {

  function useActiveSearch() {
    const [activeSearch, setActiveSearch] = useState(null);
    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
      // timeoutId for debounce mechanism
      let timeoutId = null;
      const getActiveSearch = () => {
        clearTimeout(timeoutId);

        let active = {
          search: "",
          text: "",
        };

        if (window.innerWidth <= 1400) {
          active.search = 'mobile';
          active.text = 'text from mobile';
        } else {
          active.search = 'desktop';
          active.text = 'text from desktop';
        }
        timeoutId = setTimeout(() => setActiveSearch(active), 150);
      };

      // set resize listener
      window.addEventListener("resize", getActiveSearch);
      getActiveSearch();

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", getActiveSearch);
      };
    }, []);

    return activeSearch;
  }
  return useActiveSearch();
}

export default HeaderSearchHook;
