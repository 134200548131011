import { Card, Row, Col } from "react-bootstrap";
import { useState } from "react";
import auth from "../../helpers/auth";
import MetadataSetup from "../../components/MetadataSetup.js";

import thisIsOnePeople from "../../assets/images/about-us/this-is-onepeople2.svg";

function AboutPage() {
  // eslint-disable-next-line
  const [onlineStatus, setOnlineStatus] = useState(auth.isOnline());

  return (
    <>
      <MetadataSetup 
        title="About Us | OnePeople" 
        canonical="https://onepeople.online/about-us/"
        description="OnePeople seeks to accelerate learning and understanding through voting and sharing, safely and anonymously."
      />
      {/* <Container> */}
      <Row className={`about-us-container ${onlineStatus ? "" : 'main d-grid ref-view reset-logout pt-4 mx-auto'} justify-content-center`}>
        <Col md={12} lg={12} xl={11} xxl={9} className={onlineStatus ? "" : "mx-auto"}>
          <Card className="op-box OPcard-rounded card-transparent">
            <div className="about-us-content mx-1 mz-scroll my-3">
              <Card.Header className="text-center clear-header pb-3 fblack pt-5 ">
                <h1 className="FS25 fbold">🌐 About OnePeople</h1>
              </Card.Header>
              <Card.Body className="pt-4 px-4 pb-0 p-lg-5">
                <p className="fblack FS14">
                  The Internet and social media have accelerated the spread of information, but also disinformation. As our circumstances and experiences differ, so do the truths we each hold, which reflect the realities of our immediate surroundings: our neighbourhoods, our cities, and our countries. We are different, and we will disagree.
                </p>
                <p className="fblack FS14">
                  We form tribes, we argue, we fight, we divide, and we battle for hearts and minds online, in the name of championing truth. We ban, censor, and deny a voice to those who we are not willing to listen to. We seek shelter and safety in our own echo chambers, and we no longer see the other.
                </p>
                <Row>
                  <Col lg={7}>
                    <p className="fblack FS14">
                      Here at OnePeople, we believe there are other ways to progress. There are better ways to bridge the distance between our relative truths, and build a better world, together. We believe safer communities in which we can share our views, will help us listen better to what others have to say. We seek to accelerate learning and understanding, without letting our weaknesses get in the way. And we believe a fast and efficient way for us to do that together at scale, is to vote.
                    </p>
                    <p className="fblack FS14">
                      Through referendums, we ask the world to choose, and through voting and sharing, we access the wisdom of the crowds. Vote for what you believe, and see what the world believes. Gain insight into perspectives, and discover what you did not see before. See what everyone thinks, even if we may not fully understand why we think the way we do just yet. Go beyond us versus them, beyond right or wrong, beyond good and evil.
                    </p>
                    <p className="fblack FS14">
                      What we learn as OnePeople, will help us shape a better future for our next generation.
                    </p>
                    <p className="fblack FS14">
                      OnePeople is brought to you by Edge Digital, and we connect people.
                    </p>
                  </Col>
                  <Col lg={5} className="d-flex flex-column justify-content-end pb-3">
                    <img src={thisIsOnePeople} alt="This is OnePeople" />
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
      {/* </Container> */}
    </>
  );
}

export default AboutPage;
