import { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import validator from "validator";
import auth from "../../../helpers/auth";
import OPSpinner from "../../others/OPSpinner";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import axiosConfig from "../../../helpers/axiosConfig";
import NotificationHelper from "../../../helpers/notificationsHelper";
import CountDown from "../../../components/others/CountDown";


function SetUp2FA({ setVerifiedStatus, emailSendingStatus, email }) {
  const [animateType, setAnimateType] = useState("animateLoad");
  const [isAnimated, setIsAnimated] = useState(false);
  const [step, setStep] = useState("send2FAEmail");
  const codeRef = useRef();
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(null);
  const [qrImage, setQRImage] = useState(null);
  const [otpImage, setOtpImage] = useState(null)

  const [isSending, setIsSending] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  // eslint-disable-next-line

  const animateNext = {
    initial: {
      x: 200,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
  };
  // eslint-disable-next-line
  const animateBack = {
    initial: {
      x: -200,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
  };
  // eslint-disable-next-line
  const animateLoad = {
    initial: {
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.focus();
    }
  }, [code])

  const showQRCode = (e) => {
    e.preventDefault();
    setCodeError(null);

    if (validator.isEmpty(code)) {
      setCodeError("2FA Code is required");
    } else {
      setStep("loader");
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3000/op/v1/verify/user" : "https://api.onepeople.online/op/v1/verify/user";
      axiosConfig
        .post(
          url,
          {
            query: "user_details",
            otp: code,
            type: 'email'
          },
          {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          }
        )
        .then((e) => {
          setQRImage(e.data.qrcode);
          setOtpImage(e.data.otp)
          setCode('');
          setCodeError(null);
          setStep("show2FA");
          setAnimateType("animateNext");
          setIsAnimated(false);
          setVerifiedStatus(true);
        })
        .catch((e) => {
          auth.checkErrors(e);
          if (e.response.data?.errors.length > 0) {
            const { msg } = e.response.data.errors[0];
            setCodeError(msg);
          } else {
            toast("Something went wrong! ", { autoClose: 2000 });
          }
          setStep("enter2FA", {});
        });
    }
  };
  const verify2fa = (e) => {
    e.preventDefault();
    setCodeError(null);

    if (validator.isEmpty(code)) {
      setCodeError("2FA Code is required");
    } else {
      setStep("loader");
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3000/op/v1/verify/user" : "https://api.onepeople.online/op/v1/verify/user";

      axiosConfig
        .post(
          url,
          {
            query: "user_details",
            otp: code,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          }
        )
        .then((e) => {
          setCodeError(null);
          setStep("success");
          setAnimateType("animateNext");
        })
        .catch((e) => {
          auth.checkErrors(e);
          if (e.response.data?.errors.length > 0) {
            const { msg } = e.response.data.errors[0];
            setCodeError(msg);
          } else {
            toast("Something went wrong! ", { autoClose: 2000 });
          }

          setStep("confrim2FA", {});
        });
    }
  };

  const send2FA = () => {
    if (isSending) return;
    setStep("loader")
    setIsSending(true);
    setCodeError(null);

    if (emailSendingStatus === 1 || isEmailDisabled) {
      setCodeError("Unable to send 2FA code");
      setStep("invalid")
      setIsSending(false);
      setIsCounting(true);
      return
    }

    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3003/op/v1/get-otp?email=${email}` : `https://api.onepeople.online/op/v1/get-otp?email=${email}`;
    axiosConfig
      .get(url)
      .then((res) => {
        setStep("enter2FA");
      })
      .catch((err) => {

        let datas = err.response?.data;
        let errs = datas?.errors;
        let errorTracker = null;
        if (errs) {
          if (errs[0].msg === "connect ETIMEDOUT") {
            errorTracker = "Something went wrong. Please try again.";

          } else if (errs[0].msg === "Unable to send 2FA code") {
            if (datas.disabled !== undefined && datas.disabled) {
              setIsEmailDisabled(true);
            }
            errorTracker = "Unable to send 2FA code";
          } else {
            errorTracker = errs[0].msg;
          }
        } else {
          errorTracker = "Something went wrong. Please try again.";
        }
        setCodeError(errorTracker);
      })
      .finally(() => {
        setIsSending(false);
        setIsCounting(true);
        setStep("enter2FA")
      })
  }

  const resend2FA = (e) => {
    e.preventDefault();
    if (isCounting || isSending) return;
    send2FA();
  }


  const onChangeCode = (event) => {
    if (step === 'confrim2FA' && !isAnimated) {
      setIsAnimated(true);
    }
    setCode(event.target.value);
    setCodeError(null);
  }

  const Steps = () => {
    switch (step) {
      case "success":
        return (
          <div
            className="success justify-content-center d-flex flex-wrap"
            style={{ minHeight: "12.5rem" }}
          >
            <div className="success-check-verified-otp mt-5 col-12">
              <FontAwesomeIcon icon={solid("check")} />
            </div>
            <div className="col-12">
              <p className="fblack FS12 mt-5">
                Your account has been updated and your 2FA has been set up.
              </p>
            </div>
          </div>
        );
        // eslint-disable-next-line
        break;

      case "invalid":
        return (
          <div className="FS14 mt-5 fblack">
            <p className="fblack">
              We are unable to send you an email because there may be a problem with your email service provider.
            </p>
            <p className="fblack">
              Please indicate an alternative email address that we can send an email to.
            </p>
            <p className="fblack">
              You may change your email address by updating My Details.
            </p>
          </div>
        )
        // eslint-disable-next-line
        break;
      case "loader":
        return (
          <>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "9.375rem" }}
            >
              <OPSpinner />
            </div>
          </>
        );
        // eslint-disable-next-line
        break;

      case "send2FAEmail":
        return (
          <motion.div
            // eslint-disable-next-line
            animate={eval(animateType).animate}
            style={{ minHeight: "7.5rem" }}
          >
            <div
              className="FS14 mt-5 fblack"
            >
              <p className="fblack">
                Send the 6-digit code to you via email to proceed with the setup.
              </p>
              <div className="mt-5 OPGroupButton d-flex ">
                <button
                  onClick={send2FA}
                  className="default-button  img-fluid   mx-auto justify-content-center d-flex"
                >
                  Send
                </button>
              </div>
            </div>
          </motion.div >
        );
        // eslint-disable-next-line
        break;

      case "enter2FA":
        return (
          <motion.div
            // eslint-disable-next-line
            animate={eval(animateType).animate}
          >
            <form onSubmit={showQRCode}>
              <div className="FS14 mt-5 fblack">
                <p className="fblack">
                  Enter the 6-digit code sent to you via email to
                  confirm setup
                </p>
                <div className="OPinput-group text-start">
                  <div className="d-flex">
                    <label htmlFor="2FA">2FA Code</label>
                    <div className="flex-fill ps-1 text-red FS10  justify-content-end d-flex flex-wrap align-items-center">
                      <div className="col-12 text-end">{codeError}</div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <input
                      id="2FA"
                      name="2FA"
                      type="number"
                      className="col-12 FS12 mb-1 twoFA-input-profile hl-border"
                      ref={codeRef}
                      onChange={onChangeCode}
                      value={code}
                    />
                  </div>
                  <div className={`text-end mt-0`} style={{ fontSize: "0.635rem" }}>
                    <button
                      type="button"
                      onClick={resend2FA}
                      className={`op-link-btn input-text bounce ${isCounting ? "disabled" : ""}`}
                    >
                      {`Resend via Email `} {isCounting && <CountDown timer={60} setStatus={setIsCounting} />}
                    </button>
                  </div>
                </div>
                <div className="mt-3 OPGroupButton d-flex ">
                  <button
                    onClick={showQRCode}
                    className="default-button  img-fluid   mx-auto justify-content-center d-flex"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
      case "show2FA":
        return (
          <motion.div
            // eslint-disable-next-line
            initial={eval(animateType).initial}
            // eslint-disable-next-line
            animate={eval(animateType).animate}
          >
            <div className="FS14 mt-3 fblack">
              <p className="fblack" text="">
                Scan the following QR code with your authentication app (eg
                Google Authenticator)
              </p>
              <div className="fblack">
                <div
                  className="img-fluid"
                  style={{
                    backgroundColor: "#ccc",
                    height: "12.5rem",
                    width: "12.5rem",
                    display: "inline-block",
                  }}
                >
                  {qrImage && (
                    <img
                      src={qrImage}
                      alt="qr"
                      style={{ width: "12.5rem" }}
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
              <p className="fblack">
                Or manually enter this code into the app <br />
                <b>{otpImage}</b>
              </p>

              <div className="mt-3 OPGroupButton d-flex ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setStep("confrim2FA");
                    setAnimateType("animateNext");
                  }}
                  className="default-button  img-fluid   mx-auto justify-content-center d-flex"
                >
                  Confirm
                </button>
              </div>
            </div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
      case "confrim2FA":
        return (
          <motion.div
            // eslint-disable-next-line
            initial={!isAnimated ? eval(animateType).initial : false}
            // eslint-disable-next-line
            animate={eval(animateType).animate}
          >
            <div className="FS14 mt-5 fblack">
              <p className="fblack">
                Enter the 6-digit code displayed by the app to
                confirm setup
              </p>
              <div className="OPinput-group text-start">
                <form onSubmit={verify2fa}>
                  <div className="d-flex">
                    <label>2FA Code</label>
                    <div className="flex-fill ps-1 text-red FS10  justify-content-end d-flex flex-wrap align-items-center">
                      <div className="col-12 text-end">{codeError}</div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <input
                      name="2FA"
                      type="number"
                      className="col-12 FS12 mb-2 twoFA-input-profile"
                      ref={codeRef}
                      onChange={onChangeCode}
                      value={code}
                    />
                  </div>
                </form>
              </div>
              <div className="mt-3 OPGroupButton d-flex ">
                <button
                  onClick={verify2fa}
                  className="default-button  img-fluid   mx-auto justify-content-center d-flex"
                >
                  Next
                </button>
              </div>
            </div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Card className="profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
        <Card.Body className="text-center">
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="mb-0 fw-semibold text-start text-black d-flex">🔐 <span className="ps-1 text-black">Set up 2FA on a device</span></h5>
          </div>
          <Steps />
        </Card.Body>
      </Card>
    </>
  );
}

export default SetUp2FA;
