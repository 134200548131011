import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
   name:'imageState',
   initialState:{
      image:null,
   },
   reducers:{
      setImage:(state,action)=>{
         Promise.resolve(
            state.image = action.payload
         )
      },
   }
})

export const {setImage} = counterSlice.actions

export default counterSlice.reducer