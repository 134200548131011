import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import OPSpinner from "../../components/others/OPSpinner";
import axiosConfig from "../../helpers/axiosConfig";
import auth from "../../helpers/auth";
function EmailVerify() {
  const params = useState(useLoaderData());
   const [loading,setLoading] = useState(true);
   const [error,SetError ] = useState(false);
   // eslint-disable-next-line
   const [ errMes,SetErrMess] = useState(null);
   useEffect(()=>{
      
      if(params[0] !== undefined){
        let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3000/op/v1/email_verify": "https://api.onepeople.online/op/v1/email_verify";
        axiosConfig.post(url,{
            token:params[0]
         },{}).then((e)=>{
            setLoading(false);
            window.location ="/profile/"
         }).catch((e)=>{
          auth.checkErrors(e);
            SetError(true)
            let mess = e.response.data.message === undefined ? null:e.response.data.message;
            if(mess !== null){
              SetErrMess(mess);
            }
            setLoading(false);
         })
      }
   },
   // eslint-disable-next-line
   [])
   
  return (
    <>
      {error === true &&<Container>
            <Row>
               <Col>
               <Card className="profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
                     <Card.Body className="text-center d-flex flex-wrap justify-content-center mt-4 mb-4">
                      <h5 className="my-4">The verification link has already expired.</h5>
                        <div className="col-lg-12 FS18">
                          <p className="text-black">If your email has not yet been verified, please send another request via My Profile.</p>
                          <div className="mt-4 OPGroupButton d-flex "> 
                              <a href="/profile/" className="btn default-button  img-fluid   mx-auto justify-content-center d-flex op-btn" style={{borderRadius:'0.9375rem'}}> Go to My Profile </a>
                          </div>
                        </div>
                     </Card.Body>
               </Card>
               </Col>
            </Row>
         </Container>
      }
      {loading === true && <Container>
            <Row>
               <Col>
               <Card className="profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
                     <Card.Body className="text-center d-flex justify-content-center">
                        <OPSpinner/>
                     </Card.Body>
               </Card>
               </Col>
            </Row>
         </Container>}
      {(loading === false && error !== true) &&  (
        <Container>
          <Row>
            <Col lg={9} className="mx-auto">
              <Card className="verification-container profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
                <Card.Body className="text-center justify-content-center d-flex">
                  <div className="d-flex flex-wrap align-items-center">
                    <h4 className="mb-0  text-left d-flex align-items-center">
                    <span className="d-flex me-3 justify-content-center align-items-center" style={
                     {
                        fontSize:"1.125rem",
                        backgroundColor:"#3fa778",
                        width:"1.4375rem",
                        height:"1.4375rem",
                        color:"white",

                        }}>
                          
                    <FontAwesomeIcon icon={solid("check")}/> 
                    </span>
                    
                    Email successfully verified!
                    </h4>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
export default EmailVerify;
