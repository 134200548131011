import { useEffect, useState } from "react";
import Header from "../../components/layouts/header";
import Sidebar from "../../components/layouts/sidebar";
import Footer from "../../components/layouts/footer";
import Notification from "../../components/Notification";
import auth from "../../helpers/auth";
import MetadataSetup from "../../components/MetadataSetup";

function ErrorPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(auth.isOnline());
  const [centerMenu, setCenterMenu] = useState([
    { label: "home", active: true },
    { label: "create", active: false },
    { label: "my-history", active: false },
    { label: "messages", active: false },
    { label: "leaderboards", active: false },
  ]);

  useEffect(() => {
    if (!isOnline) {
      window.location.href = "/";
    } else {
      let root_elem = document.getElementById("ed-root");
      root_elem.classList.add("bg-img-error-404");

    }
  }, 
  // eslint-disable-next-line
  []);

  const openSidebarFunction = () => {
    if (!sidebarOpen === true) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {isOnline && (
        <>
          <MetadataSetup 
            title="Page Not Found | OnePeople"
            canonical="https://onepeople.online/legal-notice/"
            description="Error Page of OnePeople Online"
          />
          <Header
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
            openSidebarFunction={openSidebarFunction}
          />
          <Sidebar
            sidebarOpen={sidebarOpen}
            openSidebarFunction={openSidebarFunction}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
          />
          <div
            className={`pt-3 pt-md-4 pb-1 px-4 px-md-4 px-lg-3 px-md-4 main-content`}
          >
            <div className="row error-page-container h-100">
              <div className="col p-0 m-0">
                <p className="error-code">404</p>
                <p className="error-msg">This page does not exist or was removed!</p>
                <a
                  href="/"
                  target="_self"
                  id="home-btn"
                  className="op-btn mt-5"
                >
                  Back to Home
                </a>
              </div>
            </div>
          </div>
          <Footer />
          <Notification />
        </>
      )}
    </>
  );
}

export default ErrorPage;
