import { Helmet } from "react-helmet";

export default function MetadataSetup({title, canonical, description}) {

    return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:url" content={canonical}/>
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:url" content={canonical}/>
        </Helmet>
    )
}