function Footer({customClass}) {
  return (
    <>
      <div className={`footer ${ customClass ?? ""}`}>
        <h5>
          <ul>
            <li>
              <a href="/about-us/">About Us</a>
            </li>
            <li>|</li>
            <li>
              <a href="/legal-notice/">Legal Notice</a>
            </li>
            <li>|</li>
            <li>
              <a href="/privacy-policy/">Privacy Policy</a>
            </li>
            <li>|</li>
            <li>
              <a href="/contact-us/">Contact Us</a>
            </li>
          </ul>
        </h5>
      </div>
    </>
  );
}

export default Footer;
