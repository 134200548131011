import { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { dateDisplayStringByServer, getVotingDurationByOrigServer, dateNowByServer } from "../../helpers/date";
import { motion } from "framer-motion";
import MetadataSetup from "../../components/MetadataSetup.js";

//Components
import Suggestions from "./Suggestions";
import { useSearchParams, useNavigate, useNavigationType } from "react-router-dom";
import SearchGrouping from "../../components/search/SearchGrouping";
import OPSpinner from "../../components/others/OPSpinner";
import axiosConfig from "../../helpers/axiosConfig"
import auth from "../../helpers/auth";

import SortReferendumButton from "../../components/others/SortReferendumButton.js";
import searchIcon from "../../assets/images/home/search.svg";
import filterList from "../../assets/images/others/filter-list.svg";
import Footer from "../../components/layouts/footer.js";
import { hasMainContentScroll, getSearchResultBodyHeight } from "../../helpers/jsHelper";
import { useSelector } from "react-redux";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
function Search() {
  // const root_url = "https://onepeople.online";
  const navigate = useNavigate();
  const navigateType = useNavigationType();
  const filterRef = useRef(null);
  const [filterValue, setFilterValue] = useState(null);
  const [searchStatus, setSearchStatus] = useState("searching");
  const initSuggestedCategory = {
    category: "random",
    slug: "random",
  };

  const [suggestedCategory, setSuggestedCategory] = useState(
    initSuggestedCategory
  );

  const [suggestionRefs, setSuggestionRefs] = useState([]);
  const [suggestionRefsStatus, setSuggestionRefsStatus] = useState("updating");

  const [loadedOnce, setLoadedOnce] = useState(false);
  const [loadRefStatus, setLoadRefStatus] = useState(null);
  const [loadRefIteration, setLoadRefIteration] = useState(null);
  const [prevRefLength, setPrevRefLength] = useState(null);
  const maxRefs = 15;

  const [showResultRef, setShowResultRef] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [showFocusFilterIcons, setShowFocusFilterIcons] = useState(false);

  const [queryParameters] = useSearchParams();
  const [searchAction, setSearchAction] = useState(null);

  const [searchKey, setSearchKey] = useState(null);
  const [searchGroupAction, setSearchGroupAction] = useState(null);

  const resultRef = useRef(null);
  // const [freshLoad, setFreshLoad] = useState(true);

  //suggestions
  //hot follows
  const [hotFollowsStatus, setHotFollowsStatus] = useState("updating");
  const [hotFollowRefs, setHotFollowRefs] = useState([]);

  const [hasError, setHasError] = useState(false)

  const [pageTitle, setPageTitle] = useState(null);
  const pathname = window.location.pathname;
  
  const [sortByStarted, setSortByStarted] = useState('desc');
  const [sortByClosed, setSortByClosed] = useState('desc');
  const [activeSort, setActiveSort] = useState(null);



  window.addEventListener("resize", () => {
    hasMainContentScroll("search")
    getSearchResultBodyHeight();
  })

  useEffect(
    () => {
      getSearchResultBodyHeight();
      if(!loadedOnce || navigateType === 'POP') {
        resetSort();
        let search_key = queryParameters.get("q");
        let path = window.location.pathname;
        if (path !== null && path.length > 0) {
          path = path.replaceAll("/", "");
        }
  
        let search_action = null;
        let search_grouping_action = null;
        if(path === 'search') {
          search_action = null;
        } else {
          search_action = 'grouping'
        }
  
        setSearchAction(search_action)
  
        switch(path) {
          case 'top':
            search_grouping_action = 'top';
            setPageTitle("Top")
            break;
          case 'hotly-contested':
            search_grouping_action = 'hot';
            setPageTitle("Hotly Contested")
            break;
          case 'trending':
            search_grouping_action = 'trending';
            setPageTitle("Trending")
            break;
          case 'help-them-out':
            search_grouping_action = 'help';
            setPageTitle("Help Them Out")
            break;
          case 'hot-follows':
            search_grouping_action = 'hot_follows';
            setPageTitle("Hot Follows")
            break;
          default:
            setPageTitle("Search")
            search_grouping_action = null;
            break;
        }
  
        // if (
        //   search_grouping_action !== null &&
        //   search_grouping_action.length > 0
        // ) {
        //   search_grouping_action = search_grouping_action.replace("/", "");
        // }
        // if (search_key !== null && search_key.length > 0) {
        //   search_key = search_key.replace("/", "");
        // }
  
        setSearchKey(search_key);
        setSearchGroupAction(search_grouping_action)
  
        filterRef.current.value = search_key
        setFilterValue(search_key);

        if(search_action !== "grouping") {
          setSortSearch();
        }

        if (search_key) {
          if (search_action === "grouping") {
            setSearchGroupAction(search_grouping_action);
            getGrouping({group: search_grouping_action, key: search_key});
          } else {
            getSearch({key: search_key, sort: 'desc', sortBy: 'started'});
          }
          
        } else {
          if (search_action === "grouping") {
            setSearchGroupAction(search_grouping_action);
            getGrouping({group: search_grouping_action});
          } else {
            getSearch({key: search_key, sort: 'desc', sortBy: 'started'});
          }
        }
  
        getHotFollowReferendums();
        setLoadedOnce(true);
        
        hasMainContentScroll("search");
      }
      
    },
    // eslint-disable-next-line
    [
      pathname
    ]

  );

  function resetFilterHandler() {
    if(filterRef.current.value !== '' && filterRef.current.value !== null && filterRef.current.value !== undefined) {
    } else {
      filterRef.current.value = '';
      setShowFocusFilterIcons(false);
      setFilterValue(null)
    }
    setFilterResult([]);
  }

  
  function sortListBy (sortColumn) {
    if(searchStatus === 'updated') {
      resetFilterHandler();
      let sortBy = sortColumn;
      let sort = sortColumn === 'started' ? sortByStarted : sortByClosed;
      if(!activeSort) {
        sort = 'desc'
      } else {
        if(sortColumn === activeSort) {
          sort = sort === 'desc' ? 'asc' : 'desc';
        } else {
          sort = 'desc'
        }
      }

      setActiveSort(sortColumn);

      if(sortColumn === 'closed') {
        setSortByClosed(sort)
        setSortByStarted('desc')
      } else {
        setSortByStarted(sort)
        setSortByClosed('desc')
      }

      if (searchAction === "grouping") {
        getGrouping({group: searchGroupAction, key: searchKey, sort, sortBy});
      } else {
        getSearch({key: searchKey, sort, sortBy});
      }
    }
  }

  async function getSearch({
    key, 
    iteration = null, 
    sort = null,
    sortBy = null
  }) {
    setHasError(false);
    setPageTitle("Search")
    if (iteration === null) {
      setSearchStatus("searching");
      setShowResultRef([]);
    } else {
      setLoadRefStatus("updating");
    }

    let query = "q=" + key;

    if (iteration !== null) {
      query += "&iteration=" + iteration;
    }

    if(sort) {
      query += `&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}`;
    }

    let refLength = 0;
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3004/op/v1/referendums/search?" + query:"https://api.onepeople.online/op/v1/referendums/search?" + query;
    axiosConfig
      .get(url,
      {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoadRefIteration(iteration);
          let refs = res.data.referendums;
          refs.forEach((ref) => {
            let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
            let duration = getVotingDurationByOrigServer(ref.end_at,dateNow);
            ref.duration = duration;
          });

          setPrevRefLength(res.data.referendums.length);
          refLength = res.data.referendums.length;
          let filter_value = !loadedOnce ? key : filterValue;
          if (iteration !== null) {
            setShowResultRef((old_refs) => {
              let new_arr = [...old_refs, ...refs]
              if(filter_value) {
                setFilteredResult(new_arr, filter_value)
              }
              return new_arr
            });
          } else {
            getSuggestions(refs, "search", key);
            setShowResultRef(refs);
            if(filter_value) {
              setFilteredResult(refs, filter_value)
            }
            resetLoader();
          }
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        if(iteration !== null) {
          getSuggestions([], "search");
        } else {
          getSuggestions([], "search");
          setShowResultRef([]);
        } 
        setHasError(true);
      })
      .finally(() => {
        if (iteration !== null) {
          setLoadRefStatus("updated");
        } else {
          setSearchStatus("updated");
          if(refLength === maxRefs) {
            loadMore({bypass: true,  action: "search", key, sort, sortBy});
          }
        }
        hasMainContentScroll("search");
      });
  }

  async function getHotFollowReferendums() {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=hot_follows&limit=4": "https://api.onepeople.online/op/v1/referendums/suggestions?group=hot_follows&limit=4";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setHotFollowRefs);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        console.log(errs);
      })
      .finally(() => {
        setHotFollowsStatus("updated");
        hasMainContentScroll("search");
      });
  }

  const setupSuggestions = (refs, setRef) => {
    refs = refs.slice(0, 4).map((ref, index) => {
      let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
      let tempStatus = getVotingDurationByOrigServer(ref.end_at,dateNow);
      ref = { ...ref, ...tempStatus };
      return ref;
    });
    setRef(refs);
  };

  async function getGrouping({
      group, 
      key = null, 
      iteration = null, 
      sort = null,
      sortBy = null
  }) {
    setHasError(false)
    if (iteration === null) {
      setSearchStatus("searching");
      setShowResultRef([]);
    } else {
      setLoadRefStatus("updating");
    }

    let query = "";
    let query_length = 0;

    if (key) {
      query += `q=${key}`;
      query_length += 1;
    }

    if (group) {
      query += `${query_length > 0 ? "&" : ""}group=${group}`;
    }

    if (iteration !== null) {
      query += "&iteration=" + iteration;
    }
    
    if(sort) {
      query += `&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}`;
    }

    let refLength = 0;
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3004/op/v1/referendums/search?" + query: "https://api.onepeople.online/op/v1/referendums/search?" + query;
    axiosConfig
      .get(url,
      {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoadRefIteration(iteration);
          let refs = res.data.referendums;
          refs.forEach((ref) => {
            let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
            let duration = getVotingDurationByOrigServer(ref.end_at,dateNow);
            ref.duration = duration;
          });
          setPrevRefLength(res.data.referendums.length);
          refLength = res.data.referendums.length;
          let filter_value = !loadedOnce ? key : filterValue;
          if (iteration !== null) {
            setShowResultRef((old_refs) => {
              let new_arr = [...old_refs, ...refs]
              if(filter_value) {
                setFilteredResult(new_arr, filter_value)
              }
              return new_arr
            });
          } else {
            getSuggestions(refs, "group", key);
            setShowResultRef(refs);
            if(filter_value){
              setFilteredResult(refs, filter_value)
            }
            resetLoader();
          }
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        if(iteration !== null) {
          getSuggestions([], "group");
        } else {
          getSuggestions([], "group");
          setShowResultRef([]);
        }
        setHasError(true)
      })
      .finally(() => {
        // console.log("showResultRef")
        // console.log(showResultRef)
        // console.log("filterResult")
        // console.log(filterResult)
        if (iteration === null) {
          setSearchStatus("updated");
          if(refLength === maxRefs) {
            loadMore({bypass: true, action: "grouping", key, group, sort, sortBy});
          }
        } else {
          setLoadRefStatus("updated");
        }
        hasMainContentScroll("search");
      });
  }

  function onGroupAction(title) {
    let action = title !== null ? "grouping" : null;
    let group_action = title !== null ? title : null;
    setSearchAction(action);
    setSearchGroupAction(group_action);

    setShowResultRef([]);
    resetFilterHandler();

    //if user tried to remove the filtered word entirely but search key was used then return the search value
    if(searchKey && (filterRef.current.value === '' || filterRef.current.value === null || filterRef.current.value === undefined )) {
      filterRef.current.value = searchKey
    }

    if (action === "grouping") {
      resetSort();
      let nav_url2 = '';
      switch(group_action) {
        case 'top':
          setPageTitle("Top")
          nav_url2 = '/top'
          break;
        case 'hot':
          setPageTitle("Hotly Contested")
          nav_url2 = '/hotly-contested'
          break;
        case 'trending':
          setPageTitle("Trending")
          nav_url2 = '/trending'
          break;
        case 'help':
          setPageTitle("Help Them Out")
          nav_url2 = '/help-them-out'
          break;
        case 'hot_follows':
          setPageTitle("Hot Follows")
          nav_url2 = '/hot-follows'
          break;
        default:
          break;
      }

      if (searchKey) {
        nav_url2 += `?q=${searchKey}`;
      } else {
        nav_url2 += `/`;
      }
      navigate(nav_url2);
      getGrouping({group: group_action, key: searchKey});
    } else {
      setSortSearch();
      
      let nav_url = "";

      if(searchKey) {
        nav_url = "/search?q=" + searchKey;
      } else {
        nav_url = "/search/"
      }

      navigate(nav_url);

      getSearch({key: searchKey, sort: 'desc', sortBy: 'started'});
    }
  }

  function setSortSearch() {
    setActiveSort("started");
    setSortByClosed("desc");
    setSortByStarted("desc");
  }
  
  function resetSort() {
    setSortByStarted("desc");
    setSortByClosed("desc");
    setActiveSort(null);
  }

  function getSort() {
    let sort, sortBy = activeSort;
    if(activeSort === 'started') {
      sort = sortByStarted;
    } else if(activeSort === 'closed') {
      sort = sortByClosed;
    } else {
      sort = null;
    }
    return {sort, sortBy}
  }

  function loadMore({
    bypass = false, 
    action = null, 
    key = null, 
    group = null,
    sort = null,
    sortBy = 'started'
  }) {
    if(!hasError) {
      if (bypass || prevRefLength === maxRefs) {
        if (loadRefStatus === "updating") return;
        setLoadRefStatus("updating");
  
        let iteration = bypass ? null : loadRefIteration;
        if (iteration === null) {
          iteration = 1;
        } else {
          iteration = iteration + 1;
        }

        action = action ?? searchAction;
        let sort_obj = getSort();
        if (action === "grouping") {
          getGrouping({
            group: group ?? searchGroupAction, 
            key: key ?? searchKey, 
            iteration, 
            sort: bypass ? sort : sort_obj.sort,
            sortBy
          });
        } else {
          //search only and no other action
          getSearch({
            key: key ?? searchKey, 
            iteration, 
            sort: bypass ? sort : sort_obj.sort, 
            sortBy
          });
        }
      }
    }
  }

  function retryLoadMore() {
    setHasError(false);
    loadMore(getSort());
  }

  function resetLoader() {
    setLoadRefIteration(null);
  }

  function getSuggestions(refs, action, key = null) {
    let request_suggestion = "random";
    let suggestedSlugInArray = [];
    let suggestedNameKey = {};
    if (refs.length > 0) {
      if(key === null) {
        setSuggestedCategory({
          category: "random",
          slug: "random",
          url: "/search/",
        });
      } else {
        refs.forEach((ref) => {
          ref.categories.forEach((category) => {
            suggestedSlugInArray.push(category.slug);
            suggestedNameKey[category.slug] = category.category;
          });
        });
        const suggestedCtr = suggestedSlugInArray.reduce((acc, curr) => {
          acc[curr] = (acc[curr] || 0) + 1;
          return acc;
        }, {});
  
        const sortedCategories = Object.keys(suggestedCtr).sort(
          (a, b) => suggestedCtr[b] - suggestedCtr[a]
        );
  
        let suggested_category_slug;
        let suggested_category_name;
  
        if (action === "search" || action === "group") {
          //get the dominant category
          suggested_category_slug = sortedCategories[0];
          suggested_category_name = suggestedNameKey[suggested_category_slug];
          setSuggestedCategory({
            category: suggested_category_name,
            slug: suggested_category_slug,
            url: "/explore?category=" + suggested_category_slug,
          });
          request_suggestion = suggested_category_slug;
        } 
        // else if (action === "category") {
        //   if (sortedCategories.length > 1) {
        //     //check if dominant or not
        //     let highest = sortedCategories[0];
        //     let second_highest = sortedCategories[1];
        //     if (highest > 1 && second_highest < highest) {
        //       suggested_category_slug = sortedCategories[1];
        //       suggested_category_name = suggestedNameKey[suggested_category_slug];
        //       setSuggestedCategory({
        //         category: suggested_category_name,
        //         slug: suggested_category_slug,
        //         url: "/explore?category=" + suggested_category_slug,
        //       });
  
        //       request_suggestion = suggested_category_slug;
        //     } else {
        //       setSuggestedCategory({
        //         category: "random",
        //         slug: "random",
        //         url: "/search/",
        //       });
        //     }
        //   }
        // } 
        else {
          setSuggestedCategory({
            category: "random",
            slug: "random",
            url: "/search/",
          });
        } 
      }
    } else {
      setSuggestedCategory({
        category: "random",
        slug: "random",
        url: "/search/",
      });
    }
    requestSuggestion(request_suggestion);
  }

  function requestSuggestion(slug) {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3004/op/v1/referendums/suggestions?category=" +slug +"&limit=4" : "https://api.onepeople.online/op/v1/referendums/suggestions?category=" +slug +"&limit=4";
    axiosConfig
      .get(
        url,
          {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          }
      )
      .then((res) => {
        if (res.data.success) {
          let refs = res.data.referendums;
          setupSuggestions(refs, setSuggestionRefs);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        console.log(errs);
      })
      .finally(() => {
        setSuggestionRefsStatus("updated");
        hasMainContentScroll("search");
      });
  }

  function onScroll() {
    const scrollTop = resultRef.current.scrollTop;
    const scrollHeight = resultRef.current.scrollHeight;
    const clientHeight = resultRef.current.clientHeight;

    const isBottom = ((scrollTop + clientHeight) + ((scrollTop + clientHeight) * .05)) >= scrollHeight;
    if (isBottom) {
      if( searchStatus=== 'updated') {
        if(filterResult.length === 0) {
          loadMore(getSort());
        }
      }
    }
  }

  
  function setFilteredResult (refs, key) {
    let new_result = refs.filter((ref) => {
      return (
        ref.title.toLowerCase().includes(key.toLowerCase()) ||
        ref.result.toLowerCase().includes(key.toLowerCase()) ||
        (ref.option &&
          ref.option.toLowerCase().includes(key.toLowerCase()))
      );
    });
    setFilterValue(key)
    setFilterResult(new_result);
  }

  //Search through Title
  function onKeyFilter() {
    const filter_keywords = filterRef.current.value;
    // setSearchKey(filter_keywords)
    // setFreshLoad(false)
    if (filter_keywords.length > 0) {
      setFilteredResult(showResultRef, filter_keywords)
      setFilterValue(filter_keywords)
      setShowFocusFilterIcons(true);
    } else {
      setFilterValue(null)
      setShowFocusFilterIcons(false);
      setFilterResult([]);
    }
    hasMainContentScroll("search")
  }

  function onSubmitFilterSearch(e) {
    e.preventDefault();
    let search_val = filterRef.current.value;
    window.location.href = `/search${
      search_val.trim().length > 0 ? `?q=${search_val}` : "/"
    }`;
  }
  
  return (
    <>
      <div className="main search">
        <MetadataSetup 
          title={`${pageTitle === null ? '' : `${pageTitle} | `}OnePeople`}
          canonical="https://onepeople.online/search/"
          description="Search Page of OnePeople Online"
        />
        <div className="center row pb-3">
          <motion.div
            className="col-12 col-xxl-9 mt-1"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              style={{
                minHeight: "6.25rem",
                borderRadius: "1.875rem",
              }}
              className="search-container border-0 op-box ps-4 py-4 pe-2 overflow-hidden justify-content-center d-flex w-100"
            >
              <Card.Body className="p-0">
                <div className="search-header search pe-0 pe-md-4">
                  <div className="d-flex align-items-center">
                    <span
                      className="icon1 d-flex align-items-center justify-content-center me-2"
                      style={{ color: "white", fontSize: "1.5rem" }}
                    >
                      {searchAction === "grouping" ? (
                        searchKey !== null ? (
                          <>&#128269;</>
                        ) : searchGroupAction === "top" ? (
                          <>&#x1F3C6;</>
                        ) : searchGroupAction === "hot" ? (
                          <>&#128293;</>
                        ) : searchGroupAction === "trending" ? (
                          <>&#128200;</>
                        ) : searchGroupAction === "hot_follows" ? (
                          <>&#128276;</>
                        ) : (
                          <>&#128075;</>
                        )
                      ) : (
                        <>&#128269;</>
                      )}
                    </span>
                    <h4 className="mb-0 fw-semibold gp-header-text">
                      {searchAction === "grouping"
                        ? searchKey !== null
                          ? "Search Results"
                          : searchGroupAction === "top"
                          ? "Top Referendums"
                          : searchGroupAction === "hot"
                          ? "Hotly Contested"
                          : searchGroupAction === "trending"
                          ? "Trending"
                          : searchGroupAction === "hot_follows"
                          ? "Hot Follows"
                          : "Help Them Out"
                        : "Search Results"}
                    </h4>
                  </div>
                  <div className="d-flex">
                    {searchAction !== "explore_category" &&
                      (loadedOnce || searchStatus === "updated") && (
                        <div className="exp-grouping d-flex align-items-center pe-3">
                          <SearchGrouping
                            searchGroupAction={searchGroupAction}
                            onGroupAction={onGroupAction}
                          />
                        </div>
                      )}
                  </div>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <form
                        className="search-form"
                        onSubmit={onSubmitFilterSearch}
                      >
                        <div>
                          {!showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={searchIcon}
                              alt="search icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={filterList}
                              alt="filter icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}

                          <input
                            name="keyword"
                            type="text"
                            className="search-input-xs"
                            placeholder="Filter by keywords or enter new search"
                            ref={filterRef}
                            onChange={onKeyFilter}
                          />
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={searchIcon}
                              alt="search icon"
                              className="icon icon-right icon-xs"
                            ></motion.img>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="result-container mt-4 ps-0 ps-lg-3">
                  <div className="result-header row m-0 pe-result-header w-100">
                    <div className="col-2 dt-start-head">
                      <SortReferendumButton 
                        sortText={"Started"}
                        activeSort={activeSort}
                        sortColumn={'started'}
                        sort={sortByStarted}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"}
                      />
                      {/* <button onClick={() => {sortListBy("started")}} type="button" className={`sort-btn sort-${sortByStarted} bounce ${activeSort === 'started' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Started<img className="mx-2" src={activeSort === 'started' ? caretBlue : caretDarkGrey} /></button> */}
                    </div>
                    <div className="col-2 dt-end-head">
                      <SortReferendumButton 
                        sortText={"Closed"}
                        activeSort={activeSort}
                        sortColumn={'closed'}
                        sort={sortByClosed}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"}
                      />
                      {/* <button onClick={() => {sortListBy("closed")}} type="button" className={`sort-btn sort-${sortByClosed} bounce ${activeSort === 'closed' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Closed<img className="mx-2" src={activeSort === 'closed' ? caretBlue : caretDarkGrey} /></button> */}
                    </div>
                    <div className="col-5 px-table-item">Referendum</div>
                    <div className="col-2 result-text">Result</div>
                    <div className="col-1">Vote %</div>
                  </div>
                  <div
                    ref={resultRef}
                    onScroll={onScroll}
                    className="result-body pe-3 mz-scroll"
                  >
                    {showResultRef.length > 0
                      && filterValue === null
                      // && searchRef.current.value.length === 0
                      // && ((searchRef.current.value.length === 0 && !freshLoad) ||
                      // searchRef.current.value.length > 0 && freshLoad) 
                      &&
                      showResultRef.map((ref, index) => {
                        return (
                          <div
                            className={`row m-0 mt-${index === 0 ? "3" : "1"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="text-black">
                                {dateDisplayStringByServer(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="text-black">
                                  {dateDisplayStringByServer(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </span>
                              ) : (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </span>
                              )}
                            </div>
                            <div className={`result-percentage col-1`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    {(filterResult.length > 0 && filterValue !== null) &&
                      filterResult.map((ref, index) => {
                        return (
                          <div
                            className={`row m-0 mt-${index === 0 ? "3" : "1"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="text-black">
                                {dateDisplayStringByServer(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="text-black">
                                  {dateDisplayStringByServer(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </span>
                              ) : (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </span>
                              )}
                            </div>
                            <div className={`result-percentage col-1`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    {prevRefLength === maxRefs &&
                      loadRefStatus === "updating" && (
                        <div className="result-loader d-flex justify-content-center align-items-center flex-column my-3">
                          <OPSpinner />
                        </div>
                      )}
                    {(searchStatus === "updated" && showResultRef.length > 0 && filterResult.length === 0 && filterValue !== null) && (
                      <h5 className="text-center p-5 text-gray">
                        No Referendums Found
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && !hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        No Referendums Found
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        An error has occured! Please refresh the page.
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length > 0 && hasError && filterResult.length === 0) && (
                      <button onClick={retryLoadMore} className="retry-btn text-center py-2 text-gray w-100 border-0 bounce rounded" type="button">
                      An error occured! Please click to try again.</button>
                    )}
                    {searchStatus === "searching" && (
                      <div className="d-flex justify-content-center align-items-center flex-column my-3">
                        <OPSpinner />
                        <span>Searching...</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
          <div className="suggestions-main-container col-12 col-xxl-3 mt-4 mt-xxl-1">
            <div className="row">
              <motion.div
                className="col-12"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  title={
                    suggestedCategory.category === "random"
                      ? "Interested in these?"
                      : "More in " + suggestedCategory.category
                  }
                  unicode="&#128499;"
                  url={suggestedCategory.url}
                  updateStatus={suggestionRefsStatus}
                  referendums={suggestionRefs}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
              <motion.div
                className="col-12 mt-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  url="/hot-follows/"
                  updateStatus={hotFollowsStatus}
                  minHeight="18.875rem"
                  title="Hot Follows"
                  unicode="&#128276;"
                  referendums={hotFollowRefs}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <Footer customClass={"inside-footer"} />

    </>
  );
}

export default Search;
