import { motion } from "framer-motion";
import { useRef, useState } from "react";
import cross from "../../assets/images/others/cross.svg";
import searchIcon from "../../assets/images/home/search.svg";

function ModalSearch(props) {
  const {
    setShowMainModal,
    setSearchActive,
  } = props;

  const searchRef = useRef();
  const OpenningAnimation = {
    scale: 1,
    translateY: "0",
    top: "calc(var(--header-height) * 0.5)",
  };
  const ClosingAnimation = {
    scale: 0,
    translateY: "0",
    top: "calc(var(--header-height) * 0.5)",
  };
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  const [searching, setSearching] = useState(false);

  const toggleOffInContainer = () => {
    setShowMainModal(false);
    setSearchActive(false);
  };

  function onSubmit(e) {
    e.preventDefault();
    setSearching(true);
    if (searchRef.current.value !== "") {
      window.location.href = `/search?q=${searchRef.current.value}`;
    } else {
      // navigate(`/search`);
      window.location.href = `/search/`;
    }
  }

  return (
    <>
      <div className="OPModal position-absolute">
        <motion.div>
          <motion.div
            initial={{
              scale: 0.5,
              translateY: "0",
              top: "calc(var(--header-height) * 0.5)",
            }}
            animate={isOpen ? OpenningAnimation : ClosingAnimation}
            transition={{ type: "spring", duration: 0.3 }}
            className="modal-search-container op-box p-3 py-5 OPModalBox position-absolute mx-auto"
          >
            <div className="custom-modal-header px-5">
              <h3 className="text-center fblack">
                What would you like to know?
              </h3>
              <button
                onClick={toggleOffInContainer}
                className="btn position-absolute bounce exit-btn"
              >
                <span className="exit-container">
                  <img
                    src={cross}
                    className="exit-icon"
                    alt="Exit Modal"
                  />
                </span>
              </button>
            </div>
            <div
              className={`row px-3  login-register align-items-center justify-content-center mt-1`}
            >
              <div className={`col-12 col-md-10 col-lg-10`}>
                <form
                  onSubmit={onSubmit}
                  className="position-relative mt-2 modal-search-form"
                >
                  <div className="position-relative">
                    <img
                      src={searchIcon}
                      alt="search icon"
                      className="modal-search-icon"
                    />
                    <input
                      id="modal_search"
                      type="text"
                      name="modal-search"
                      className="hl-border"
                      placeholder="Search referendums for topics or keywords"
                      ref={searchRef}
                    />
                  </div>
                  <div className="pt-4 pt-md-5 pt-lg-5 text-center">
                    <motion.input
                      initial={{}}
                      animate={
                        searching
                          ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                          : {}
                      }
                      type="submit"
                      name="submit"
                      value="Search"
                      className="op-btn confirm-btn bounce"
                    />
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
export default ModalSearch;
