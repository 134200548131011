import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useDispatch,useSelector } from "react-redux";

import auth from "../../helpers/auth.js";
import { Link } from "react-router-dom";
//Components
import Modal from "../../components/Modal.js";
import ModalLoginRegister from "../../components/login_register/ModalLoginRegister.js";
import CenterMenu from "../others/CenterMenu.js";
// import { useNavigate } from "react-router-dom";
import ModalSearch from "../search/ModalSearch.js";

import HeaderSearchHook from "../../customHooks/HeaderSearchHook.js";
import axiosConfig from "../../helpers/axiosConfig.js"
import { setSelectedCategory } from "../../helpers/exploreSelection";
// import messageHelper from "../../helpers/messageHelper.js";
// import { setMessageValue } from "../../helpers/messageCounter.js";
import photoHelper from "../../helpers/photoHelper.js";
import { setImage } from "../../helpers/imageState.js";
import searchIcon from "../../assets/images/home/search.svg";
import openBetaLogo from "../../assets/images/home/onepeople-open-beta-logo.svg";
function Header(props) {
  const { isOnline, setIsOnline, openSidebarFunction, isAlpha, centerMenu, setCenterMenu } = props;

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showMainModal, setShowMainModal] = useState(false);
  const [showSearchMainModal, setShowSearchMainModal] = useState(false);
  function openModal() {
    setShowMainModal(true);
  }
  const imageState = useSelector(state => { return state.imageReducer.image});
  

  const [showProfileAction, setShowProfileAction] = useState(false);

  const [searchActive, setSearchActive] = useState(false);
  // const [showSearch, setShowSearch] = useState(true);

  const searchRef = useRef();

  const activeSearchHook = HeaderSearchHook();

  
  const [showQuickMessage, setShowQuickMessage] = useState(false);

  useEffect(() => {
    // let pathname = window.location.pathname.replace("/", "");
    // if (pathname === "") {
    //   setShowSearch(false);
    // }
  }, []);

  function handleProfIconClick() {
    setShowProfileAction(!showProfileAction);
  }

  function handleProfileClick() {

    let root_elem = document.getElementById("ed-root");
    root_elem.classList.remove("bg-img-error-404");
    root_elem.classList.add('bg-img')
    dispatch(setSelectedCategory(null));
    // messageHelper.getMessageCount(getMessageUpdate);
    
    setShowProfileAction(false);
    setCenterMenu((old) => {
      return old.map((val) => {
        val.active = false;
        return val;
      })
    })
  }

  
  // function getMessageUpdate(ctr) {
  //   dispatch(setMessageValue(ctr))
  // }

  function handleLogout() {
    let id = auth.getId();
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3003/op/v1/logout`:`https://api.onepeople.online/op/v1/logout`;
    axiosConfig
      .post(url, { id }, {})
      .then((res) => {})
      .catch((errors) => {
        auth.checkErrors(errors);
        console.log(errors);
      })
      .finally(() => {
        auth.logout();
        setShowProfileAction(false);
        window.location.href = '/'
        // setIsOnline(auth.isOnline());
        // navigate("/");
      });
  }

  function handleSearch() {
    setShowProfileAction(false);
    if (window.innerWidth <= 1023) {
      setShowSearchMainModal(!showSearchMainModal);
    } else {
      let curr_value = searchActive;
      let search_elem = document.getElementById("header_search");
      if (!curr_value) {
        setTimeout(() => {
          search_elem.focus();
        }, 20);
        search_elem.value = "";
      }
      setShowSearchMainModal(false);
      setSearchActive(!searchActive);
    }
  }

  function onSearch(e) {
    e.preventDefault();
    if (searchRef.current.value !== "") {
      window.location.href = `/search?q=${searchRef.current.value}`;
    } else {
      // navigate(`/search`);
      window.location.href = `/search/`;
    }
  }

  function openMobileSidebar() {
    openSidebarFunction();
    setShowProfileAction(false);
  }

useState(async ()=>{
  const handlePopState = ()=>{

    setShowProfileAction(false)
  }
  window.addEventListener('popstate',handlePopState)
  window.addEventListener('beforeunload',handlePopState)
  if(auth.isOnline()){
    let photo = await photoHelper.get();
    if(photo != null){
      dispatch(setImage(photo.blob))
    }
    
  }
  
  return ()=>{
    window.removeEventListener('popstate',handlePopState);
    window.removeEventListener('beforeunload',handlePopState);
  }
},[showProfileAction,setShowProfileAction]);

  return (
    <>
      <div className="header">
        <div className="left-nav">
          <a href="/">
            <img
              src={openBetaLogo}
              alt="OnePeopleOnline Logo"
              className="header-logo bounce"
            />
          </a>
        </div>
        {isOnline && (
          <CenterMenu centerMenu={centerMenu} setCenterMenu={setCenterMenu} setIsOnline={setIsOnline} showQuickMessage={showQuickMessage} setShowQuickMessage={setShowQuickMessage} setShowProfileAction={setShowProfileAction} />
        )}
        <motion.div
          className="right-nav d-flex align-items-center"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          {!isOnline && (
            <button onClick={openModal} className="join-btn bounce">
              Join In
            </button>
          )}

          {isOnline && (
            <>
              <div className={!centerMenu[0].active ? 'd-block' : 'd-none'}>
                <form
                  className={`search-form ${
                    (searchActive && activeSearchHook === null) ||
                    (showSearchMainModal &&
                      activeSearchHook !== null &&
                      activeSearchHook.search === "desktop") ||
                    (searchActive &&
                      activeSearchHook !== null &&
                      activeSearchHook.search === "desktop")
                      ? "active"
                      : ""
                  } pe-3`}
                  onSubmit={onSearch}
                >
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className="icon"
                    onClick={handleSearch}
                  />
                  <div>
                    <input
                      id="header_search"
                      type="string"
                      name="header-search"
                      className="hl-border"
                      placeholder="Search referendums for topics or keywords"
                      ref={searchRef}
                    />
                  </div>
                </form>
              </div>

              <div className="position-relative">

                <button
                  className="header-prof-btn"
                  onClick={handleProfIconClick}
                  style={{
                    position: "relative",
                    height: "3.75rem",
                    width: "3.75rem",
                    border: imageState ? "none":"0.1875rem solid black",
                    borderRadius: "50%",
                    backgroundColor: "transparent",
                    overflow: "hidden",
                    boxSizing: "border-box",
                  }}
                >
                
                  {
                    imageState ? <img src={imageState} className="img-fluid" alt="user profile" />: <FontAwesomeIcon
                    icon={solid("user")}
                    style={{ fontSize: "3rem", marginTop: "0.5rem" }}
                  />
                  }
                 
                </button>
                {showProfileAction && (
                  <div className="header-right-action-menu">
                    <ul className="m-0 p-0" style={{ fontSize: "0.75rem" }}>
                      <li className="bounce">
                        <Link to="/profile/" onClick={handleProfileClick}>
                          My Profile
                        </Link>
                      </li>
                      <li className="mobile-btn bounce">
                        <Link to="/my-history/" onClick={handleProfileClick}>
                          My History
                        </Link>
                      </li>
                      <li className="mobile-btn bounce">
                        <Link to="/create/" onClick={handleProfileClick}>
                          Create Referendum
                        </Link>
                      </li>
                      <li className="mobile-btn bounce">
                        <Link to="/messages/" onClick={handleProfileClick}>
                          Messages
                        </Link>
                      </li>
                      <li className="mobile-btn bounce">
                        <Link to="/leaderboards/" onClick={handleProfileClick}>
                          Leaderboards
                        </Link>
                      </li>
                      <li className="mt-0 bounce">
                        <button onClick={handleLogout}>Log Out</button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          )}
          {isOnline && (
            <div className="hamburger" onClick={openMobileSidebar}>
              <FontAwesomeIcon icon={solid("bars")} />
            </div>
          )}
        </motion.div>
      </div>

      {showMainModal && (
        <Modal>
          <ModalLoginRegister
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
          />
        </Modal>
      )}
      {((showSearchMainModal && activeSearchHook === null) ||
        (showSearchMainModal &&
          activeSearchHook !== null &&
          activeSearchHook.search === "mobile") ||
        (searchActive &&
          activeSearchHook !== null &&
          activeSearchHook.search === "mobile")) && (
        <Modal>
          <ModalSearch
            setSearchActive={setSearchActive}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowSearchMainModal}
            isAlpha={isAlpha}
          />
        </Modal>
      )}
    </>
  );
}

export default Header;
