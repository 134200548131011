import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
   name:'explore',
   initialState:{
      category:null,
      country:null,
   },
   reducers:{
      setSelectedCategory:(state,action)=>{
         Promise.resolve(
            state.category = action.payload
         )
      },
      setSelectedCountry:(state,action)=>{
        state.country = action.payload
      }
   }
})

export const {setSelectedCategory,setSelectedCountry} = counterSlice.actions

export default counterSlice.reducer