//css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

//custom css
import "./main.css";
import "./Mobile.css";
// Layouts
import Header from "./components/layouts/header";
import Sidebar from "./components/layouts/sidebar";
import Main from "./components/layouts/main";
import Footer from "./components/layouts/footer";

import { useOutlet } from "react-router-dom";
import Notification from "./components/Notification";
import { useEffect, useState } from "react";
import auth from "./helpers/auth";
import MetadataSetup from "./components/MetadataSetup";
import axiosConfig from "./helpers/axiosConfig";
import { toast } from "react-toastify";
import onePeopleLogoHorizontalColor from "./assets/images/home/onepeople-logo-horizontal-color.svg";
import { setOrigServerTime, setServerTime, setReceivedTime } from "./helpers/serverTime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import serverTimeHelper from "./helpers/serverTimeHelper";

function Root() {
  const [centerMenu, setCenterMenu] = useState([
    { label: "home", active: true },
    { label: "create", active: false },
    { label: "my-history", active: false },
    { label: "messages", active: false },
    { label: "leaderboards", active: false },
  ]);
  const serverTime = useSelector((state) => {
    return state.serverTime.currentTime;
  });
  const dispatch = useDispatch();

//   const csp = ` 
//   default-src 'self' 'api.onepeople.online'; 
//   script-src  'self' 'unsafe-inline' 'api.onepeople.online'; 
//   style-src 'self' 'unsafe-inline'; 
//   img-src 'self' data:; 
//   font-src 'self' data:; 
//   frame-src 'self;
//   connect-src api.onepeople.online;
// `; 
  const isAlpha = true;
  const [isOnline, setIsOnline] = useState(auth.isOnline());
  const outlet = useOutlet({ isOnline, setIsOnline });
  const [sidebarOpen, SetSidebarOpen] = useState(false);
  const slugRegex = /^\/referendums(\/[a-zA-Z0-9-_]+)*\/?$/;
  const slug = window.location.pathname;
  const match = slug.match(slugRegex);
  const settingStatus = {autoClose: 3000,position:"bottom-right",closeButton:"x",closeOnClick: true,pauseOnFocusLoss:true};
  if (
    slug !== "/" &&
    slug !== "/reset-password" &&
    slug !== "/privacy-policy/" &&
    slug !== "/legal-notice/" &&
    slug !== "/about-us/" &&
    slug !== "/contact-us/" &&
    isAlpha === true &&
    auth.isOnline() === false
  ) {
    window.location.href = "/";
  }


  const userCheck = () => {
    try {
      if (auth.isOnline()) {
        let url = process.env.REACT_APP_ENVIRONMENT ==="development" ? "http://127.0.0.1:3000/op/v1/auth/check":"https://api.onepeople.online/op/v1/auth/check";
        axiosConfig
          .get(url, {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          })
          .then((e) => {
            
            if(e.data !== undefined){
              const offsetTime = moment().format("Z");
              const serverTimeAlignToClient = moment(e.data.time).utcOffset(offsetTime).format('YYYY-MM-DDTHH:mm:ssZ');

              dispatch(setOrigServerTime(e.data.time));
              dispatch(setServerTime(serverTimeAlignToClient));
              dispatch(setReceivedTime(moment().format("YYYY-MM-DDTHH:mm:ssZ")));
            }
          })
          .catch((e) => {
            auth.checkErrors(e);
          });
      }
    } catch (e) {}
  };

  useEffect(() => {
    document.getElementById("ed-root")?.classList.add("bg-img");
    if (auth.isOnline()) {
      // userCheck();
      window.addEventListener('online',handleConnectivity);
      window.addEventListener('offline',handleConnectivity);
    }
    function handleConnectivity(){
      if(navigator.onLine){
       
        toast("You are now connected!",settingStatus)
      }else{
        toast("You are currently offline! ", settingStatus);
      }
    }
    if (!isOnline && isAlpha && match === null 
      && slug !== "/reset-password" 
      && slug !== '/privacy-policy/'
      && slug !== '/legal-notice/'
      && slug !== '/about-us/'
      && slug !== '/contact-us/') {
      document.getElementsByClassName("join-btn")[0].click();
    }
   const handlePopState = ()=>{
    SetSidebarOpen(false);
    document.getElementsByClassName('sidebar')[0]?.classList.remove('open')
    document.getElementsByTagName('body')[0]?.classList.remove('no-scroll')
    
   }

    window.addEventListener('popstate',handlePopState)
    
    window.addEventListener('beforeunload',handlePopState)

    return () => {
      // remove resize listener
      window.removeEventListener('popstate',handlePopState);
      window.removeEventListener('beforeunload',handlePopState);
      window.removeEventListener('online',handleConnectivity);
      window.removeEventListener('offline',handleConnectivity);
    };
  
  }, 
  // eslint-disable-next-line
  [slug, isOnline]);
  const openSidebarFunction = () => {
    if (!sidebarOpen === true) {
      document.body?.classList.add("no-scroll");
    } else {
      document.body?.classList.remove("no-scroll");
    }
    SetSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      {((isAlpha && isOnline) || !isAlpha) && (
        <>
          <Header
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            openSidebarFunction={openSidebarFunction}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
          />
          <Sidebar
            sidebarOpen={sidebarOpen}
            openSidebarFunction={openSidebarFunction}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
          />
        
            <div className={`pt-3 pt-md-4 pb-0 px-${ match ? '3' : "4"} px-md-4 px-lg-3 main-content mz-scroll`}>
              {outlet || <Main />}
            </div>
          
          <Footer customClass={"outside-footer"}/>
          <Notification />
        </>
      )}

      {isAlpha && !isOnline && (
        <>
          <MetadataSetup 
            title="OnePeople: Vote, and share." 
            canonical="https://onepeople.online/"
            description="Tap into the wisdom of the crowds, and learn something new today!"
          />
          <Header
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            openSidebarFunction={openSidebarFunction}
            isAlpha={isAlpha}
            centerMenu={centerMenu}
            setCenterMenu={setCenterMenu}
          />
          {slug !== "/reset-password" && 
          slug !== '/privacy-policy/' && 
          slug !== '/legal-notice/' && 
          slug !== '/about-us/' && 
          slug !== '/contact-us/'  ? (
            <div
              className=""
              style={{
                backgroundColor: "var(--white)",
                width: "100vw",
                height: "calc(100vh - var(--header-height))",
                backgroundImage:
                  "url("+onePeopleLogoHorizontalColor+")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "50%",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <>
              {outlet || <Main isAlpha={isAlpha} />}
              <Footer />
            </>
          )}
        </>
      )}

    </>
  );
}

export default Root;
