import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import auth from "../../../helpers/auth.js";
import moment from "moment";
import { motion } from "framer-motion";
import {
  isPast,
  dateByTimezone,
  initDateForPassing,
  isPastByServer,
  dateNowByServer,
  initDateForPassingByServer,
} from "../../../helpers/date.js";
import axiosConfig from "../../../helpers/axiosConfig";
import serverTimeHelper from "../../../helpers/serverTimeHelper.js";
import { useSelector } from "react-redux";

function ExtendSelect(props) {
  const { referendum, setReferendum, setStep } = props;
  
  const serverTime = serverTimeHelper.get();
  const initServerTime = serverTimeHelper.clientTime();
  
  const [date, setDate] = useState(new Date(referendum.end_at));
  const [time, setTime] = useState(new Date(referendum.end_at));

  const initDate = {
    min: new Date(referendum.created_at),
    max: moment(new Date(referendum.created_at)).add(7, 'days').toDate(),
  };

  const initErrors = {
    date: null,
    others: null,
  };
  const [errors, setErrors] = useState(initErrors);
  const [updating, setUpdating] = useState(false);

  function nextStep() {
    setStep("extended");
  }

  function onChangeDate(e) {
    setDate(e);
    setErrors(initDate);
  }

  function onChangeTime(e) {
    setTime(e);
    setErrors(initDate);
  }

  function onSubmit(e) {
    e.preventDefault();

    let errorTrackers = { ...errors };
    let hasErrors = false;

    if (date === undefined || time === undefined) {
      errorTrackers.date = "Please select a valid date and time. (max: 7 days)";
      hasErrors = true;
    } else {
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
      date.setMilliseconds(time.getMilliseconds());

      const dateNow = dateNowByServer(serverTime, initServerTime);
      if (isPastByServer(date, dateNow)) {
        errorTrackers.date =
          "Please select a valid date and time. (max: 7 days)";
        hasErrors = true;
      }
    }

    
    setErrors(errorTrackers);
    if (hasErrors) return;
    if (updating) return;
    setUpdating(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${referendum.slug}/update-duration`:`https://api.onepeople.online/op/v1/referendums/${referendum.slug}/update-duration`;
    axiosConfig
      .post(
        url,
        {
          closing_datetime: initDateForPassingByServer(date),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setReferendum((old_ref) => {
            let new_ref = { ...old_ref };
            new_ref.end_at = moment(date).utc().format();
            return new_ref;
          });
          nextStep();
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        let ers = errs.response.data.errors;
        if (ers) {
          ers.map((er) => {
            let param = er.path;
            if (param === "closing_datetime") {
              errorTrackers.date = er.msg;
            } else {
              errorTrackers.others = er.msg;
            }
            return er;
          });
        } else {
          errorTrackers.others = "Something went wrong. Please try again.";
        }
        setErrors(errorTrackers);
      })
      .finally(() => {
        setUpdating(false);
      });
  }

  return (
    <form className="px-4" autoComplete="off">
      <div className={`col-12 col-md-9 col-lg-8 px-2 px-md-2 px-lg-5 text-center mx-auto`}>
        <p className="op-text mt-3 text-black">
          Select a new Closing Date and Time. Please note that you may only
          update the referendum up to a maximum of 7 days from its creation
          date.
        </p>
        <div className="row mt-3 justify-content-center">
          <div className="col-auto col-md-auto col-lg-6">
            <div className="position-relative d-select border-0">
              <DatePicker
                id="closing_date"
                dateFormat="dd/MM/yyyy"
                className="px-2 w-100"
                placeholderText="Date"
                selected={date ?? undefined}
                onChange={onChangeDate}
                startDate={initDate.min}
                minDate={initDate.min}
                // endDate={initDate.max}
                maxDate={initDate.max}
                onKeyDown={(e) => {
                  e.preventDefault();
               }}
              />
              <FontAwesomeIcon
                icon={faCalendarDays}
                fontSize={"0.8rem"}
                color="#3FA778"
                aria-hidden="true"
                style={{
                  position: "absolute",
                  right: "0.8125rem",
                  top: "50%",
                  transform:"translateY(-50%)",
                  color: "#ccc",
                  zIndex: "5",
                }}
              />
            </div>
          </div>
          <div className="col-auto col-md-auto col-lg-6">
            <div className="position-relative d-select border-0">
              <DatePicker
                id="closing_time"
                className="px-2 w-100"
                selected={time ?? undefined}
                onChange={onChangeTime}
                placeholderText="Time"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                popperPlacement="bottom"
              />
              <FontAwesomeIcon
                icon={faClock}
                fontSize={"0.8rem"}
                color="#3FA778"
                aria-hidden="true"
                style={{
                  position: "absolute",
                  right: "0.8125rem",
                  top: "50%",
                  transform:"translateY(-50%)",
                  color: "#ccc",
                  zIndex: "5",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {(errors.date || errors.others) && (
        <div className="m-0 p-0 text-center">
          {errors.date && (
            <p className="m-0 p-0 input-text input-error text-red">
              {errors.date}
            </p>
          )}
          {errors.others && (
            <p className="m-0 p-0 input-text input-error text-red">
              {errors.others}
            </p>
          )}
        </div>
      )}

      <div className="d-flex justify-content-center mt-5">
        <motion.input
          initial={{}}
          animate={
            updating ? { backgroundColor: "#ccc", cursor: "not-allowed" } : {}
          }
          onClick={onSubmit}
          type="submit"
          name="submit"
          value="Update Voting"
          className="bounce op-btn extend-btn"
        />
      </div>
    </form>
  );
}

export default ExtendSelect;
