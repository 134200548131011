import Card from "react-bootstrap/Card"
function DetailsCardNoneUser(){
   return (<div>
     <Card style={{"minHeight":"20.1875rem","borderRadius":"3.125rem"}} className="border-0 op-box  px-4 py-2 overflow-hidden justify-content-center d-flex flex-wrap ">
      
      <Card.Body>
            <div className="d-flex flex-wrap align-items-center">
               <span className="icon1 d-flex align-items-center justify-content-center me-3" style={{"width":"1.25rem","height":"1.25rem","backgroundColor":"blue","color":"white"}}>
                  i
               </span>
               <h4 className="mb-0 fw-semibold">Details</h4>
            </div>
            <div className="d-flex flex-wrap justify-content-center pt-4">
               <div className="ProfilePicture" style={{width:"9.375rem", height:"9.375rem",borderRadius:"50%", border:"0.125rem solid black"}}>
                
               </div>
            </div>
            <div className="d-flex flex-wrap justify-content-left pt-3">
               <div className="d-flex col-12">
                  <div className="fw-semibold me-2" style={{color:"#ccc"}}>Name:</div>
                  <div>John Carlo Salazar</div>
               </div>
               <div className="d-flex col-12">
                  <div className="fw-semibold me-2" style={{color:"#ccc"}}>Email:</div>
                  <div>john@onepeople.online</div>
               </div>
            </div>
      </Card.Body>
      </Card>
   </div>)
}

export default DetailsCardNoneUser;