import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DetailsCardNoneUser from "../../../components/profile/nonuser/DetailsCardNoneUser"
import MyBadgesNoneUser from "../../../components/profile/nonuser/MyBadgesNoneUser"
function NonUserDetails(){
   return (
      <>
        <Row>
            <Col lg="4">
                 <DetailsCardNoneUser/>
            </Col>
            <Col lg="8">
                <MyBadgesNoneUser/>
            </Col>
        </Row>
      </>
  )
}

export default NonUserDetails;