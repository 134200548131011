

// const cleanSpaces = (str) => {
//   return str.trim().replace(/\s{2,}/g, " ");
// };

// const isEmpty = (str) => {
//   return str.length === 0;
// };

// const isEmail = (email) => {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// };

// const isValidLength = (str, min = 1, max) => {
//     return str.length >= min && str.length <= max;
// }


const hasDuplicateEntry = (arr = []) => {
    return arr.filter((item, index) => {
      if(item.length === "" ){
        return false;
      }
      return arr.indexOf(item) !== index
    }).length > 0 ? true : false;
}

export {
    hasDuplicateEntry,
}