import moment from "moment";

moment.updateLocale("en", {
  relativeTime: {
    future: "%s left",
    past: "%s ago",
    s: "%ds",
    ss: "%ds",
    m: "%dm",
    mm: "%dm",
    h: "%d hr",
    hh: "%d hrs",
    d: "%d day",
    dd: "%d days",
    w: "%d wk",
    ww: "%d wks",
    M: "%d mo",
    MM: "%d mos",
    y: "%d yr",
    yy: "%d yrs",
  },
});

const initAddDays = () => {
  // eslint-disable-next-line
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
};

const dateNow = (formatted = true) => {
  if (formatted) {
    return dateToDbFormat(moment());
  } else {
    return moment();
  }
};

const dateToDbFormat = (moment_date) => {
  return moment_date.format("YYYY-MM-DD HH:mm");
};

const isPast = (referendum_end_at) => {
  return moment(dateByTimezone(referendum_end_at)).diff(dateNow(false)) < 0
    ? true
    : false;
};


const dateByTimezone = (date = new Date(), timeZone = "Asia/Singapore") => {
  initAddDays();
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
};

const oneDayDefault = () => {
  initAddDays();
  let dt = new Date().addDays(1);
  return dateByTimezone(dt);
}

const getVotingDuration = (due_date) => {
  let dateNow = dateByTimezone();
  let refEndDate = moment(dateByTimezone(due_date));
  let result = refEndDate.from(dateNow);
  let resArr = result.split(" ");
  if (resArr.length === 2) {
    //means res is in seconds or minutes eg: 1s lef or 2m left
    if (resArr[1] === "ago") {
      return {
        icon: "grey", //ask for gray
        time_left: result,
      };
    } else {
      return { icon: "red", time_left: result };
    }
  } else {
    if (resArr[2] === "ago") {
      return {
        icon: "grey", //ask for gray
        time_left: result,
      };
    } else {
      //means hours, days, weeks, month or year
      if (
        (resArr[1] === "hr" && resArr[0] <= 3) ||
        (resArr[1] === "hrs" && resArr[0] <= 3)
      ) {
        return {
          icon: "amber",
          time_left: result,
        };
      } else {
        return {
          icon: "green",
          time_left: result,
        };
      }
    }
  }
};

const initDateForPassing = (date) => {
  var regex = /([,])/g;
  var new_date = date
    .toLocaleString("en-US", {
      timeZone: "Asia/Singapore",
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(regex, "");
  var new_date_arr = new_date.split(" ");
  return (`
  ${new_date_arr[2]}-${new_date_arr[0]}-${new_date_arr[1]} ${new_date_arr[3] === 'at' ? new_date_arr[4] : new_date_arr[3]} ${new_date_arr[3] === 'at' ? new_date_arr[5] : new_date_arr[4]}`);
};

const dateDisplayString = (date = dateByTimezone(), time = true) => {
  let format = time ? "D MMM YYYY h:mma" : "D MMM YYYY";
  return moment(dateByTimezone(date)).format(format);
};







//server related functions

const dateNowByServer = (server_time, init_server_time) => {
  const elapasedMS = new Date() - new Date(init_server_time);
  return moment(new Date(server_time)).set("milliseconds", elapasedMS).format("YYYY-MM-DDTHH:mm:ss");
}

const isPastByServer = (referendum_end_at, date_now_by_server) => {
  return moment(new Date(referendum_end_at)).diff(moment(new Date(date_now_by_server))) < 0
    ? true
    : false;
};

const dateDisplayStringByServer = (date, time = true) => {
  let format = time ? "D MMM YYYY h:mma" : "D MMM YYYY";
  if(typeof date === 'string') {
    date = new Date(date);
  }
  return moment(new Date(date)).format(format);
};

const getTimerResultByServer = (result = '') => {
  let resArr = result.split(" ");
  if(resArr.length === 0) {
    return {
      icon: "grey", //ask for gray
      time_left: undefined,
    };
  } else if (resArr.length === 2) {
    //means res is in seconds or minutes eg: 1s lef or 2m left
    if (resArr[1] === "ago") {
      return {
        icon: "grey", //ask for gray
        time_left: result,
      };
    } else {
      return { icon: "red", time_left: result };
    }
  } else {
    if (resArr[2] === "ago") {
      return {
        icon: "grey", //ask for gray
        time_left: result,
      };
    } else {
      //means hours, days, weeks, month or year
      if (
        (resArr[1] === "hr" && resArr[0] <= 3) ||
        (resArr[1] === "hrs" && resArr[0] <= 3)
      ) {
        return {
          icon: "amber",
          time_left: result,
        };
      } else {
        return {
          icon: "green",
          time_left: result,
        };
      }
    }
  }
}

const getVotingDurationByServer = (due_date, server_time, init_server_time) => {
  let dateNow = dateNowByServer(server_time, init_server_time);
  let refEndDate = moment(new Date(due_date));
  let result = refEndDate.from(dateNow);
  return getTimerResultByServer(result);
};

const getVotingDurationByOrigServer = (due_date, now) => {
  let result = moment(due_date).from(now);
  return getTimerResultByServer(result);
};

const initDateForPassingByServer = (date) => {
  if(typeof date === 'string') {
    date = new Date(date);
  }
  return moment(new Date(date)).format("YYYY-MM-DDTHH:mm:ssZ") 
}



//end of server related functions

export {
  dateNow,
  dateDisplayString,
  dateByTimezone,
  getVotingDuration,
  initDateForPassing,
  isPast,
  oneDayDefault,
  isPastByServer,
  dateNowByServer,
  getVotingDurationByOrigServer,
  getVotingDurationByServer,
  dateDisplayStringByServer,
  initDateForPassingByServer,
};
