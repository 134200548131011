function PreviewForm(props) {
  const { referendum } = props;
  
  return (
    <div className="ref-choices">
      {referendum &&
        referendum.options.map((option, index) => {
          return (
            <div className="ref-choice" key={index}>
              <div
                className={`ref-choice-label past px-3 px-md-3 px-lg-5 py-2 text-center ${
                  option.active || (referendum.voted_id && referendum.voted_id === option.id)
                      ? "active"
                      : ""
                }`}
              >
                {option.option}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PreviewForm;
