function Radio({ name, label, value, active = false, onChange = undefined }) {
    return (
      <div className="position-relative text-start radio-container">
        <label htmlFor={label}>{label}
          <input
            onChange={onChange}
            type="radio"
            name={name}
            value={value}
            id={label}
            checked={active}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
  
  export default Radio;
  