import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import caretLightGrey from "../../assets/images/referendum/caret-light-grey.svg";

//Components
import Select, { components } from "react-select";

function SelectReferendum(props) {
  const { inputId, faIcon = faFolder,customClass, inputColor, options, onChange, menuIsOpen, maxMenuHeight, placeholder, defaultValue, fontSize } = props;

  const font = fontSize ?? "0.75rem";
  const selectCustomStyles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 24,
      minHeight: "1.125rem",
      height: "1.5625rem",
      fontSize: font,
      paddingTop: 0,
      paddingBottom: 0,
      boxSizing: "border-box",
    }),
    control: (base) => ({
      ...base,
      border: "0.0625rem solid #ddd",
      minHeight: "0.9375rem",
      height: "1.5625rem",
    }),
    menu: (styles) => ({
      ...styles,
      border: "0.0625rem solid var(--color-light-3)",
      zIndex: "20",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#66a3c4"
          : isFocused
          ? "#E6F0F5"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#fff" : "#00659d",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#66a3c4"
              : undefined
            : undefined,
        },
        padding: "0.1875rem 0.75rem",
        fontSize: font,
      };
    },
    singleValue: (base) => ({
      ...base,
      color: inputColor ?? "#00659d",
      fontSize: font,
      marginTop: "-0.125rem",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: font,
      marginTop: "-0.1875rem",
    }),
    noOptionsMessage: (base) => ({
      ...base,
      fontSize: font,
      height: "1.875rem",
      marginTop: "-0.3125rem"
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={caretLightGrey}
            style={{ 
              width: "0.5625rem", 
              height: "0.5625rem", 
              transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : undefined }}
            alt={`arrow ${props.selectProps.menuIsOpen ? "up" : "down"} icon`}
          />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <>
      <div className="position-relative">
        <FontAwesomeIcon
          icon={faIcon}
          fontSize={`${font}`}
          color="#3FA778"
          aria-hidden="true"
          style={{
            position: "absolute",
            left: "0.4375rem",
            top: "0.375rem",
            color: "#ccc",
            zIndex: "5",
          }}
        />
        <Select
          inputId={inputId}
          classNamePrefix="select"
          className={customClass ?? undefined}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            // ValueContainer,
          }}
          // menuIsOpen={menuIsOpen ?? undefined}
          minHeight={20}
          maxMenuHeight={maxMenuHeight}
          onChange={onChange}
          isSearchable
          defaultMenuIsOpen={menuIsOpen ?? undefined}
          defaultValue={defaultValue ?? undefined}
          placeholder={placeholder ?? 'Select...'}
          options={options ?? {}}
          styles={selectCustomStyles}
         
        />
      </div>
    </>
  );
}

export default SelectReferendum;
