import { useState } from "react";
import { motion } from "framer-motion";
import validator from "validator";
//Components
import LoginRegisterHeader from "./login_register_header.js";
import axiosConfig from "../../helpers/axiosConfig.js";

import hidePw from "../../assets/images/profile/hide-password.svg";
import showPw from "../../assets/images/profile/show-password.svg";

function Register(props) {
  const { setEmail, setPassword, setStep, setQrCodeUrl, setManualCode } = props;

  const [name, setName] = useState("");
  const [emailIn, setEmailIn] = useState("");
  const [passwordIn, setPasswordIn] = useState("");

  const [checkedAgreement, setCheckedAgreement] = useState(false);

  const [sending, setSending] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [passwordToggled, setPasswordToggled] = useState(false);

  const initErrors = {
    name: null,
    emailIn: null,
    passwordIn: null,
    agreement: null,
    others: null,
  };
  const [errors, setErrors] = useState(initErrors);

  const initPasswordErrors = {
    chars: {
      text: 'at least 8 characters long',
      valid: true
    },
    lowerUpperNumberSymbol: {
      text: 'a mix of lower case, upper case, numbers, and special characters',
      valid: true
    }
  }
  const [passwordErrors, setPasswordErrors] = useState(initPasswordErrors);

  function handleName(e) {
    setName(e.target.value);
    setErrors({ ...errors, name: null, others: null });
  }

  function handleEmail(e) {
    setEmailIn(e.target.value);
    setErrors({ ...errors, emailIn: null, others: null });
  }

  function handlePassword(e) {
    setPasswordIn(e.target.value);
    setErrors({ ...errors, passwordIn: null, others: null });
  }

  function handleAgreement() {
    setCheckedAgreement(!checkedAgreement);
    setErrors({ ...errors, agreement: null, others: null });
  }

  function validatePassword (userdata) {
    let newPassErrors = JSON.parse(JSON.stringify(initPasswordErrors));
    if(!validator.isStrongPassword(userdata.password, {minLowercase: 1,minUppercase: 1, minNumbers: 1,minSymbols: 1})) {
      newPassErrors.lowerUpperNumberSymbol.valid = false;
    }
    
    if(!validator.isStrongPassword(userdata.password, {minLength: 8})) {
      newPassErrors.chars.valid = false;
    }

    setPasswordErrors(newPassErrors);
  }

  async function onSubmit(e) {
    e.preventDefault();
    setErrors(initErrors)

    let userdata = {
      fullname: name,
      email: emailIn,
      password: passwordIn,
    };

    let errorTracker = errors;
    let hasError = false;

    //Empty Checking
    if (validator.isEmpty(userdata.fullname)) {
      errorTracker = { ...errorTracker, name: "Displayed name is required" };
      hasError = true;
    }

    if (validator.isEmpty(userdata.email)) {
      errorTracker = { ...errorTracker, emailIn: "Email is required" };
      hasError = true;
    } else if (!validator.isEmail(userdata.email)) {
      errorTracker = { ...errorTracker, emailIn: "Email is invalid" };
      hasError = true;
    }

    if (validator.isEmpty(userdata.password)) {
      errorTracker = { ...errorTracker, passwordIn: "Password is required" };
      hasError = true;
    // } else if (!validator.isLength(userdata.password, { min: 8 })) {
    //   errorTracker = {
    //     ...errorTracker,
    //     passwordIn: "Your password must be at least 8 characters",
    //   };
    //   hasError = true;
    } else if (
      !validator.isStrongPassword(userdata.password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        minLength: 8,
      })
    ) {
      validatePassword(userdata)
      errorTracker = {
        ...errorTracker,
        passwordIn: "Password is not strong enough",
      };
      hasError = true;
    }

    if (!checkedAgreement) {
      errorTracker = {
        ...errorTracker,
        agreement: "You must agree to the terms to proceed",
      };
      hasError = true;
    }

    setErrors(errorTracker);

    if (hasError) return;

    if (sending) return;

    setSending(true);
    setRegisterError(false);
    let url = process.env.REACT_APP_ENVIRONMENT ==="development" ? "http://127.0.0.1:3003/op/v1/register":"https://api.onepeople.online/op/v1/register";
    axiosConfig
      .post(url, userdata)
      .then((res) => {
        if (res.data.success) {
          // setStep("register_qr");
          setStep("register_verify_email");
          setSending(false);
          // setQrCodeUrl(res.data.qrcode);
          // setManualCode(res.data.code);
          setEmail(userdata.email);
          setPassword(userdata.password);
        }
      })
      .catch((err) => {
        let errors = err.response.data.errors;
        if (errors) {
          if (errors[0].msg === "connect ETIMEDOUT") {
            errorTracker.others = "Something went wrong. Please try again.";
          } else {
            errors.map((obj, index) => {
              let params = obj.path;
              if (params) {
                if (params === "fullname") {
                  errorTracker.name = obj.msg;
                } else if (params === "email") {
                  errorTracker.emailIn = obj.msg;
                } else {
                  //password
                  errorTracker.passwordIn = obj.msg;
                  if(obj.msg === 'Password is not strong enough') {
                    validatePassword(userdata)
                  }
                }
              } else {
                errorTracker.others = obj.msg;
              }
              return obj;
            });
          }
        } else {
          errorTracker.others = "Something went wrong. Please try again.";
        }

        setErrors(errorTracker);
      })
      .finally(() => {
        setSending(false);
      });
  }

  return (
    <>
      <form autoComplete="off" className=" position-relative">
        <LoginRegisterHeader text="Create Account" uni="&#128221;" />
        <div className="mt-5">
          <div className="m-0 p-0 d-flex justify-content-between">
            <p className="m-0 p-0 input-text input-error text-gray">
              Displayed Name
            </p>
            {errors.name && (
              <p className="m-0 p-0 input-text text-red text-end">{errors.name}</p>
            )}
          </div>
          <input
            value={name}
            onChange={handleName}
            type="text"
            name="name"
            className="form-control mt-1 hl-border"
            autoComplete="off"
          />
        </div>
        <div className="mt-2">
          <div className="m-0 p-0 d-flex justify-content-between">
            <p className="m-0 p-0 input-text input-error text-gray">Email</p>
            {errors.emailIn && (
              <p className="m-0 p-0 input-text text-red text-end">{errors.emailIn}</p>
            )}
          </div>
          <input
            value={emailIn}
            onChange={handleEmail}
            type="text"
            name="email"
            className="form-control mt-1 hl-border"
            autoComplete="off"
          />
        </div>
        <div className="mt-2">
          <div className="m-0 p-0 d-flex justify-content-between">
            <p className="m-0 p-0 input-text text-gray">Password</p>
            {errors.passwordIn && (
              <p className="m-0 p-0 input-text text-red text-end">{errors.passwordIn}</p>
            )}
          </div>
          <div className="d-flex position-relative input-with-toggle">
            <input
              value={passwordIn}
              onChange={handlePassword}
              type={passwordToggled ? "text" : "password"}
              className="form-control hl-border"
              id="new-password"
              name="password"
              autoComplete="new-password"
            ></input>
            <button
              type="button"
              className="border-0 position-absolute"
              onClick={() => setPasswordToggled(!passwordToggled)}
            >
              {passwordToggled ? (
                <motion.img
                  whileHover={{ scale: 1 }}
                  src={hidePw}
                  style={{ width: "1.25rem", height: "1.25rem", scale: 0.9 }}
                />
              ) : (
                <motion.img
                  whileHover={{ scale: 1 }}
                  src={showPw}
                  style={{ width: "1.25rem", height: "1.25rem", scale: 0.9 }}
                />
              )}
            </button>
          </div>
        </div>
        {sending && (
          <motion.div className="mt-2">
            <p className="m-0 p-0 input-text text-center text-gray">
              Registering...
            </p>
          </motion.div>
        )}
        {registerError && (
          <motion.div className="mt-2">
            <p className="m-0 p-0 input-text text-center text-red">
              Something went wrong...
            </p>
          </motion.div>
        )}
        {errors.passwordIn === 'Password is not strong enough' && (
          <motion.div className="mt-2">
            <p className="m-0 p-0 input-text text-start text-gray">
              Your password needs to be:
            </p>
            <ul className="reg-requirements input-text text-gray p-0 m-0 ps-3">
              <li className="p-0 m-0">{passwordErrors.chars.text}</li>
              <li className="p-0 m-0">{passwordErrors.lowerUpperNumberSymbol.text}</li>
            </ul>
          </motion.div>
        )}
        
        <div className="form-check mt-3 d-flex align-items-center">
          <input
            className="form-check-input position-relative me-2 hl-border"
            style={{ top: "-0.125rem" }}
            type="checkbox"
            checked={checkedAgreement}
            onChange={handleAgreement}
            name="register-agreement"
            id="register_agreement"
          />
          <label
            className="form-check-label input-text text-black"
            htmlFor="register_agreement"
          >
            I agree to the <a href="/legal-notice/" target="_blank">Terms</a> and{" "}
            <a href="/privacy-policy/" target="_blank">Privacy Policy</a>.
          </label>
        </div>
        {errors.agreement && (
          <div className="m-0 p-0 mt-3 input-text text-red">
            {errors.agreement}
          </div>
        )}
        {errors.others && (
          <div className="m-0 p-0 input-text text-red">{errors.others}</div>
        )}
        <div className="mt-4 text-center bounce">
          <motion.input
            initial={{}}
            animate={
              sending ? { backgroundColor: "#ccc", cursor: "not-allowed" } : {}
            }
            onClick={onSubmit}
            type="submit"
            name="submit"
            value="Register"
            className="op-btn register-btn bounce"
          />
        </div>
      </form>
    </>
  );
}

export default Register;
