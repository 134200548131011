import { Card, Row, Col } from "react-bootstrap";
import layout from "../privacy/layout.module.css";
import auth from "../../helpers/auth";
import { useState } from "react";
import MetadataSetup from "../../components/MetadataSetup";

export default function LegalNotice() {
  // eslint-disable-next-line
  const [onlineStatus, setOnlineStatus] = useState(auth.isOnline());

  return (
    <>
      <MetadataSetup 
        title="Legal Notice | OnePeople" 
        canonical="https://onepeople.online/legal-notice/"
        description="Here are the terms and conditions for the use of OnePeople.online."
      />
      <Row className={`${onlineStatus ? "" : "main d-grid ref-view reset-logout pt-4 mx-auto " }about-us-container justify-content-center`}>
        <Col md={12} lg={12} xl={11} xxl={9} className={onlineStatus ? "" : "mx-auto"}>
          <Card
            className={`about-us-content op-box OPcard-rounded card-transparent ${layout.maxHeight} mz-scroll-none pe-1`}
          >
            <div className="mz-scroll">
              <Card.Header className="text-center clear-header py-3 fblack pt-5">
                <h1 className="FS25 fbold">Legal Notice</h1>
              </Card.Header>
              <Card.Body className={`pt-4 px-4 p-lg-5 ${layout.cardP}`}>
                <p>
                  Welcome to Edge Digital. Please read our Legal Notice
                  carefully (including our Privacy Policy) before using the
                  website (“the sites”) and/or the services (both defined
                  below), so that you are aware of your legal rights and
                  obligations with respect to Edge Digital and its websites,
                  edgedigital.sg and OnePeople.online (“Edge Digital”, “us”, “we” or
                  “our”).
                </p>
                <p>
                  The terms and conditions (“terms”) in this Legal Notice serve
                  as an agreement between you, your child or ward and Edge
                  Digital. By accessing this site, you hereby accept and comply
                  to be legally bound by this agreement (as defined below). If
                  you do not accept this agreement, please leave the sites and
                  discontinue use of the services immediately.
                </p>
                <div className="pt-4">
                  <ol className={`${layout.orderedList}`}>
                    <li className="mt-4">
                      <div className={layout.b}>Agreement</div>

                      <ol className={`${layout.orderedList}`}>
                        <li className={`${layout.az}`}>
                          <div className={`${layout.b}`}>
                            You hereby represent and warrant that:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              you have read, agree and consent to this
                              agreement, including this Legal Notice and the
                              terms of our{" "}
                              <a href="/privacy-policy">Privacy Policy</a> as
                              amended from time to time, and the terms of the{" "}
                              <a href="/privacy-policy">Privacy Policy</a> are
                              incorporated into this agreement by reference;
                            </li>
                            <li>
                              <div>
                                you are at least 18 years old and have the
                                necessary legal capacity, right, power and
                                authority to agree to this agreement and you are
                                either;
                              </div>
                              <ol className={`${layout.AlphaNumericList}`}>
                                <li>
                                  accessing the sites, using the services and
                                  contracting in your own personal capacity;
                                </li>
                                <li>
                                  accessing the sites, using the services and
                                  contracting on behalf of a corporate entity;
                                </li>
                                <li>
                                  contracting as a parent or guardian of a child
                                  or ward who is less than 18 years old and who
                                  is accessing the sites and using the services,
                                  in which event you agree as their
                                  parent/guardian, both in your personal
                                  capacity and on behalf of your child/ward, to
                                  be bound by this agreement and be liable for
                                  your child’s/ward’s actions and omissions
                                  while accessing the sites and/or using the
                                  services, and you also agree to ensure that
                                  your child/ward observes this agreement;
                                </li>
                              </ol>
                            </li>
                            <li>
                              you are authorised to bind the entity on whose
                              behalf you are contracting and such entity agrees
                              to ensure that your child/ward observes this
                              agreement;
                            </li>
                            <li>
                              all of the information provided by you to Edge
                              Digital (including without limitation personal
                              particulars and contact information) is accurate
                              and complete. If in connection with your use of
                              the sites and/or services, you provide the
                              Personal Data of any third parties, you further
                              warrant and represent that these third parties
                              have also consented to the terms of the Privacy
                              Policy, and to the collection, use and disclosure
                              of their Personal Data in accordance with the
                              aforesaid.
                            </li>
                          </ol>
                        </li>
                        <li className={layout.az}>
                          <div className={layout.b}>
                            Edge Digital reserves the right to change, modify,
                            suspend or discontinue the whole or any portion of
                            the services or site at any time. Edge Digital may
                            also impose limits on certain features or restrict
                            your access to the parts or the entire services or
                            site without notice or liability.
                          </div>
                        </li>
                        <li className={layout.az}>
                          <div className={layout.b}>
                            Edge Digital may from time to time vary, replace,
                            delete, supplement or amend this agreement by
                            posting the amended agreement at the sites. Any use
                            of the services and the amendment of this agreement
                            will be deemed to be accepted by you. If you do not
                            agree to the amended agreement, you have the right
                            to close your account and/or cease using the
                            services.
                          </div>
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={`${layout.b}`}>Definitions</div>
                      <ol className={layout.orderedList}>
                        <li className={layout.az}>
                          <div className={layout.b}>
                            In this agreement, the following definitions shall
                            apply unless the context does not permit such
                            application
                          </div>
                          <div className={`${layout.az}`}>
                            <div>
                              <span className={layout.b}>“Account”</span> means
                              a registered account of a member opened under the
                              sites or any app.
                            </div>
                            <div>
                              <span className={layout.b}>“Agreement”</span>{" "}
                              means the agreement formed by this Legal Notice
                              and the Privacy Policy. “Computer” means your
                              computer, notebook computer, personal digital
                              assistant, mobile phone or other electronic device
                              used to access the sites or the services.
                            </div>
                            <div>
                              <span className={layout.b}>“Content”</span> means
                              materials, information, news, advertisements,
                              listings, data, input, text, songs, audio, video,
                              pictures, graphics, software, blogs, webcasts,
                              podcasts, broadcasts, messages, comments,
                              suggestions, ideas and other content.
                            </div>
                            <div>
                              <span className={layout.b}>“Display Name” </span>{" "}
                              is defined as a name you want to be known as to
                              the community.
                            </div>
                            <div>
                              <span className={layout.b}> “Linked Sites”</span>{" "}
                              is defined in Clause 7.
                            </div>
                            <div>
                              <span className={layout.b}>“Member”</span> means a
                              registered member of the sites.
                            </div>
                            <div>
                              <span className={layout.b}>“Password”</span> means
                              a string of characters that allow access to a
                              computer system or service.
                            </div>
                            <div>
                              <span className={layout.b}>“Personal Data”</span>{" "}
                              means data, whether true or not, about an
                              individual who can be identified from that data or
                              from that data and other information to which a
                              party has or is likely to have access.
                            </div>
                            <div>
                              <span className={layout.b}>“Record”</span> means
                              any evidence on the site that is in both written
                              and electronic form.
                            </div>
                            <div>
                              <span className={layout.b}>“Servers”</span> means
                              software, systems and servers hosting, operating,
                              managing, providing or contributing to the sites
                              and the services.
                            </div>
                            <div>
                              <span className={layout.b}>“Services”</span> is
                              defined in{" "}
                              <span className={layout.b}>Clause 3</span>.
                            </div>
                            <div>
                              <span className={layout.b}>“Site”</span> or{" "}
                              <span className={layout.b}>“Sites”</span> shall
                              mean any of the sites owned or operated by Edge
                              Digital including OnePeople and/or platform and
                              includes but is not limited to the material and
                              information contained in the sites and/or
                              platform, including any content and RSS feeds,
                              APIs, software code and scripts and any other
                              downloadable materials displayed or incorporated
                              therein, as well as the services offered thereon,
                              as may be applicable.
                            </div>
                            <div>
                              <span className={layout.b}>
                                “Third Party Products”
                              </span>{" "}
                              means products and services of third parties,
                              including other users, advertised on or available
                              at the website or sites linked from this site.
                            </div>
                            <div>
                              <span className={layout.b}>
                                “Third Party User Content”
                              </span>{" "}
                              means all user content which is not created,
                              transmitted, posted or uploaded by you.
                            </div>
                            <div>
                              <span className={layout.b}>“User Content”</span>{" "}
                              means all content on the sites which is created,
                              transmitted, posted or uploaded by a user of the
                              site.
                            </div>
                          </div>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The words “include” and “including” shall not be
                          construed as having any limiting effect.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The headings in this agreement do not have any legal
                          effect nor shall they affect the construction of this
                          agreement in any way.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Site and Services</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          The websites are owned and maintained by Edge Digital.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          We may offer one or more of the following services on
                          or through the sites (each a “service” and
                          collectively the “services”):
                          <ol className={layout.letterList}>
                            <li>
                              access to the collection of information, data,
                              text, listing, graphics, images, videos, audio
                              files, podcasts, webcasts, software applications
                              and other types of works; search engines or tools;
                            </li>
                            <li>
                              a platform to create, upload and publicly make
                              available personalised content;
                            </li>
                            <li>a social networking platform;</li>
                            <li>email alerts; and</li>
                            <li>
                              any other features, content or applications that
                              we may offer on or through the sites from time to
                              time in its sole and absolute discretion.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You acknowledge and agree that to access and use
                          certain services, you will be required to register as
                          a member and additionally shall be bound to strictly
                          comply with the{" "}
                          <a href="/privacy-policy">Privacy Policy</a> and/or
                          such other terms and conditions as we may prescribe in
                          addition to this Legal Notice and other terms of this
                          agreement.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          From time to time, Edge Digital may run promotions and
                          campaigns on the site. These are subject to additional
                          terms and conditions that will be made available at
                          the time they are run.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Intellectual Property</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          The copyright, patents, trademarks, registered designs
                          and all intellectual property rights in the services,
                          the sites and all content, including without
                          limitation the copyright in the compilation of all
                          User Content, shall vest in and remain with Edge
                          Digital.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The trademarks, logos and service marks (“marks”)
                          displayed on the sites are the property of Edge
                          Digital or other third parties, and all rights to the
                          marks are expressly reserved by Edge Digital or
                          relevant third parties. You shall not use any marks
                          without the prior written consent of Edge Digital or
                          such third party. Edge Digital and its subsidiaries
                          aggressively enforce their intellectual property
                          rights to the fullest extent of the law. The name of
                          Edge Digital, OnePeople.online or any other marks may
                          not be used in any way, including in any advertising
                          or publicity, or as a hyperlink without prior written
                          permission of Edge Digital.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The domain names on which the sites are hosted is the
                          sole property of Edge Digital and you may not use or
                          otherwise adopt a similar name for your own use.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          If you have any questions about this agreement or any
                          issues raised in this agreement or on the site, please
                          contact us at{" "}
                          <a href="mailto:enquiries@edgedigital.sg">
                            enquiries@edgedigital.sg
                          </a>
                          .
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Online Conduct</div>
                      <ol className={layout.orderedList}>
                        <li className={layout.az}>
                          <div className={layout.b}>You hereby undertake:</div>
                          <ol className={layout.letterList}>
                            <li>
                              to comply with this agreement, and such other
                              notices or guidelines that may be posted on the
                              sites by us from time to time (which are hereby
                              incorporated by reference into this agreement);
                            </li>
                            <li>
                              not to use any service or content for unlawful
                              purpose, and to comply with all applicable laws
                              and regulations, including without limitation,
                              copyright law, the Protection from Online
                              Falsehoods and Manipulation Act 2019 (No. 18 or
                              2019) and the Protection from Harassment Act (Cap.
                              256A);
                            </li>
                            <li>
                              not to hack into, interfere with, disrupt,
                              disable, over-burden or otherwise impair the
                              proper working of the site, services or servers,
                              which shall include but is not limited to
                              denial-of-service-attacks, spoof attacks, session
                              hacking, sniffing, tampering, reverse engineering
                              or reprogramming;
                            </li>
                            <li>
                              not use any automated process, use any kind of
                              scripting software or bots or service to access
                              and/or use the sites and/or services; and
                            </li>
                            <li>
                              not to use the account of another member at any
                              time, without their permission.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You acknowledge and agree that you are responsible for
                          the security of your Account details and/or Password,
                          and that any unauthorised sharing or disclosure may
                          result in your Account being identified as an
                          inauthentic online account as defined in the
                          Protection from Online Falsehoods and Manipulation Act
                          2019 (“POFMA”). Further, any misuse of such an account
                          may constitute an offence under the Computer Misuse
                          Act 1993, or the Protection from Online Falsehoods and
                          Manipulation Act 2019 (“POFMA”).
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You shall provide Edge Digital with accurate, complete
                          and up-to-date Account information and further ensure
                          that the information is kept updated and remains
                          current, accurate and complete. Failure to do so shall
                          constitute a break of our Online Conduct, which may
                          result in the restriction, suspension or immediate
                          termination of your Account.
                        </li>
                        <li className={`${layout.az}`}>
                          <div className={` ${layout.b}`}>
                            As part of the registration process for your
                            Account, Edge Digital may put in place reasonable
                            verification measures and/or conduct additional
                            verification where required. Further, you will
                            select a Password. You are reminded to have a strong
                            Password and to regularly change your Password to
                            prevent any unauthorised access of your Account. In
                            addition, you may not:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              select or use a Display Name with the intent to
                              misrepresent your identity or to mislead any
                              person as to your identity (e.g to suggest an
                              affiliation to / association with an organisation
                              when no such affiliation / association exists);
                            </li>
                            <li>
                              use a Display Name subject to the rights or any
                              other person without authorisation; or
                            </li>
                            <li>
                              use a Display Name that Edge Digital, in its sole
                              discretion, deems inappropriate or offensive.
                            </li>
                            <li>
                              You shall promptly notify Edge Digital of any
                              known or suspected unauthorised use(s) of your
                              Account, or any known or suspected breach of
                              security, including loss, theft, or unauthorised
                              disclosure of your Password. You shall be solely
                              responsible for safeguarding and maintaining the
                              confidentiality of your Password. We may also
                              prescribe other security procedures applicable to
                              the Services, which you are advised to comply
                              with.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You shall promptly notify Edge Digital of any known or
                          suspected unauthorised use(s) of your Account, or any
                          known or suspected breach of security, including loss,
                          theft, or unauthorised disclosure of your Password.
                          You shall be solely responsible for safeguarding and
                          maintaining the confidentiality of your Password. We
                          may also prescribe other security procedures
                          applicable to the Services, which you are advised to
                          comply with.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You shall be bound and responsible for, and Edge
                          Digital shall be entitled to rely on, all
                          communications transmitted through the use of your
                          Password, and all such communications shall be deemed
                          to be communications made and issued by you.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You shall be responsible for all User Content,
                          messages and all online activity at the Site
                          transmitted or conducted through the use of your
                          Password.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Edge Digital shall have no responsibility or liability
                          for any loss, damage, cost, expenses or liabilities
                          arising as a result of or in connection with the
                          wrongful or fraudulent use of your Password.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree that Edge Digital reserves the right to
                          change or re-assign Password(s) at its sole discretion
                          by giving you notice. Edge Digital shall not be liable
                          for any loss, damage, cost or expense incurred by you
                          as a result of such change or re-assignment.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree that by providing the information requested
                          for your Account, you hereby consent to Edge Digital’s
                          collection, use and disclosure of all such information
                          and information relating to your use of the Site or
                          Services as stated in{" "}
                          <span className={layout.b}>Clause 3</span> of our{" "}
                          <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={`${layout.b}`}>
                            You agree that Edge Digital has the right in its
                            sole and absolute discretion and without notice to:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              restrict, suspend, or terminate your access to all
                              or any part of the Services; and/or
                            </li>
                            <li>
                              terminate, deactivate or suspend your Account,
                              delete your Account and all related information
                              and files in your Account, without assigning any
                              reason. Without prejudice to the generality of the
                              above, Edge Digital reserves the right to
                              deactivate your Account if you are in breach of
                              any of this Legal Notice, or if Edge Digital
                              believes that you have been using the Account for
                              unlawful and/or undesirable activities.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree to not hold Edge Digital liable or
                          responsible for any loss or damage incurred by you
                          arising out of or in connection with the suspension
                          and/or termination of your Account.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You represent and warrant that you have read and agree
                          to be bound by our{" "}
                          <a href="/privacy-policy">Privacy Policy</a> and this{" "}
                          <a href="/legal-notice">Legal Notice</a>.
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={`${layout.b}`}>
                            You acknowledge that the Site and Services are used
                            by a community of users, and you hereby agree and
                            undertake:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              to treat all users with respect and civility;
                            </li>
                            <li>
                              not to annoy, pester, solicit, harass, threaten,
                              offend, insult, abuse, defame, or injure any other
                              users, or commit any unlawful or tortious acts
                              against other users;
                            </li>
                            <li>
                              not to spam, mail bomb, send viruses, spyware,
                              malware, worms or other damaging material, or act
                              in any manner that adversely affects the user of
                              the Services by other users;
                            </li>
                            <li>
                              not to harvest or otherwise collect information
                              about users, including email addresses, without
                              Edge Digital’s prior written consent
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={layout.b}>
                            You agree that when using the Services;
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              you will not alter, delete, manipulate, undermine
                              or interfere with the listings or postings of any
                              other Member.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Any infringing, fraudulent, abusive, or otherwise
                          illegal activity, or any breach of the{" "}
                          <a href="/privacy-policy">Privacy Policy</a> or this{" "}
                          <a href="/legal-notice">Legal Notice</a>, shall be
                          grounds for termination of your use of the Site and
                          Services and/or your Account, at Edge Digital’s sole
                          and absolute discretion, and you may be reported to
                          appropriate law-enforcement agencies.
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={layout.b}>
                            You agree that you will not permit or cause your
                            Account to be controlled by a bot (as defined in the
                            Protection from Online Falsehoods and Manipulation
                            Act 2019) without the prior written authorisation of
                            Edge Digital. Should you receive prior written
                            authorisation of Edge Digital, you agree that (in
                            addition to any conditions that may be attached by
                            Edge Digital in approving the use of a bot):
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              you will ensure and procure that no false
                              statement of fact is communicated in Singapore
                              through your Account;
                            </li>
                            <li>
                              you will ensure and procure that the Account is
                              not involved in any coordinated inauthentic
                              behaviour;
                            </li>
                            <li>
                              you will ensure and procure that the use of the
                              bot to control the Account in order to communicate
                              and/or interact with end-users of any internet
                              intermediary service offered by Edge Digital is
                              effectively disclosed to such end-users; and
                            </li>
                            <li>
                              you shall be bound and be responsible for any act
                              carried out by the bot as if the same were carried
                              out by you. For the purposes of this{" "}
                              <span className={layout.b}>clause 5.17</span>,
                              “coordinated inauthentic behaviour” means any
                              coordinated activity carried out using two or more
                              online accounts (whether or not such accounts are
                              created with Edge Digital) in order to mislead
                              end-users of any internet intermediary service
                              offered by Edge Digital as to any matter. Examples
                              of coordinated inauthentic behaviour include
                              manipulating trending topics or artificially
                              boosting the popularity of content or of any
                              online account.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You acknowledge that Edge Digital may be required
                          under applicable law or upon the receipt of legitimate
                          instructions from government authorities, to carry out
                          acts in breach of the terms of this Agreement, and you
                          hereby agree to not hold Edge Digital liable or
                          responsible for any such breach of the Agreement.
                          During election periods in any country, including
                          elections to any political office or national
                          referendums, Edge Digital may further conduct
                          additional verification of accounts of accounts and/or
                          pages belonging to political parties and candidates.
                        </li>
                        <li className={layout.az}>
                          <div className={layout.b}>You hereby agree that:</div>
                          <ol className={layout.letterList}>
                            <li>
                              All records of Edge Digital relating to the
                              Services, your use or access of the Services or
                              the Site, your Account, your particulars, any
                              Content, or this Agreement, though in electronic
                              form, are in written documents, and you shall not
                              dispute or challenge the validity or
                              enforceability of any Record on the grounds that
                              it is not in a written document, is in electronic
                              form, or was produced by or is the output of a
                              computer system, and you hereby waive any such
                              right you may have at law; and
                            </li>
                            <li>
                              the Records, though in electronic form, are
                              original documents, and you will not challenge the
                              admissibility of any Record on the grounds that it
                              is made in electronic form.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>
                        Disclaimers and Limitations
                      </div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          While we make every effort to ensure that all of our
                          content displayed on the sites is accurate and
                          complete, we provide the content with informative
                          purposes on an “as is”, “as available” basis only
                          without warranties of any kind either express or
                          implied, including, but not limited to, implied
                          warranties of satisfactory quality, merchantability or
                          fitness for a particular purpose, compliance with
                          description, or the warranty of non-infringement.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Without limiting the foregoing, Edge Digital does not
                          warrant that the functions contained in or access to
                          or use of the site, services, our content or other
                          content will be timely, secure, uninterrupted or
                          error-free without omission, that defects will be
                          corrected, or that the site, services, our content or
                          servers are free of viruses or other harmful
                          components, or that the download, installation or use
                          of any of our content in or with any computer will not
                          affect the functionality or performance of the
                          computer. Edge Digital does not warrant or make any
                          representations regarding the use or the results of
                          the use of the Edge Digital content, the services, the
                          sites or the servers in terms of their correctness,
                          accuracy, completeness, reliability or otherwise.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree that you shall bear and assume all risk
                          arising out of your use of the site, services, Edge
                          Digital content or the servers including, without
                          limitation the entire cost of all necessary servicing,
                          repair, or correction including any defect, problem or
                          damage in any computer. Without limiting the
                          foregoing, you agree to not hold Edge Digital liable
                          for the loss of any of your User Content that is due
                          to any circumstances beyond our control.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The data and information made available on the sites
                          are of a general nature and do not purport, and shall
                          not in any way be deemed, to constitute an offer or
                          provision of any professional or expert advice. You
                          should at all times consult a qualified expert or
                          professional adviser to obtain advice and independent
                          verification of the information and data contained
                          herein before acting on it.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Any financial or investment information on the sites
                          are for use in Singapore only and are intended to be
                          for your general information only. You should not rely
                          upon such information in making any particular
                          investment or other decision which should only be made
                          after consulting with a fully qualified financial
                          adviser. Such information does not and is not intended
                          to constitute any form of investment advice or any
                          inducement, invitation or recommendation relating to
                          any of the products listed or referred to. Any
                          arrangement between you and a third party named on or
                          linked to from these pages is at your sole risk and
                          responsibility. Edge Digital does not sponsor, endorse
                          or promote any financial products, services or
                          information.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Your acknowledge that it is not Edge Digital’s policy
                          to exercise editorial control over, and to review,
                          edit or amend any data, information, materials or
                          contents of any User Content, posting, email or any
                          information that may be inserted or made available on
                          the sites by other users of the services and that Edge
                          Digital does not endorse and shall not be responsible
                          for any such content.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You acknowledge and agree that Edge Digital does not
                          endorse or recommend, is not an agent, reseller or
                          distributor of, and has no control over Third Party
                          Products, whether available or advertised via the
                          sites or on linked sites.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree that all statements, offers, information,
                          opinions, materials, User Content and Third Party
                          Products, from other users and from advertisers and
                          other third parties on the sites should be used,
                          accepted and relied upon only with care and discretion
                          and at your own risk and we shall not be responsible
                          for any loss, damage or liability incurred by you
                          arising from such use or reliance.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You also acknowledge and agree that some services may
                          enable other users to upload content to the sites and
                          that some of these may be offensive, annoying,
                          unlawful, in breach of this agreement, contain viruses
                          or cause you damage. While we may remove any such User
                          Content brought to our notice at our sole and absolute
                          discretion, you acknowledge and agree that we cannot
                          be responsible or liable for any User Content, and you
                          agree to exercise and use User Content only at your
                          own risk and with care and discretion.
                        </li>
                        <li className={layout.az}>
                          <div className={layout.b}>You agree that:</div>
                          <ol className={layout.letterList}>
                            <li>
                              we shall be entitled at any time, at its sole and
                              absolute discretion and without prior notice, to
                              add to, vary, terminate, withdraw or suspend the
                              operation of the whole or any part or feature of
                              the sites or services without assigning any
                              reason; and
                            </li>
                            <li>
                              access to or the operation of the site, servers
                              and/or the services may from time to time be
                              interrupted or encounter technical or other
                              problems and may not necessarily continue
                              uninterrupted or without technical or other
                              errors;
                            </li>
                            <li>
                              and in any such event, we shall not be liable for
                              any loss, liability or damage which may be
                              incurred as a result.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          In no event shall Edge Digital be liable to you for
                          any damages, losses, expenses, liabilities under any
                          causes of action (whether in contract or tort
                          including, but not limited to negligence, or
                          otherwise) caused through the use of, or the inability
                          to use, the Edge Digital content, User Content,
                          services, Third Party Products, any computers, the
                          sites or any other site.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Under no circumstances, including, but not limited to,
                          negligence shall Edge Digital be liable for any
                          indirect, special, consequential, loss of profits,
                          loss of business or incidental damages suffered by any
                          party or that result from (A) this agreement; or (B)
                          the use of, of the inability to use, the Edge Digital
                          content, services, site or any other site even if Edge
                          Digital or an authorised Edge Digital representative
                          has been advised of, or should have foreseen, the
                          possibility of such damages.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          To the maximum extent permitted by applicable law,
                          Edge Digital disclaims all liability for any direct,
                          incidental or consequential damage or loss suffered by
                          you that may result from the collection, use of
                          disclosure of your Personal Data, including but not
                          limited to any loss of, or any inability to retrieve,
                          any Personal Data, howsoever caused or any inaccuracy
                          in the Personal Data presented, used or transmitted.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You agree that the above exclusions and limitations of
                          liability are reasonable and enable the services and
                          Edge Digital content to be provided by us at either
                          reasonable costs or no costs to you.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Linked Sites</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          Edge Digital may provide links to other sites (“linked
                          sites”) that may be of relevance and interest to
                          users. Edge Digital has no control over, and is not
                          responsible for, the content on the linked sites or
                          for any damage you may incur from the linked sites
                          (including any virus, spyware, malware, worms, errors
                          or damaging material contained in the linked sites) or
                          the availability of any content on the linked sites,
                          and you hereby irrevocably waive any claim against us
                          with respect to the linked sites.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Data Use and Privacy</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          Please do not submit any personal information or data
                          without first reading our{" "}
                          <a href="/privacy-policy">Privacy Policy</a> which
                          explains our data use and privacy practices in detail.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Should you have any feedback or enquiries relating to
                          your personal data or wish to stop receiving
                          promotional or marketing messages from Edge Digital,
                          write to us at the provided email address of our
                          Personal Data Protection Officer in{" "}
                          <span className={layout.b}>Clause 21.3</span>.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The foregoing consents provided by you in Clause 1
                          supplements but does not supersede nor replace any
                          other consents you may have previously provided to us
                          in respect of your Personal Data, and your consents
                          herein are additional to any rights which we may have
                          at law to collect, use or disclose your Personal Data.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The site may provide links to linked sites whose data
                          protection and privacy practices may differ from those
                          set forth here. Edge Digital is not responsible for
                          the content and privacy practices of these other
                          websites and you agree to review and abide by the data
                          protection and privacy notices of those sites.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          The site uses cookies. A cookie is a small text file
                          which is placed on your device whenever you run or use
                          the site. These cookies collect information about your
                          use of the site. We use cookies and other related
                          technologies to facilitate your use of the sites and
                          to improve your experience of the site. You may change
                          the settings on your device to block the use of
                          cookies. However, if you choose to block the cookies
                          used on the site, you may not be able to use certain
                          features and functions of the site.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Termination</div>
                      <ol className={layout.orderedList}>
                        <li className={layout.az}>
                          <div className={layout.b}>
                            You agree that Edge Digital has the right in its
                            sole and absolute discretion and without notice to
                            restrict, suspend, or terminate your account and/or
                            your access to all or any part of the sites or
                            services, without assigning any reason. Grounds for
                            such termination may include but are not limited to:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              a breach of any of the terms under the agreement;
                            </li>
                            <li>violation of any applicable law;</li>
                            <li>
                              fraudulent, harassing, defamatory, threatening or
                              abusive behaviour; and/or
                            </li>
                            <li>
                              behaviour that is harmful to other users, third
                              parties, or the business interests of Edge
                              Digital.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Use of your account, the sites and/or the services for
                          illegal, fraudulent, harassing, defamatory,
                          threatening or abusive purposes may be referred to law
                          enforcement authorities without prior notice to you.
                        </li>
                        <li className={`${layout.az}`}>
                          <div className={`${layout.b}`}>
                            Upon suspension or termination of your account or
                            access to all or any part of the sites or services;
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              all rights and/or licenses granted to you under
                              this agreement shall immediately terminate;
                            </li>
                            <li>
                              we may remove or discard from the services content
                              or any content associated with your account; and
                            </li>
                            <li>
                              you remain liable for all obligations you may have
                              incurred under this agreement.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className={"mt-4"}>
                      <div className={layout.b}>
                        Notification of Infringement
                      </div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b} `}>
                          Edge Digital reserves the right to investigate notices
                          of copyright, trademark and other intellectual
                          property infringement (“infringement”) in respect of
                          our content, User Content and other material on the
                          sites (“infringing material”) and take appropriate
                          action. If you believe that your work has been copied
                          in a way that constitutes infringement and such
                          infringement is occurring on the site, please notify
                          us in writing immediately in the form and containing
                          the information prescribed by the Singapore Copyright
                          Act (Cap. 63) (“infringement notice”).
                        </li>
                        <li className={`${layout.az} ${layout.b} `}>
                          All infringement notices shall be sent to us at{" "}
                          <a href="mailto:enquiries@edgedigital.sg">
                            enquiries@edgedigital.sg
                          </a>
                          .
                        </li>
                        <li className={`${layout.az} ${layout.b} `}>
                          We will duly consider all infringement notices
                          submitted. In return, you agree that you shall not
                          take any legal action or exercise any legal remedy you
                          may have against us in respect of any infringing
                          material, unless you have first given Edge Digital the
                          infringement notice and sufficient opportunity to
                          remove the infringing material, and thereafter Edge
                          Digital refuses or fails to remove the infringing
                          material within a reasonable time. Where Edge Digital
                          removes the infringing material in response to your
                          infringing notice, you agree not to exercise and you
                          hereby waive, any right of action against us under
                          applicable law which you may have in respect of any
                          infringing material appearing on the sites prior to
                          such removal by Edge Digital.
                        </li>
                        <li className={`${layout.az} ${layout.b} `}>
                          You acknowledge and agree that Edge Digital has no
                          control and cannot undertake responsibility or
                          liability in respect of infringing material appearing
                          on linked sites or other third party sites.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Content</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          Edge Digital reserves the right to investigate notices
                          of infringement under the Protection of Online
                          Falsehoods and Manipulation Act 2019 (No. 18 of 2019),
                          including without limitation the Code of Practice for
                          Transparency of Online Political Advertisements and
                          Code of Practice for Preventing and Countering Abuse
                          of Online Accounts (“relevant notices”) received in
                          respect of content, User Content and other material on
                          the sites and take such action as it considers
                          appropriate to comply with the requirements of the
                          said act and/or codes.
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={`${layout.b}`}>
                            If you believe that any Edge Digital content, User
                            Content and other material on the sites contravenes
                            the said act or any other written law, including
                            without limitation where:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              there are online political advertisements that are
                              undisclosed or miscategorised;
                            </li>
                            <li>
                              there is a suspected abuse of advertising tools by
                              malicious actors; and/or
                            </li>
                            <li>
                              there are compromised end-user account(s) and/or
                              accounts(s) that impersonate any “verified”
                              accounts.
                            </li>
                          </ol>
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={`${layout.b}`}>
                            If you detect any suspected vulnerabilities or
                            weaknesses in the services, the sites, systems,
                            resources and/or processes which may potentially
                            affect Edge Digital’s internet-accessible services,
                            notify us immediately or as soon as possible at{" "}
                            <a href="mailto:enquiries@edgedigital.sg">
                              enquiries@edgedigital.sg
                            </a>
                            . This <span className={layout.b}>Clause 11.3</span>{" "}
                            does not authorise or permit the taking of any
                            action which may contravene applicable laws and
                            regulations, including without limitation the
                            Computer Misuse Act (Cap. 50A) and all of Edge
                            Digital’s rights and remedies are expressly
                            reserved. Without limiting the generality of the
                            foregoing, any exploitation or testing of suspected
                            vulnerabilities or gaining unauthorised access to
                            any computer program or data is prohibited, and you
                            agree and undertake not to:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              publish or publicly disclose any suspected
                              vulnerability to any third party;
                            </li>
                            <li>
                              deploy destructive, disruptive or other unlawful
                              means to detect vulnerabilities, including without
                              limitation any attacks on physical security,
                              social engineering, denial of service, brute force
                              attacks; and
                            </li>
                            <li>
                              exploit, test or otherwise use any suspected
                              vulnerability, including without limitation taking
                              any steps(s) to access, copy, create, delete,
                              modify, manipulate or download any data or
                              programme, build system backdoor(s), modify system
                              configuration(s), facilitate or share system
                              access.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className={"mt-4"}>
                      <div className={layout.b}>Jurisdictional Issues</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          The websites are owned and operated by Edge Digital in
                          Singapore. Edge Digital makes no representation that
                          the contents of the sites are appropriate or available
                          for use in your location. Those who choose to access
                          the sites from any location do so on their own
                          initiative and are responsible for compliance with
                          local laws, if and to the extent local laws are
                          applicable.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Indemnity</div>
                      <ol className={layout.orderedList}>
                        <li className={layout.az}>
                          <div className={layout.b}>
                            You agree to indemnify and hold Edge Digital and its
                            subsidiaries, affiliates, officers, agents, partners
                            and employees, harmless from all claims, demands,
                            actions, proceedings, liabilities (including
                            statutory liability and liability to third parties),
                            penalties, and costs (including without limitation,
                            legal costs on a full indemnity basis), awards,
                            losses and/or expenses, due to or arising out of:
                          </div>
                          <ol className={layout.letterList}>
                            <li>any use of the sites or service;</li>
                            <li>your connection to the site;</li>
                            <li>
                              your breach of any terms and conditions of this
                              agreement;
                            </li>
                            <li>
                              your violation of any rights of another person or
                              entity; or
                            </li>
                            <li>
                              your breach of any statutory requirement, duty or
                              law.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Severability</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          If any provision of this agreement is found to be
                          illegal, void or unenforceable by law that is
                          applicable hereto or if any court of competent
                          jurisdiction in a final decision so determines, this
                          agreement shall continue in force save that such
                          provision shall be deemed to be deleted.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}> Relationship of Parties</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          Nothing in this agreement shall constitute of be
                          deemed to constitute an agency, partnership or joint
                          venture between Edge Digital and you and neither party
                          shall have any authority to bind the other in any way.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}> Waiver</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          No waiver of any rights or remedies by Edge Digital
                          shall be effective unless made in writing and signed
                          by an authorised representative of Edge Digital.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          A failure by Edge Digital to exercise or enforce any
                          rights conferred upon it by this agreement shall not
                          be deemed to be a waiver or variation of any such
                          rights or operate so as to bar the exercise or
                          enforcement thereof at any subsequent time or times.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}> Assignment</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          You may not assign this agreement or any of its rights
                          or obligations under this agreement to any party.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          Edge Digital may, by notice in writing posted on this
                          site, assign all of its rights and interests relating
                          to or in connection with this agreement, the sites
                          and/or services to a wholly-owned subsidiary of Edge
                          Digital (the “transferee”) without your consent.
                        </li>
                        <li className={`${layout.az} `}>
                          <div className={layout.b}>
                            Without prejudice and in addition to the right under{" "}
                            <span className={layout.b}>Clause 17.2</span> above,
                            Edge Digital may, by notice in writing posted on
                            this site (the “notification”), transfer and/or
                            novate all of its rights, interests, obligations and
                            liabilities relating to or in connection with this
                            agreement, the sites and or/services to the
                            transferee, and you shall not withhold your consent
                            thereto. For the avoidance of doubt, you hereby
                            consent to:
                          </div>
                          <ol className={layout.letterList}>
                            <li>
                              the assignment of all rights of Edge Digital
                              relating to or in connection with this agreement,
                              the sites and/or services to the transferee,
                            </li>
                            <li>
                              the assumption by the transferee of all
                              liabilities of Edge Digital relating to or in
                              connection with this agreement, the sites and/or
                              services,
                            </li>
                            <li>
                              the release of all obligations of Edge Digital
                              relating to or in connection with this agreement,
                              the sites and/or services, and
                            </li>
                            <li>
                              the transferee taking the place of Edge Digital as
                              if it were named in all documents relating to or
                              in connection with this agreement, the sites
                              and/or services as a party thereto in place of
                              Edge Digital, in each case with effect from such
                              effective date as may be informed in the
                              notification.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Rights of Third Parties</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          Except as provided for in{" "}
                          <span className={layout.b}>Clause 17</span>, a person
                          or entity who is not a party to this agreement shall
                          have no right under the contracts (Rights of Third
                          Parties) Act (Cap. 53B) to enforce any term of this
                          agreement, regardless of whether such person or entity
                          has been identified by name, as a member of a class or
                          as answering a particular description.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Force Majeure</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          No party shall be liable for any failure to perform
                          its obligations under this agreement if the failure
                          results from a Force Majeure Event (defined below),
                          provided always that whenever possible, the affected
                          party will resume that obligation as soon as the Force
                          Majeure Event occasioning the failure ceases or
                          abates.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          For purposes of this agreement, a{" "}
                          <span className={layout.b}>
                            “Force Majeure Event”
                          </span>{" "}
                          is an event which is a circumstance or event beyond
                          the reasonable control of a party which affects the
                          general public in that party’s country or in the
                          territory, and which results in the party being unable
                          to observe or perform on time an obligation under this
                          agreement. Such circumstance or event shall include
                          industrial action or labour disputes, civil unrest,
                          war or threat of war, criminal or terrorist acts,
                          government action or regulation, telecommunication or
                          utility failures, power outages, explosions, natural
                          physical disasters, epidemic, quarantine restrictions,
                          and general failure of public transport.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>
                        Governing Law and Jurisdiction
                      </div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          This agreement and all matters relating to your access
                          to, or use of, the sites and services shall be
                          governed and construed in accordance with the laws of
                          Singapore including without limitation the provisions
                          of the Singapore Evidence Act (Chapter 97) and the
                          Electronic Transactions Act (Cap. 88), without giving
                          effect to any principles of conflicts of law.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          You hereby agree to submit to the non-exclusive
                          jurisdiction of the Singapore courts.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-4">
                      <div className={layout.b}>Contact Us and Notice</div>
                      <ol className={layout.orderedList}>
                        <li className={`${layout.az} ${layout.b}`}>
                          If you have any feedback or enquiries relating to this
                          site and/or the services, and/or this agreement,
                          please contact us.
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          All notices required or permitted to be given
                          hereunder to Edge Digital in writing and in the
                          English language shall be sent via email to{" "}
                          <a href="mailto:enquiries@edgedigital.sg">
                            enquiries@edgedigital.sg
                          </a>
                          .
                        </li>
                        <li className={`${layout.az} ${layout.b}`}>
                          To contact our Personal Data Protection Officer,
                          please write to us at{" "}
                          <a href="mailto:enquiries@edgedigital.sg">
                            {" "}
                            enquiries@edgedigital.sg
                          </a>
                          .
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
