import axios from "axios";
import {AES,enc,SHA256} from "crypto-js"; 
const expiration = 58;
var csrfHelper = {
      get:async ()=>{
        return new Promise(async (resolve)=>{
         let storedCookie = getCookie('csrf');
          
         if(storedCookie === null || storedCookie === ""){
          let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3000/op/v1/get-csrf":"https://api.onepeople.online/op/v1/get-csrf";
            await axios.post(url,{getToken:true}).then(function(e){
               if(e.data.csrfToken !== undefined){
                  setCookie('csrf',e.data.csrfToken,expiration)
                   resolve(e.data.csrfToken);
               }
            })
         }else{
           let isExpired = isCookieExpired('csrf');
         
            if(!isExpired){
               resolve(storedCookie);
            }else{
               removeCookie('csrf')
               await csrfHelper.get().then(e=>{
                resolve(e);
               });
             
            }

         }
        })
      },
      initialize:async ()=>{
        csrfHelper.get();
      }


}

function setCookie(name, value, minutes) {
  var expires = "";
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  
  const encrypted = AES.encrypt(value, process.env.REACT_APP_MESSAGE_SECRET).toString();
  const name_encrypted = SHA256(name.toString()).toString();

  
  document.cookie = name_encrypted + "=" + encrypted + expires + "; path=/; secure; samesite=strict";
}
 // Get the value of a cookie with the given name
 function getCookie(name) {
  const name_encrypted = SHA256(name.toString()).toString();
   var nameEQ = name_encrypted + "=";
   var cookies = document.cookie.split(";");

   for (var i = 0; i < cookies.length; i++) {
     var cookie = cookies[i];
     while (cookie.charAt(0) === " ") {
       cookie = cookie.substring(1, cookie.length);
     }
     if (cookie.indexOf(nameEQ) === 0) {
     let temp_val = cookie.substring(nameEQ.length, cookie.length)
       const decrypted = AES.decrypt(temp_val,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
      
       return decrypted;
     }
   }
   return null;
 }

 function removeCookie(name) {
    const name_encrypted = SHA256(name.toString()).toString();
    document.cookie = name_encrypted + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  function isCookieExpired(cookieName) {
    // Retrieve the cookie value
    const cookies = document.cookie.split(';');
    if(cookies.length > 0 && cookies[0].length > 0) {
      const name_encrypted = SHA256(cookieName.toString()).toString();
      const cookie = cookies.find(c => c.trim().startsWith(name_encrypted + '='));
    
      if (cookie) {
        // Extract the expiration date from the cookie
        const cookieParts = cookie.split('=');
        const cookieValue = cookieParts[1];
        const expirationDate = new Date(cookieValue);
    
        // Get the current date and time
        const currentDate = new Date();
    
        // Compare the expiration with the current date
        if (expirationDate <= currentDate) {
          return true; // Cookie has expired
        }
      }
    }
    
  
    return false; // Cookie is still valid
  }



export default csrfHelper;