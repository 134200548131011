import axios from "axios";
import {AES,enc,SHA256} from "crypto-js"; 
import moment from "moment";
import messageHelper from "./messageHelper";
const expiration = 30;
let cookiename = "servertime";

var serverTimeHelper = {
      get: ()=>{
        let storedCookie = getCookie(cookiename);
      
       return (storedCookie);
      },
      set:(time)=>{
        setCookie(cookiename,time,expiration)
      },
      clientTime:()=>{
        const offsetTime = moment().format("Z");
        const serverTimeAlignToClient = moment(serverTimeHelper.get()).utcOffset(offsetTime).format('YYYY-MM-DDTHH:mm:ssZ');
        return serverTimeAlignToClient
      },
      refreshServerTime:()=>{
        setCookie(cookiename,"refreshing",expiration)
        return new Promise(async(resolve,error)=>{
          await messageHelper.getMessageCount().then(()=>{
            resolve(true);
          }).catch(e=>{
            console.log(e)
          })
        });
      },
      isRefereshing:()=>{
        return getCookie(cookiename) == "refreshing"
      },
      initialize:async ()=>{
        serverTimeHelper.get();
      },
      isExpired:()=>{
        return isCookieExpired()
      }


}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  const encrypted = AES.encrypt(value, process.env.REACT_APP_MESSAGE_SECRET).toString();

  const name_encrypted = SHA256(name.toString()).toString();
  document.cookie = name_encrypted + "=" + encrypted + expires + "; path=/; secure; samesite=strict";
}
 // Get the value of a cookie with the given name
 function getCookie(name) {
  const name_encrypted = SHA256(name.toString()).toString();
   var nameEQ = name_encrypted + "=";
   var cookies = document.cookie.split(";");
  
   for (var i = 0; i < cookies.length; i++) {
     var cookie = cookies[i];
     while (cookie.charAt(0) === " ") {
       cookie = cookie.substring(1, cookie.length);
     }

     if (cookie.indexOf(nameEQ) === 0) {
     let temp_val = cookie.substring(nameEQ.length, cookie.length)
       const decrypted = AES.decrypt(temp_val,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
    
       return decrypted;
     }
   }
   return null;
 }

 function removeCookie(name) {
    const name_encrypted = SHA256(name.toString()).toString();
    document.cookie = name_encrypted + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  function isCookieExpired() {
    const now = new Date();
    const serverTime = serverTimeHelper.get();
    const serverAlignToClient = serverTimeHelper.clientTime();
    let res = true;
    if(serverTime){
      const thirtyMinutesLater = moment(serverAlignToClient).clone().add(30, 'minutes');

      res = moment(now).isSameOrAfter(thirtyMinutesLater);
    }else{
      
    }
    return res;
    // return false;
     

  }



export default serverTimeHelper;