import { motion } from "framer-motion";
import { useState } from "react";

import ModalHeader from "../others/ModalHeader";

function ModalResetPasswordMsg(props) {
  const { setShowMainModal, isAlpha, setStep } = props;

  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);


  const toggleOffInContainer = () => {
    if(isAlpha) {
      setStep(null)
    } else {
      setShowMainModal(false);
    }
  };

  return (
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="auth-container op-box p-0 p-md-3 py-5 py-md-5 OPModalBox position-absolute mx-auto"
        style={{ width: "100%", minHeight: "11.875rem" }}
      >
        <ModalHeader
          title="Trouble signing in?"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3  login-register align-items-center justify-content-center`}
        >
          <div className={`login-container col-12 px-5 text-center`}>
            <p className="auth-text">
              Click on the link sent to your email address to reset your password.
            </p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
export default ModalResetPasswordMsg;
