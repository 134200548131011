import cross from "../../assets/images/others/cross.svg";

function ModalHeader(props) {
  const { title, toggleOffInContainer, isAlpha } = props;

  return (
    <>
      <div className="custom-modal-header">
        <h3 className="text-center fblack">{title}</h3>
        {isAlpha ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="btn position-absolute bounce exit-btn"
          >
            <span className="exit-container">
              <img
                src={cross}
                className="exit-icon"
                alt="Exit Modal"
              />
            </span>
          </button>
        ) : (
          <button
            onClick={toggleOffInContainer}
            className="btn position-absolute bounce exit-btn"
          >
            {" "}
            <span className="exit-container">
              <img
                src={cross}
                className="exit-icon"
                alt="Exit Modal"
              />
            </span>
          </button>
        )}
      </div>
    </>
  );
}

export default ModalHeader;
