import { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { dateDisplayStringByServer, getVotingDurationByOrigServer, dateNowByServer } from "../../helpers/date";
import { motion } from "framer-motion";
import MetadataSetup from "../../components/MetadataSetup.js";

import {
  setSelectedCategory,
  setSelectedCountry,
} from "../../helpers/exploreSelection";
import { useDispatch, useSelector } from "react-redux";
//Components
import Suggestions from "../referendums/Suggestions";
import { useNavigate, useNavigationType } from "react-router-dom";
import OPSpinner from "../../components/others/OPSpinner";
import OPSelectCategory from "../../components/inputs/OPSelectCategory";
import OPSelectCountry from "../../components/inputs/OPSelectCountry";
import axiosConfig from "../../helpers/axiosConfig";
import auth from "../../helpers/auth";
import categoryCacheHelper from "../../helpers/categoryCacheHelper";
import countryCacheHelper from "../../helpers/countryCacheHelper";
import SortReferendumButton from "../../components/others/SortReferendumButton.js";

import searchIcon from "../../assets/images/home/search.svg";
import filterList from "../../assets/images/others/filter-list.svg";
import Footer from "../../components/layouts/footer.js";
import { hasMainContentScroll, getSearchResultBodyHeight } from "../../helpers/jsHelper";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
function Explore() {
  const dispatch = useDispatch();

  // const root_url = "https://onepeople.online";
  const navigate = useNavigate();
  const navigateType = useNavigationType();
  // const category = useSelector((state) => state.catandcountry.category);
  // const country = useSelector((state) => state.catandcountry.country);
  const [searchStatus, setSearchStatus] = useState("searching");
  const searchRef = useRef(null);
  const [filterValue, setFilterValue] = useState(null);
  const initSuggestedCategory = {
    category: "random",
    slug: "random",
  };

  window.onbeforeunload = () => {
    dispatch(setSelectedCategory(null));
    dispatch(setSelectedCountry(null));
  };

  const [suggestedCategory, setSuggestedCategory] = useState(
    initSuggestedCategory
  );

  const [suggestionRefs, setSuggestionRefs] = useState([]);
  const [suggestionRefsStatus, setSuggestionRefsStatus] = useState("updating");

  const [loadedOnce, setLoadedOnce] = useState(false);
  const [loadRefStatus, setLoadRefStatus] = useState(null);
  const [loadRefIteration, setLoadRefIteration] = useState(null);
  const [prevRefLength, setPrevRefLength] = useState(null);

  const maxRefs = 15;

  const [showResultRef, setShowResultRef] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [showFocusFilterIcons, setShowFocusFilterIcons] = useState(false);

  const countrySelect = useRef(null);
  const categorySelect = useRef(null);

  const [onCountrySelect, setOnCountrySelect] = useState(null);
  const [onCategorySelect, setOnCategorySelect] = useState(null);

  // const [queryParameters] = useSearchParams();
  const [categoryOpt, setCategoryOpt] = useState(null);
  const [countryOpt, setCountryOpt] = useState(null);

  const resultRef = useRef(null);

  //suggestions
  //hot follows
  const [hotFollowsStatus, setHotFollowsStatus] = useState("updating");
  const [hotFollowRefs, setHotFollowRefs] = useState([]);

  //categories and countries filter
  const [countries, setCountries] = useState([]);
  const [countriesStatus, setCountriesStatus] = useState("updating");
  const [categories, setCategories] = useState([]);
  const [categoriesStatus, setCategoriesStatus] = useState("updating");

  const [hasError, setHasError] = useState(false);
  const pathname = window.location.pathname;

  const [sortByStarted, setSortByStarted] = useState('desc');
  const [sortByClosed, setSortByClosed] = useState('desc');
  const [activeSort, setActiveSort] = useState('started');

  window.addEventListener("resize", () => {
    hasMainContentScroll("search")
    getSearchResultBodyHeight();
  })

  useEffect(
    () => {
      getSearchResultBodyHeight();
      const setupPage = async () => {
        getFilter("categories").then((res) => {
          let cgs = res;
          getFilter("countries").then((res2) => {
            resetSort();
            let cts = res2;
            let paths_arr = window.location.pathname.split('/').filter(str  => str.length > 0);
            
            let search_category = null
            let search_country = null;

            if(paths_arr.length > 1) {
              paths_arr.map((path) => {
                if(path !== 'explore') {
                  let country_obj = cts.filter((ct) => ct.value === path);
                  let category_obj = cgs.filter((cg) => cg.value === path);
                  if(country_obj.length > 0) {
                    search_country = path
                  }
                  if(category_obj.length > 0) {
                    search_category = path
                  }
                }
                return path;
              })
            }
            
            setCategoryOpt(search_category);
            setCountryOpt(search_country);
            setOnCategorySelect(search_category);
            setOnCountrySelect(search_country);

            getByCategory({category: search_category, country: search_country});
            getHotFollowReferendums();
            setLoadedOnce(true);
          }).catch((err) => {
            pageError()
          }).finally(() => {
            hasMainContentScroll("search")
          })
        }).catch((err) => {
          pageError()
        })
      }

      if(!loadedOnce || navigateType === 'POP') {
        setupPage();
      }
    },
    // eslint-disable-next-line
    [
      pathname
    ]
  );

  function pageError() {
    setCategoriesStatus("updated");
    setCountriesStatus("updated");
    getSuggestions([], "category");
    getHotFollowReferendums();
    setShowResultRef([]);
    setHasError(true);
    setSearchStatus("updated");
  }

  
  function sortListBy (sortColumn) {
    if(searchStatus === 'updated') {
      resetFilterHandler();
      let sortBy = sortColumn;
      let sort = sortColumn === 'started' ? sortByStarted : sortByClosed;
      setActiveSort(sortColumn);
      
      sort = sort === 'desc' ? 'asc' : 'desc';
      if(sortColumn === 'closed') {
        setSortByClosed(sort)
        setSortByStarted('desc')
      } else {
        setSortByStarted(sort)
        setSortByClosed('desc')
      }

      let category = onCategorySelect ?? categoryOpt;
      let country = onCountrySelect ?? countryOpt;
      getByCategory({category, country, sort, sortBy});
    }
  }

  async function getByCategory({
    category = null, 
    country = null, 
    iteration = null, 
    sort = 'desc',
    sortBy = 'started'
  }) {
    setHasError(false)
    let query = "";
    if (iteration === null) {
      setSearchStatus("searching");
      setShowResultRef([]);
    } else {
      setLoadRefStatus("updating");
    }
    if (category !== null || country !== null) {
      
      let query_ctr = 0;

      if (category !== null) {
        query += "?category=" + category;
        query_ctr += 1;
      }

      if (country !== null) {
        if (query_ctr > 0) {
          query += "&country=" + country;
        } else {
          query += "?country=" + country;
        }
      }

      if (iteration !== null) {
        query += "&iteration=" + iteration;
      }

    } else {
      query = "?q=null";
      if (iteration !== null) {
        query += "&iteration=" + iteration;
      }
    }

    query += `&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}`
    let refLength = 0;
    let refs = [];
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/search" + query: "https://api.onepeople.online/op/v1/referendums/search" + query;
    axiosConfig
    .get(url, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
      },
    })
    .then((res) => {
      if (res.data.success) {
        setLoadRefIteration(iteration);
        refs = res.data.referendums;
        refs.forEach((ref) => {
          
          let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
          let duration = getVotingDurationByOrigServer(ref.end_at, dateNow);
          ref.duration = duration;
        });
        setPrevRefLength(res.data.referendums.length);
        refLength = res.data.referendums.length;
        if (iteration !== null) {
          setShowResultRef((old_refs) => [...old_refs, ...refs]);
        } else {
          setShowResultRef(refs);
          getSuggestions(refs, "category", category);
          resetLoader();
        }
      }
    })
    .catch((errs) => {
      auth.checkErrors(errs);
      if(iteration !== null) {
        getSuggestions([], "category");
      } else {
        getSuggestions([], "category");
        setShowResultRef([]);
      }
      setHasError(true);
    })
    .finally(() => {
      if (iteration !== null) {
        setLoadRefStatus("updated");
        // setFilteredResult(refs, searchRef.current.value);
      } else {
        setSearchStatus("updated");
        if(refLength === maxRefs) {
          loadMore({bypass: true, category, country, sort, sortBy});
        } else {
          // setFilteredResult(refs, searchRef.current.value);
        }
      }
      hasMainContentScroll("search")
    });
  }

  async function getHotFollowReferendums() {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=hot_follows&limit=4" : "https://api.onepeople.online/op/v1/referendums/suggestions?group=hot_follows&limit=4";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setHotFollowRefs);
        }
      })
      .catch((errs) => {
        
        auth.checkErrors(errs);
        setupSuggestions([], setHotFollowRefs);
      })
      .finally(() => {
        setHotFollowsStatus("updated");
        hasMainContentScroll("search")
      });
  }

  const setupSuggestions = (refs, setRef) => {
    refs = refs.slice(0, 4).map((ref, index) => {
      
      let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
      let tempStatus = getVotingDurationByOrigServer(ref.end_at, dateNow);
      ref = { ...ref, ...tempStatus };
      return ref;
    });
    setRef(refs);
  };
  
  function resetSort() {
    setSortByStarted("desc");
    setSortByClosed("desc");
    setActiveSort("started");
  }
  
  function getSort() {
    let sort, sortBy = activeSort;
    if(activeSort === 'started') {
      sort = sortByStarted;
    } else {
      sort = sortByClosed;
    }
    return {sort, sortBy}
  }


  const onSelectExplore = () => {
    // console.log('from on select')
    resetLoader();
    let category = categorySelect.current.value.length > 0 ? categorySelect.current.value : null;
    let country = countrySelect.current.value.length > 0 ? countrySelect.current.value : null;
    
    let query = "?";
    let queryCtr = 0;

    setShowResultRef([]);
    resetFilterHandler();

    let nav_url = '/explore';


    if (category !== "" && category !== null) {
      query += "category=" + category;
      queryCtr += 1;
      nav_url += `/${category}`
    }

    if (country !== "" && country !== null) {
      if (queryCtr === 0) {
        query += "country=" + country;
      } else {
        // eslint-disable-next-line
        query += "&country=" + country;
      }
      nav_url += `/${country}`
    }

    setCategoryOpt(category);
    setCountryOpt(country);
    
    setOnCategorySelect(category);
    setOnCountrySelect(country);

    nav_url += '/'
    resetSort();
    getByCategory({category, country});
    navigateToExploreURL(nav_url);
  };

  const navigateToExploreURL = (query) => {
    navigate(`${query}`);
  };

  
  function setFilteredResult (refs, key) {
    let new_result = refs.filter((ref) => {
      return (
        ref.title.toLowerCase().includes(key.toLowerCase()) ||
        ref.result.toLowerCase().includes(key.toLowerCase()) ||
        (ref.option &&
          ref.option.toLowerCase().includes(key.toLowerCase()))
      );
    });
    setFilterResult(new_result);
  }

  function resetFilterHandler() {
    if(searchRef.current.value !== '' && searchRef.current.value !== null && searchRef.current.value !== undefined) {
    } else {
      searchRef.current.value = '';
      setShowFocusFilterIcons(false);
      setFilterValue(null)
    }
    setFilterResult([]);
  }


  //Search through Title
  function onKeyFilter(e) {
    const search_keywords = searchRef.current.value;
    if (search_keywords.length > 0) {
      setFilteredResult(showResultRef, search_keywords)
      setFilterValue(search_keywords)
      setShowFocusFilterIcons(true);
    } else {
      resetFilterHandler();
    }
    hasMainContentScroll("search");
  }

  function loadMore({
    bypass = false, 
    category = null, 
    country = null, 
    sort = null,
    sortBy = 'started'
  }) {
    // console.log('ey 2')
    if(!hasError) {
      // console.log('ey 3')
      if (bypass || prevRefLength === maxRefs) {
        // console.log("ey 4")
        if (loadRefStatus === "updating") return;
        // console.log('ey 5')
        setLoadRefStatus("updating");
  
        let iteration = bypass ? null : loadRefIteration;
        if (iteration === null) {
          iteration = 1;
        } else {
          iteration = iteration + 1;
        }
  
        // console.log('--opts')
        // console.log(categoryOpt);
        // console.log(countryOpt);
        // console.log('--onSelect')
        // console.log(onCategorySelect);
        // console.log(onCountrySelect);


        category = bypass ? category : categoryOpt ?? onCategorySelect;
        country = bypass ? country : countryOpt ?? onCountrySelect;
        // console.log("{category: category, country: country}");
        // console.log({category: category, country: country});

        let sort_obj = getSort();
        getByCategory({category, country, iteration, sort: bypass ? sort : sort_obj.sort, sortBy});
      }
    }
  }
  
  function retryLoadMore() {
    setHasError(false);
    loadMore(getSort());
  }

  function resetLoader() {
    setLoadRefIteration(null);
  }

  function getSuggestions(refs, action, query_category = null) {
    let request_suggestion = "random";
    let suggestedSlugInArray = [];
    let suggestedNameKey = {};

    if (refs.length > 0) {
      refs.forEach((ref) => {
        ref.categories.forEach((category) => {
          if (category.slug !== query_category) {
            suggestedSlugInArray.push(category.slug);
            suggestedNameKey[category.slug] = category.category;
          }
        });
      });
      const suggestedCtr = suggestedSlugInArray.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});

      const sortedCategories = Object.keys(suggestedCtr).sort(
        (a, b) => suggestedCtr[b] - suggestedCtr[a]
      );

      // console.log(sortedCategories);

      let suggested_category_slug;
      let suggested_category_name;

      if (action === "category") {
        if (sortedCategories.length > 0) {
          //get the dominant category after the selected category
          let highest = sortedCategories[0];

          suggested_category_slug = highest;
          suggested_category_name = suggestedNameKey[suggested_category_slug];
          setSuggestedCategory({
            category: suggested_category_name,
            slug: suggested_category_slug,
            url: "/explore?category=" + suggested_category_slug,
          });

          // request_suggestion = suggested_category_slug;
          request_suggestion = sortedCategories.toString();
        } else {
          setSuggestedCategory({
            category: "random",
            slug: "random",
            url: "/search/",
          });
        }
      } else {
        setSuggestedCategory({
          category: "random",
          slug: "random",
          url: "/search",
        });
      }
    } else {
      setSuggestedCategory({
        category: "random",
        slug: "random",
        url: "/search",
      });
    }
    requestSuggestion(request_suggestion);
  }

  function requestSuggestion(slug) {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?categories=" +slug +"&limit=4" : "https://api.onepeople.online/op/v1/referendums/suggestions?categories=" +slug +"&limit=4";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let refs = res.data.referendums;
          setupSuggestions(refs, setSuggestionRefs);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        setupSuggestions([], setSuggestionRefs);
      })
      .finally(() => {
        setSuggestionRefsStatus("updated");
        hasMainContentScroll("search")
      });
  }

  function getFilter(filter) {
    return new Promise((resolve, reject) => {
      if(filter === 'categories') {
        //category cache
        let cacheCategory = categoryCacheHelper.get();
        if (categoryCacheHelper.get().length !== 0) {
          setCategoriesStatus("updated");
          let datas = cacheCategory.map((data) => {
            return { text: data['category'], value: data.slug };
          });
          setCategories(datas);
          return resolve(datas)
        }
      } else {
        //country cache
        let cacheCountry = countryCacheHelper.get();
        if (countryCacheHelper.get().length !== 0) {
          setCountriesStatus("updated");
          let datas = cacheCountry.map((data) => {
            return { text: data['country'], value: data.slug };
          });
          setCountries(datas);
          return resolve(datas)
        }
      }

      let processed_datas = [];
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3001/op/v1/options/${filter}`:`https://api.onepeople.online/op/v1/options/${filter}`;
      axiosConfig
        .get(url, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            let datas = res.data[filter];
            processed_datas = datas.map((data) => {
              let prop = filter === "countries" ? "country" : "category";
              return { text: data[prop], value: data.slug };
            });
            if (filter === "categories") {
              setCategories(processed_datas);
              categoryCacheHelper.set(datas);
            } else if (filter === "countries") {
              setCountries(processed_datas);
              countryCacheHelper.set(datas)
            }
          }
        })
        .catch((errs) => {
          auth.checkErrors(errs);
          reject('Error')
        })
        .finally(() => {
          if (filter === "categories") {
            setCategoriesStatus("updated");
          } else if (filter === "countries") {
            setCountriesStatus("updated");
          }
          resolve(processed_datas)
          hasMainContentScroll("search")
        });
    })
  }

  function onScroll() {
    const scrollTop = Math.ceil(resultRef.current.scrollTop);
    const scrollHeight = Math.ceil(resultRef.current.scrollHeight);
    const clientHeight = resultRef.current.clientHeight;

    const isBottom =
      scrollTop + clientHeight + (scrollTop + clientHeight) * 0.05 >=
      scrollHeight;
      if (isBottom) {
        if (searchStatus === "updated") {
          if(filterResult.length === 0) {
          loadMore(getSort());
        }
      }
    }
  }

  function onSubmitFilterSearch(e) {
    e.preventDefault();
    let search_val = searchRef.current.value;
    window.location.href = `/search${
      search_val.trim().length > 0 ? `?q=${search_val}` : "/"
    }`;
  }

  return (
    <>
      <MetadataSetup
        title="Explore Categories | OnePeople" 
        canonical="https://onepeople.online/explore/"
        description="Explore Category Page of OnePeople Online"
      />
      <div className="main search">
        <div className="center row pb-3">
          <motion.div
            className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 mt-1"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              style={{
                minHeight: "6.25rem",
                borderRadius: "1.875rem",
                width: "100%"
              }}
              className="search-container border-0 op-box ps-4 py-4 pe-2 overflow-hidden justify-content-center d-flex"
            >
              <Card.Body className="p-0">
                <div className="search-header pe-0 pe-md-4">
                  <div className="d-flex align-items-center">
                    <span
                      className="icon1 d-flex align-items-center justify-content-center me-2"
                      style={{ color: "white", fontSize: "1.5rem" }}
                    >
                      <>&#128269;</>
                    </span>
                    <h4 className="mb-0 fw-semibold gp-header-text">
                      Explore Category
                    </h4>
                  </div>
                  <div className="d-flex">
                    <div className="exp-category d-flex align-items-center pe-3">
                      {countriesStatus === "updated" && (
                        <div className="me-3">
                          <OPSelectCountry
                            changestate={onSelectExplore}
                            options={countries}
                            select={countrySelect}
                            placeholder="All Countries"
                          />
                        </div>
                      )}
                      {categoriesStatus === "updated" && (
                        <div>
                          <OPSelectCategory
                            changestate={onSelectExplore}
                            options={categories}
                            select={categorySelect}
                            placeholder="All Categories"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <form
                        className="search-form"
                        onSubmit={onSubmitFilterSearch}
                      >
                        <div>
                          {!showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={searchIcon}
                              alt="search icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={filterList}
                              alt="filter icon"
                              className="icon icon-xs"
                            ></motion.img>
                          )}

                          <input
                            name="keyword"
                            type="text"
                            className="search-input-xs"
                            placeholder="Filter by keywords or enter new search"
                            ref={searchRef}
                            onChange={onKeyFilter}
                          />
                          {showFocusFilterIcons && (
                            <motion.img
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                              src={searchIcon}
                              alt="search icon"
                              className="icon icon-right icon-xs"
                            ></motion.img>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="result-container mt-4 ps-0 ps-lg-3">
                  <div className="result-header row m-0 pe-result-header w-100">
                    <div className="col-2 dt-start-head">
                      <SortReferendumButton 
                        sortText={"Started"}
                        activeSort={activeSort}
                        sortColumn={'started'}
                        sort={sortByStarted}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"}
                      />
                    </div>
                    <div className="col-2 dt-end-head">
                      <SortReferendumButton 
                        sortText={"Closed"}
                        activeSort={activeSort}
                        sortColumn={'closed'}
                        sort={sortByClosed}
                        sortListBy={sortListBy}
                        searchStatus={searchStatus} 
                        searchStatusText={"searching"}
                      />
                    </div>
                    {/* <div className="col-2 dt-start-head">
                      <button onClick={() => {sortListBy('started')}} type="button" className={`sort-btn sort-${sortByStarted} bounce ${activeSort === 'started' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Started<img className="mx-2" src={activeSort === 'started' ? caretBlue : caretDarkGrey} /></button>
                    </div>
                    <div className="col-2 dt-end-head">
                      <button onClick={() => {sortListBy('closed')}} type="button" className={`sort-btn sort-${sortByClosed} bounce ${activeSort === 'closed' ? "sort-active" : ""} ${searchStatus === 'searching' ? 'sort-disabled' : ''}`}>Closed<img className="mx-2" src={activeSort === 'closed' ? caretBlue : caretDarkGrey} /></button>
                    </div> */}
                    <div className="col-5 px-table-item">Referendum</div>
                    <div className="col-2 result-text">Result</div>
                    <div className="col-1">Vote %</div>
                  </div>
                  <div
                    ref={resultRef}
                    onScroll={onScroll}
                    className="result-body pe-3 mz-scroll"
                  >
                    {(((showResultRef.length > 0 && !hasError) || (showResultRef.length > 0 && hasError)) && 
                      filterValue === null ) &&
                      showResultRef.map((ref, index) => {
                        return (
                          <div
                            className={`row m-0 mt-${index === 0 ? "3" : "1"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="text-black">
                                {dateDisplayStringByServer(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="text-black">
                                  {dateDisplayStringByServer(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </span>
                              ) : (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </span>
                              )}
                            </div>
                            <div className={`result-percentage col-1`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    {((filterResult.length > 0 && !hasError) || (filterResult.length > 0 && hasError)) 
                    && filterValue !== null
                    && filterResult.map((ref, index) => {
                        return (
                          <div
                            className={`row m-0 mt-${index === 0 ? "3" : "1"}`}
                            key={index}
                          >
                            <div className={`dt-start-text col-2`}>
                              <span className="text-black">
                                {dateDisplayStringByServer(ref.created_at, false)}
                              </span>
                            </div>
                            <div className={`dt-end-text col-2 text-black`}>
                              {!ref.result_checked ? (
                                <span className="text-black">
                                  In Progress
                                </span>
                              ) : (
                                <span className="text-black">
                                  {dateDisplayStringByServer(ref.end_at, false)}
                                </span>
                              )}
                            </div>
                            <div className={`col-5 search-ref-title`}>
                              <a
                                href={`/referendums/${ref.slug}/`}
                                className="d-block"
                              >
                                {ref.title}
                              </a>
                            </div>
                            <div className={`col-2 result-text`}>
                              {ref.result === "winner" ? (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.option}
                                </span>
                              ) : (
                                <span
                                  className={`text-${
                                    ref.result === "winner"
                                      ? "green"
                                      : !ref.result_checked
                                      ? "amber"
                                      : "black"
                                  }`}
                                >
                                  {ref.result.charAt(0).toUpperCase() +
                                    ref.result.slice(1)}
                                </span>
                              )}
                            </div>
                            <div className={`result-percentage col-1`}>
                              <span
                                className={`text-${
                                  ref.result === "winner"
                                    ? "green"
                                    : !ref.result_checked
                                    ? "amber"
                                    : "black"
                                }`}
                              >
                                {ref.percentage ?? "-"}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    {prevRefLength === maxRefs &&
                      loadRefStatus === "updating" && (
                        <div className="result-loader d-flex justify-content-center align-items-center flex-column my-3">
                          <OPSpinner />
                        </div>
                      )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && !hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        No Referendums Found
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length > 0 && filterResult.length === 0 && filterValue !== null) && (
                      <h5 className="text-center p-5 text-gray">
                        No Referendums Found
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length === 0 && hasError) && (
                      <h5 className="text-center p-5 text-gray">
                        An error has occured! Please refresh the page.
                      </h5>
                    )}
                    {(searchStatus === "updated" && showResultRef.length > 0 && hasError && filterResult.length === 0) && (
                      <button onClick={retryLoadMore} className="retry-btn text-center py-2 text-gray w-100 border-0 bounce rounded" type="button">
                      An error occured! Please click to try again.</button>
                    )}
                    {searchStatus === "searching" && (
                      <div className="d-flex justify-content-center align-items-center flex-column my-3">
                        <OPSpinner />
                        <span>Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </motion.div>
          <div className="suggestions-main-container col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-3 mt-4 mt-xxl-1">
            <div className="row">
              <motion.div
                className="suggestions-container col-12"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  // title={
                  //   suggestedCategory.category === "random"
                  //     ? "Interested in these?"
                  //     : "More in " + suggestedCategory.category
                  // }
                  title={`Interested in these?`}
                  unicode="&#128499;"
                  url={suggestedCategory.url}
                  updateStatus={suggestionRefsStatus}
                  referendums={suggestionRefs}
                  // errorMsg={`No ${suggestedCategory.category} Referendums Found`}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
              <motion.div
                className="col-12 mt-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Suggestions
                  url="/hot-follows/"
                  updateStatus={hotFollowsStatus}
                  minHeight="18.875rem"
                  title="Hot Follows"
                  unicode="&#128276;"
                  referendums={hotFollowRefs}
                  errorMsg={`No open referendums found`}
                />
              </motion.div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer customClass={"inside-footer"} />
    </>
  );
}

export default Explore;
