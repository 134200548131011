import {
  Card,
  Row,
  Col,
  Accordion
} from "react-bootstrap";

import ContactUsForm from "./ContactUsForm";
import ContactUsFormMobile from "./ContactUsFormMobile";
import { useState } from "react";
import auth from "../../helpers/auth";
import MetadataSetup from "../../components/MetadataSetup";

function ContactUsPage() {
  // eslint-disable-next-line
  const [onlineStatus, setOnlineStatus] = useState(auth.isOnline());

  function Faq() {
    return (
      <Accordion>
        <Accordion.Item eventKey="0" className="OPAccordionItem">
          <Accordion.Header>What is OnePeople?</Accordion.Header>
          <Accordion.Body>
            OnePeople is a safe space for all to anonymously access the wisdom of the crowds through referendums. Vote for what you believe, and see what the world believes.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="OPAccordionItem">
          <Accordion.Header>
            I made a mistake! How do I edit my referendum?
          </Accordion.Header>
          <Accordion.Body>
            You may amend your referendum before publishing, so do check if there are any mistakes before submission. You may not edit your referendum after publishing, but you may delete your referendum and republish another.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="OPAccordionItem">
          <Accordion.Header>
            How many times can I extend my referendum?
          </Accordion.Header>
          <Accordion.Body>
            You may extend your referendum as many times as you like, but only up to a maximum of 7 days from the initial publishing.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="OPAccordionItem">
          <Accordion.Header>
            How many referendums can I post? Is there a limit?
          </Accordion.Header>
          <Accordion.Body>
            There is no limit as to how many referendums you can post now, so feel free to consult the crowd as many times as you need!
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="OPAccordionItem">
          <Accordion.Header>
            Would it be ok if I share or post personal information?
          </Accordion.Header>
          <Accordion.Body>
            Please do not post or share personal information as you risk doxing yourself and others. Doxing yourself or others can lead to harassment and cyberbullying.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="OPAccordionItem">
          <Accordion.Header>
            What happens when a referendum gets reported?
          </Accordion.Header>
          <Accordion.Body>
            The reported referendum will then be reviewed. If there are many people reporting your referendum, it is likely that your referendum is in violation of our terms and conditions. Reporting referendums and spam is one of the best courses of action when it comes to helping keep OnePeople safe.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
  return (
    <>
      <MetadataSetup 
        title="Contact Us | OnePeople" 
        canonical="https://onepeople.online/contact-us/"
        description="Have some questions? Curious about something? Can't find what you're looking for? We're here for you."
      />
      <Row className={`justify-content-center ${onlineStatus ? '' : 'main reset-logout pt-4 mx-auto justify-content-center'} row`}>
        <Col md={12} lg={12} xl={11} xxl={9} xs={12} className="mx-auto">
          <Card className="op-box OPcard-rounded card-transparent pe-lg-1">
            <div className="contact-us-content mz-scroll my-3 pe-0 d-flex flex-fill flex-column">
              <Card.Header className="text-center clear-header py-3 fblack pt-5 ">
                <h1 className="FS25 fbold">📱 Contact Us</h1>
              </Card.Header>
              <Card.Body className="d-flex ps-4 ps-lg-5 pe-4 pt-4 pt-md-4 pt-lg-5 pb-3 d-flex justify-content-center flex-wrap">
                <Row className="d-flex flex-fill">
                  <Col md={12} lg={6} xl={6} className="cu-left">
                    <h5 className="fblack mb-3">Do you need help?</h5>
                    <p className="fblack FS14 pb-4">
                      Have some questions? Curious about something? Can't find
                      what you're looking for? Need help but don't know what to
                      do? Fret no more, we're here for you!
                    </p>
                    <ContactUsFormMobile />
                    <h5 className="fblack mb-3">
                      Here are our commonly asked questions:
                    </h5>
                    <Faq />
                  </Col>
                  <Col md={12} lg={6} xl={6} className="d-flex flex-column mt-5 mt-md-5 mt-lg-0">
                    <ContactUsForm />
                    <div className="cu-img-container mt-3 d-flex flex-fill flex-column align-items-center justify-content-end"></div>
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default ContactUsPage;
