import { Card } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { dateDisplayStringByServer } from "../../helpers/date";
import OPSpinner from "../others/OPSpinner";

import SortReferendumButton from "../others/SortReferendumButton.js";
import auth from "../../helpers/auth.js";
import historyCache from "../../helpers/historyCache";
import axiosConfig from "../../helpers/axiosConfig"
import filterList from "../../assets/images/others/filter-list.svg";
import { hasMainContentScroll, getMyReferendumBodyHeight } from "../../helpers/jsHelper";

function StatisticMyReferendum() {
  // const root_url = "https://onepeople.online";
  const filterRef = useRef();

  const maxRefs = 15;
  const resultScrollRef = useRef();
  const [dataUpdateStatus, setDataUpdateStatus] = useState(null);
  const [prevRefLength, setPrevRefLength] = useState(null);
  const [loadRefIteration, setLoadRefIteration] = useState(null);

  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState(null);
  // const [filterResult, setFilterResult] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [sortByStarted, setSortByStarted] = useState('desc');
  const [sortByClosed, setSortByClosed] = useState('desc');
  const [activeSort, setActiveSort] = useState('started');


  
  useEffect(() => {
    resetSort();
    loadMyReferendums({});
    hasMainContentScroll("my-history-content")
  }, 
  // eslint-disable-next-line
  []);
  let timeout = null;
  function onKeyFilter() {
    // resetSort();
    const search_keywords = filterRef.current.value;
    const sortBy = activeSort;
    const sort = activeSort === 'started' ? sortByStarted : sortByClosed;
    clearTimeout(timeout);
    timeout = setTimeout(function(){
      loadMyReferendums({search:search_keywords, sort, sortBy});
    },300)
  }

  
  function resetSort() {
    setSortByStarted("desc");
    setSortByClosed("desc");
    setActiveSort("started");
  }

  
  function getSort() {
    let sort, sortBy = activeSort;
    if(activeSort === 'started') {
      sort = sortByStarted;
    } else {
      sort = sortByClosed;
    }
    return {sort, sortBy}
  }

  function sortListBy (sortColumn) {
    if(dataStatus === 'updated') {
      
      let sortBy = sortColumn;
      let sort = sortColumn === 'started' ? sortByStarted : sortByClosed;
      const search_keywords = filterRef.current.value;
      setActiveSort(sortColumn);

      sort = sort === 'desc' ? 'asc' : 'desc';
      if(sortColumn === 'closed') {
        setSortByClosed(sort)
        setSortByStarted('desc')
      } else {
        setSortByStarted(sort)
        setSortByClosed('desc')
      }
      loadMyReferendums({sort, sortBy, search: search_keywords});
    }
  }

  function loadMyReferendums({
    iteration = null, 
    sort = 'desc', 
    sortBy = 'started',
    search = null
  }) {
    
    let historyDataCache = historyCache.get();
    setHasError(false)
    
    if(historyDataCache.length !== 0){
      setDataStatus("updated");
      setData(historyDataCache);
    }

    if (dataStatus === "updating" || dataUpdateStatus === "updating") return;
    let status = "updating";
    

    if (iteration === null) {
      setData([])
      setDataStatus(status);
    } else {
      setDataUpdateStatus(status);
    }

   
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/users`:`https://api.onepeople.online/op/v1/referendums/users`;
    if (iteration) {
      url += `?iteration=${iteration}&sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}${search != null ? `&q=${search}` : ''}`;
      
    } else {
      url += `?sort=${sort}&sortBy=${sortBy === 'started' ? 'created' : 'end'}${search != null && search.length > 0 ? `&q=${search}` : ''}`;
    }

    axiosConfig
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoadRefIteration(iteration);
          let refs = res.data.referendums;
          setPrevRefLength(refs.length);
          if (iteration === null) {
            historyCache.set(refs);
            setData(refs);
          } else {
            setData((old_data) => [...data, ...refs]);
          }
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        if(iteration === null) {
          setData([]);
        }
        setHasError(true)
      })
      .finally(() => {
        if (iteration === null) {
          setDataStatus("updated");
        } else {
          setDataUpdateStatus("updated");
        }

        getMyReferendumBodyHeight();
        hasMainContentScroll("my-history-content")
      });
  }

  function loadMore({
    sort = null, 
    sortBy = 'started'
}) {
    if(!hasError) {
      if (prevRefLength === maxRefs && (dataUpdateStatus === 'updated' || dataUpdateStatus === null)) {
        let iteration = loadRefIteration;
        if (loadRefIteration === null) {
          iteration = 1;
        } else {
          iteration = iteration + 1;
        }
        loadMyReferendums({iteration, sort, sortBy})
        // onKeyFilter()
      }
    }
  }

  function retryLoadMore() {
    setHasError(false);
    loadMore(getSort());
  }

  function onScroll() {
    const scrollTop = resultScrollRef.current.scrollTop;
    const scrollHeight = resultScrollRef.current.scrollHeight;
    const clientHeight = resultScrollRef.current.clientHeight;

    // console.log('-----------')
    // console.log(scrollTop)
    // console.log(scrollHeight)
    // console.log(clientHeight)
    // console.log((scrollTop + clientHeight) + ((scrollTop + clientHeight) * .05));
    const isBottom = ((scrollTop + clientHeight) + ((scrollTop + clientHeight) * .05)) >= scrollHeight;
    if (isBottom) {
      if(dataStatus === 'updated') {
        loadMore(getSort());
      }
    }
  }

  function onSubmit(event) {
    event.preventDefault();
  }

  return (
    <>
      <Card
        style={{
          minHeight: "6.25rem",
          borderRadius: "1.875rem",
        }}
        className="border-0 ps-4 py-4 pe-2 overflow-hidden justify-content-center d-flex op-box"
      >
        <Card.Body className="p-0">
          <div className="my-ref-header pe-0 pe-md-4">
            <div className="d-flex align-items-center">
              <span
                className="icon1 d-flex align-items-center justify-content-center me-2"
                style={{ color: "white", fontSize: "1.5rem" }}
              >
                &#128203;
              </span>
              <h4 className="mb-0 fw-semibold gp-header-text">
                My Referendums
              </h4>
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <form className="search-form" onSubmit={onSubmit}>
                  <div>
                    <img
                      src={filterList}
                      alt="search icon"
                      className="icon icon-xs"
                    />
                    <input
                      type="text"
                      className="search-input-xs history-search-input"
                      placeholder="Filter by keywords"
                      ref={filterRef}
                      onKeyUp={onKeyFilter}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="result-container my-ref mt-4 ps-0 ps-lg-3">
            <div className="result-header row w-100 m-0 pe-result-header">
              <div className="col-1 p-0">By</div>
              <div className="col-2 p-0">
                <SortReferendumButton 
                  sortText={"Started"}
                  activeSort={activeSort}
                  sortColumn={'started'}
                  sort={sortByStarted}
                  sortListBy={sortListBy}
                  searchStatus={dataStatus} 
                  searchStatusText={"updating"} 
                />

                {/* <button onClick={() => {sortListBy("started")}} type="button" className={`sort-btn sort-${sortByStarted} bounce ${activeSort === 'started' ? "sort-active" : ""} ${dataStatus === 'updating' ? 'sort-disabled' : ''}`}>Started<img className="mx-2" src={activeSort === 'started' ? caretBlue : caretDarkGrey} /></button> */}
              </div>
              <div className="col-2 p-0">
                <SortReferendumButton 
                  sortText={"Closed"}
                  activeSort={activeSort}
                  sortColumn={'closed'}
                  sort={sortByClosed}
                  sortListBy={sortListBy}
                  searchStatus={dataStatus} 
                  searchStatusText={"updating"} 
                />
                {/* <button onClick={() => {sortListBy("closed")}} type="button" className={`sort-btn sort-${sortByClosed} bounce ${activeSort === 'closed' ? "sort-active" : ""} ${dataStatus === 'updating' ? 'sort-disabled' : ''}`}>Closed<img className="mx-2" src={activeSort === 'closed' ? caretBlue : caretDarkGrey} /></button> */}
              </div>
              <div className="col-4 p-0 px-table-item">Referendum</div>
              <div className="col-1 py-0 ref-user-vote">My Vote</div>
              <div className="col-1 py-0 result-text">Result</div>
              <div className="col-1 p-0">Vote %</div>
            </div>
            <div
              ref={resultScrollRef}
              onScroll={onScroll}
              className="result-body pe-3 mz-scroll"
            >
              {((data.length > 0 && !hasError) || (data.length > 0 && hasError))
                &&
                data.map((ref, index) => {
                  return (
                    <div
                      className={`row m-0 mt-${index === 0 ? "3" : "1"} w-100`}
                      key={index}
                    >
                      <div className={`ref-author col-1`}>
                        <span className="text-black">
                          {ref.owner ? "Me" : "Others"}
                        </span>
                      </div>
                      <div className={`dt-start-text col-2`}>
                        <span className="d-block text-black">
                          {dateDisplayStringByServer(ref.created_at, false)}
                        </span>
                      </div>
                      <div className={`dt-end-text col-2`}>
                        {!ref.result_checked ? (
                          <span className="text-black">In Progress</span>
                        ) : (
                          <span className="text-black">
                            {dateDisplayStringByServer(ref.end_at, false)}
                          </span>
                        )}
                      </div>
                      <div className={`col-4 search-ref-title`}>
                        <a
                          href={`/referendums/${ref.slug}/`}
                          className="d-block p-table-item"
                        >
                          {ref.title}
                        </a>
                      </div>
                      <div className={`col-1 ref-user-vote`}>
                        <span
                          className={`text-${
                            ref.result === "winner" && ref.option === ref.vote
                              ? "green"
                              : ref.result === "winner" && ref.option !== ref.vote && ref.vote !== null
                              ? "gray"
                              : "black"
                          }`}
                        >
                          {ref.vote ?? "-"}
                        </span>
                      </div>
                      <div className={`result-text col-1`}>
                          {ref.result === "winner" ? (
                            <span
                              className={`text-${
                                ref.result === "winner"
                                  ? "green"
                                  : !ref.result_checked
                                  ? "amber"
                                  : "black"
                              }`}
                            >
                              {ref.option}
                            </span>
                          ) : (
                            <span
                              className={`text-${
                                ref.result === "winner"
                                  ? "green"
                                  : !ref.result_checked
                                  ? "amber"
                                  : "black"
                              }`}
                            >
                              {ref.result.charAt(0).toUpperCase() +
                                ref.result.slice(1)}
                            </span>
                          )}
                      </div>
                      <div className={`col-1`}>
                        <span
                          className={`text-${
                            ref.result === "winner"
                              ? "green"
                              : !ref.result_checked
                              ? "black"
                              : "black"
                          }`}
                        >
                          {ref.percentage ?? "-"}
                        </span>
                      </div>
                    </div>
                  );
                })}

              {/* {filterResult.length > 0 &&
                filterResult.map((ref, index) => {
                  return (
                    <div
                      className={`row mt-${index === 0 ? "2" : "4"}`}
                      key={index}
                    >
                      <div className={`ref-author col-1`}>
                        <span className="ps-1 text-black">
                          {ref.owner ? "Me" : "Others"}
                        </span>
                      </div>
                      <div className={`dt-start-text col-2`}>
                        <span className="ps-1 text-black">
                          {dateDisplayString(ref.created_at, false)}
                        </span>
                      </div>
                      <div className={`dt-end-text col-2 text-black`}>
                        {!ref.result_checked ? (
                          <span className="ps-1 text-black">In Progress</span>
                        ) : (
                          <span className="ps-1 text-black">
                            {dateDisplayString(ref.end_at, false)}
                          </span>
                        )}
                      </div>
                      <div className={`col-4 search-ref-title`}>
                        <a
                          href={`${root_url}/referendums/${ref.slug}/`}
                          className="d-block"
                        >
                          {ref.title}
                        </a>
                      </div>
                      <div className={`col-1 result-text`}>
                        <span
                          className={`text-${
                            ref.result === "winner" && ref.option === ref.vote
                              ? "green"
                              : !ref.result_checked
                              ? "amber"
                              : "black"
                          }`}
                        >
                          {ref.vote ?? "-"}
                        </span>
                      </div>
                      <div className={`ref-user-vote col-1`}>
                        <span
                          className={`text-${
                            ref.result === "winner"
                              ? "green"
                              : !ref.result_checked
                              ? "amber"
                              : "black"
                          }`}
                        >
                          {ref.result === "winner" ? (
                            <span
                              className={`text-${
                                ref.result === "winner" &&
                                ref.option === ref.vote
                                  ? "green"
                                  : !ref.result_checked
                                  ? "amber"
                                  : "black"
                              }`}
                            >
                              {ref.vote}
                            </span>
                          ) : (
                            <span
                              className={`text-${
                                ref.result === "winner"
                                  ? "green"
                                  : !ref.result_checked
                                  ? "amber"
                                  : "black"
                              }`}
                            >
                              {ref.result.charAt(0).toUpperCase() +
                                ref.result.slice(1)}
                            </span>
                          )}
                        </span>
                      </div>
                      <div className={`col-1`}>
                        <span
                          className={`text-${
                            ref.result === "winner"
                              ? "green"
                              : !ref.result_checked
                              ? "amber"
                              : "black"
                          }`}
                        >
                          {ref.percentage ?? "-"}
                        </span>
                      </div>
                    </div>
                  );
                })} 

              {((dataStatus === "updated" && data.length === 0) ||
                (data.length > 0 &&
                  filterResult.length === 0 &&
                  filterRef.current.value.length > 0)) && (
                <h5 className="text-center p-5 text-gray">
                  No Referendums Found
                </h5>
              )}*/}

              {(dataStatus === "updated" && data.length === 0 && !hasError) && (
                <h5 className="text-center p-5 text-gray">
                  No Referendums Found
                </h5>
              )}

              {(dataStatus === 'updated' && hasError && data.length === 0) && (
                <h5 className="text-center p-5 text-gray">
                  An error has occured! Please refresh the page.
                </h5>
              )}
              {(dataStatus === 'updated' && hasError && data.length > 0) && (
                <button onClick={retryLoadMore} className="retry-btn text-center py-2 text-gray w-100 border-0 bounce rounded" type="button">
                  An error occured! Please click to try again.</button>
              )}

              {dataStatus === "updating" && (
                <div className="d-flex justify-content-center align-items-center flex-column my-3">
                  <OPSpinner />
                  <span>Loading...</span>
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
export default StatisticMyReferendum;
