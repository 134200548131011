import { useState } from "react";
import Card from "react-bootstrap/Card";
import { motion } from "framer-motion";
import validator from "validator";
import auth from "../../helpers/auth";
import axiosConfig from "../../helpers/axiosConfig";
import MetadataSetup from "../../components/MetadataSetup";

import hidePw from "../../assets/images/profile/hide-password.svg"
import showPw from "../../assets/images/profile/show-password.svg"

function ResetPassword() {
  const [changed, setChanged] = useState(false);
  const [passwordToggled, setPasswordToggled] = useState(false);
  const [repeatPasswordToggled, setRepeatPasswordToggled] = useState(false);
  const [changing, setChanging] = useState(false);

  const initErrors = {
    pw: null,
    pwc: null,
    others: null,
  };

  const [errors, setErrors] = useState(initErrors);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  
  const initPasswordErrors = {
    chars: {
      text: 'at least 8 characters long',
      valid: true
    },
    lowerUpperNumberSymbol: {
      text: 'a mix of lower case, upper case, numbers, and special characters',
      valid: true
    }
  }
  // eslint-disable-next-line
  const [passwordErrors, setPasswordErrors] = useState(initPasswordErrors);

  
  // function validatePassword (userdata) {
  //   let newPassErrors = JSON.parse(JSON.stringify(initPasswordErrors));
  //   if(!validator.isStrongPassword(userdata.password, {minLowercase: 1,minUppercase: 1, minNumbers: 1,minSymbols: 1})) {
  //     newPassErrors.lowerUpperNumberSymbol.valid = false;
  //   }
    
  //   if(!validator.isStrongPassword(userdata.password, {minLength: 8})) {
  //     newPassErrors.chars.valid = false;
  //   }

  //   setPasswordErrors(newPassErrors);
  // }

  function handlePassword(e) {
    let val = e.target.value;
    setPassword(val);
    setErrors({ ...errors, pw: null });
  }

  function handleConfirmation(e) {
    let val = e.target.value;
    setPasswordConfirmation(val);
    setErrors({ ...errors, pwc: null });
  }

  function onSubmit(e) {
    e.preventDefault();

    let errorTrachers = { ...errors };
    let hasErrors = false;

    if (validator.isEmpty(password)) {
      errorTrachers.pw = "Password is required";
      hasErrors = true;
    } else if (
      !validator.isStrongPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        minLength: 8,
      })
    ) {
      errorTrachers = {
        ...errorTrachers,
        pw: "Password is not strong enough",
      };
      hasErrors = true;
    }

    if (validator.isEmpty(password)) {
      errorTrachers.pwc = "Password confirmation is required";
      hasErrors = true;
    } else if(!validator.equals(password, passwordConfirmation)) {
      errorTrachers.pwc = "Passwords do not match";
      hasErrors = true;
    }

    setErrors(errorTrachers);
    if (hasErrors) return;

    if (changing) return;
    setChanging(true);

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    let userdata = {
      password: password,
      password_confirmation: passwordConfirmation,
    };
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?  "http://127.0.0.1:3003/op/v1/reset-password":"https://api.onepeople.online/op/v1/reset-password";
    axiosConfig
      .post(url, userdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setChanged(true);
        }
      })
      .catch((err) => {
        let errors = err.response.data.errors;
        if (errors) {
          errors.map((obj, index) => {
            let params = obj.path;
            if (params) {
              if (params === "password") {
                errorTrachers.pw = obj.msg;
              } else if (params === "password_confirmation") {
                errorTrachers.pwc = obj.msg;
              } else {
                //password
                errorTrachers.others = obj.msg;
              }
            } else {
              errorTrachers.others = obj.msg;
            }
            return obj;
          });
          setErrors(errorTrachers);
        }
      })
      .finally(() => {
        setChanging(false);
      });
  }

  return (
    <>
      <MetadataSetup 
        title="Reset Password | OnePeople" 
        canonical="https://onepeople.online/reset-password/"
        description="Reset Password Page of OnePeople Online"
      />
      <div
        className={"main d-grid ref-view " + (!auth.isOnline() && 'reset-logout')}
        style={{
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <div className={"center row"}>
          <div className="col-12 col-md-8 col-lg-8 col-xl-7 col-xxl-5 pb-3">
            <Card
              style={{
                minHeight: "12.1875rem",
                maxWidth: "52.8125rem",
                borderRadius: "1.875rem",
                paddingTop: "1.25rem",
                paddingBottom: "2.5rem",
                height: "auto",
                marginTop: "6.25rem",
                margin: (!auth.isOnline() && 'reset-logout' ? "6.25rem auto 0 auto" : "6.25rem 0 0 0")
              }}
              className="border-0 op-box px-5 overflow-hidden justify-content-center d-flex flex-column"
            >
              <h1 className="text-center fblack rp-title mt-3">
                Reset your password
              </h1>
              <div
                className={`login-register row pt-4 px-4 align-items-center justify-content-center`}
              >
                <div
                  className={`login-container px-lg-5 ${
                    !changed ? "px-0 col-12 col-xxl-10" : "col-12 text-center"
                  }`}
                >
                  {changed && (
                    <p className="auth-text mt-4">
                      Your password has been reset successfully. You may log in.
                    </p>
                  )}
                  {!changed && (
                    <form
                      onSubmit={onSubmit}
                      className="position-relative mt-4"
                    >
                      <h4
                        className="position-absolute d-inline-block px-1 text-black modal-sub-header"
                        style={{ top: "-2.1875rem", left: "-0.5rem" }}
                      >
                        <span className="me-2">&#128260;</span>
                        Enter new password
                      </h4>
                      <div className="mt-5">
                        <p className="auth-text">
                          Your new password must contain at least <br></br> 8
                          characters
                        </p>
                      </div>
                      <div className="pt-3">
                        <div class="m-0 p-0 d-flex justify-content-between">
                          <p className="m-0 p-0 input-text text-gray">
                            New Password
                          </p>
                          {errors.pw !== null && (
                            <p className="m-0 p-0 input-text text-red text-end">
                              {errors.pw}
                            </p>
                          )}
                        </div>
                        <div className="d-flex position-relative input-with-toggle">
                          <input
                            value={password}
                            onChange={handlePassword}
                            type={passwordToggled ? "text" : "password"}
                            className="form-control hl-border w-100"
                            id="password"
                            name="password"
                          ></input>
                          <button
                            type="button"
                            className="border-0 position-absolute"
                            onClick={() => setPasswordToggled(!passwordToggled)}
                          >
                            {passwordToggled ? (
                              <motion.img
                                whileHover={{ scale: 1.1 }}
                                src={hidePw}
                                style={{ width: "1.25rem", height: "1.25rem" }}
                              />
                            ) : (
                              <motion.img
                                whileHover={{ scale: 1.1 }}
                                src={showPw}
                                style={{ width: "1.25rem", height: "1.25rem" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="pt-3">
                        <div className="m-0 p-0 d-flex justify-content-between">
                          <p className="m-0 p-0 input-text text-gray">
                            Re-enter New Password
                          </p>
                          {errors.pwc !== null && (
                            <p className="m-0 p-0 input-text text-red text-end">
                              {errors.pwc}
                            </p>
                          )}
                        </div>
                        <div className="d-flex position-relative input-with-toggle">
                          <input
                            value={passwordConfirmation}
                            onChange={handleConfirmation}
                            type={repeatPasswordToggled ? "text" : "password"}
                            className="form-control hl-border w-100"
                            id="password2"
                            name="password2"
                          ></input>
                          <button
                            type="button"
                            className="border-0 position-absolute"
                            onClick={() =>
                              setRepeatPasswordToggled(!repeatPasswordToggled)
                            }
                          >
                            {repeatPasswordToggled ? (
                              <motion.img
                                whileHover={{ scale: 0.9 }}
                                src={hidePw}
                                style={{
                                  width: "1.25rem",
                                  height: "1.25rem",
                                  scale: 1,
                                }}
                              />
                            ) : (
                              <motion.img
                                whileHover={{ scale: 0.9 }}
                                src={showPw}
                                style={{
                                  width: "1.25rem",
                                  height: "1.25rem",
                                  scale: 1,
                                }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="m-0 p-0">
                        {errors.others !== null && (
                          <p className="m-0 p-0 input-text text-red">
                            {errors.others}
                          </p>
                        )}
                      </div>
                      {errors.pw === 'Password is not strong enough' && (
                        <motion.div className="mt-2">
                          <p className="m-0 p-0 input-text text-start text-gray">
                            Your password needs to be:
                          </p>
                          <ul className="reg-requirements input-text text-gray p-0 m-0 ps-3">
                            <li className="p-0 m-0">{passwordErrors.chars.text}</li>
                            <li className="p-0 m-0">{passwordErrors.lowerUpperNumberSymbol.text}</li>
                          </ul>
                        </motion.div>
                      )}
                      <div className="mt-5 text-center d-flex justify-content-center">
                        <motion.input
                          initial={{}}
                          animate={
                            changing
                              ? {
                                  backgroundColor: "#ccc",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                          type="submit"
                          name="submit"
                          value="Confirm"
                          className="op-btn confirm-btn bounce"
                        />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
