import { motion } from "framer-motion";
import { useState } from "react";

//Components
import Register from "./register";
import ModalRegisterQR from "./ModalRegisterQR";
import ModalRegister2FA from "./ModalRegister2FA";
import ModalRegisterWelcome from "./ModalRegisterWelcome";

import { ToastContainer } from "react-toastify";

import ModalResetPassword from "./ModalResetPassword";

import Login from "./login";
import ModalLogin2FA from "./ModalLogin2FA";
import ModalLogin2FAEmail from "./ModalLogin2FAEmail";

import ModalHeaderLogin from "../others/ModalHeaderLogin";
import ModalResetPasswordMsg from "./ModalResetPasswordMsg";

function ModalLoginRegister(props) {
  const { setShowMainModal, setIsOnline, isAlpha } = props;

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [manualCode, setManualCode] = useState(null);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  const [step, setStep] = useState(null);

  //for email sending error tracking
  const [email2FAError, setEmail2FAError] = useState(null);

  const toggleOffInContainer = () => {
    setShowMainModal(false);
  };
  const preventD = (e) => {
    e.preventDefault();
  };

  // const StepFunction = ({ step }) => {
  //   //odd steps are for register and even are for login
  //   switch (step) {
  //     case "register_qr":
  //       return (
  //         <ModalRegisterQR
  //           setStep={setStep}
  //           qrCodeUrl={qrCodeUrl}
  //           manualCode={manualCode}
  //           setIsOnline={setIsOnline}
  //           setShowMainModal={setShowMainModal}
  //         />
  //       );
  //     case "register_verify":
  //       return (
  //         <ModalRegister2FA
  //           email={email}
  //           password={password}
  //           setStep={setStep}
  //           setIsOnline={setIsOnline}
  //           setShowMainModal={setShowMainModal}
  //         />
  //       );
  //     case "register_welcome":
  //       return <ModalRegisterWelcome setShowMainModal={setShowMainModal} />;
  //     case "reset_password":
  //       return (
  //         <ModalResetPassword
  //           setStep={setStep}
  //           setShowMainModal={setShowMainModal}
  //         />
  //       );

  //     case "reset_password_confirmed":
  //       return <ModalResetPasswordMsg setShowMainModal={setShowMainModal} />;

  //     case "login_verify":
  //       return (
  //         <ModalLogin2FA
  //           email={email}
  //           password={password}
  //           setStep={setStep}
  //           setIsOnline={setIsOnline}
  //           setShowMainModal={setShowMainModal}
  //         />
  //       );
  //     case "login_verify_email":
  //       return (
  //         <ModalLogin2FAEmail
  //           email={email}
  //           password={password}
  //           setStep={setStep}
  //           setIsOnline={setIsOnline}
  //           setShowMainModal={setShowMainModal}
  //         />
  //       );

  //     default:
  //       return (
  //           <motion.div
  //             initial={{ scale: 0.5, translateY: "-50%" }}
  //             animate={isOpen ? OpenningAnimation : ClosingAnimation}
  //             transition={{ type: "spring", duration: 0.3 }}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //             }}
  //             className="login_register_container op-box p-3 py-5 OPModalBox position-absolute mx-auto"
  //           >
  //             <ModalHeaderLogin
  //               title="Hey there...General."
  //               toggleOffInContainer={toggleOffInContainer}
  //               isAlpha={isAlpha}
  //             />
  //             <div className={`row px-3  login-register`}>
  //               <div
  //                 className={`login-container col-12 col-md-12 col-lg-6 px-5 pb-5 pb-md-5 pb-lg-0 border-bottom-divider mt-1`}
  //               >
  //                 <Login
  //                   setEmail={setEmail}
  //                   setPassword={setPassword}
  //                   setQrCodeUrl={setQrCodeUrl}
  //                   setManualCode={setManualCode}
  //                   setStep={setStep}
  //                 />
  //               </div>
  //               {/* {!isAlpha && ( */}
  //                 <div className="register-container mt-5 mt-md-5 mt-lg-0 col-12 col-md-12 col-lg-6 px-5 border-left-divider">
  //                   <Register
  //                     setEmail={setEmail}
  //                     setPassword={setPassword}
  //                     setQrCodeUrl={setQrCodeUrl}
  //                     setManualCode={setManualCode}
  //                     setStep={setStep}
  //                   />
  //                 </div>
  //               {/* )} */}
  //             </div>
  //           </motion.div>
  //       );
  //   }
  // };

  return (
    <>
      <div
        className="OPModal position-absolute"
        onClick={isAlpha ? preventD : toggleOffInContainer}
      >
        {/* <StepFunction step={step} /> */}
        {step === null && (
          <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="login_register_container op-box p-3 py-5 OPModalBox position-absolute mx-auto"
            >
              <ModalHeaderLogin
                title="Hello there!"
                toggleOffInContainer={toggleOffInContainer}
                isAlpha={isAlpha}
              />
              <div className={`row px-3  login-register mz-scroll`}>
                <div
                  className={`login-container col-12 col-md-12 col-lg-6 px-5 pb-5 pb-md-5 pb-lg-0 border-bottom-divider mt-1`}
                >
                  <Login
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setQrCodeUrl={setQrCodeUrl}
                    setManualCode={setManualCode}
                    setStep={setStep}
                  />
                </div>
                {/* {!isAlpha && ( */}
                  <div className="register-container mt-5 mt-md-5 mt-lg-0 col-12 col-md-12 col-lg-6 px-5 border-left-divider">
                    <Register
                      setEmail={setEmail}
                      setPassword={setPassword}
                      setQrCodeUrl={setQrCodeUrl}
                      setManualCode={setManualCode}
                      setStep={setStep}
                      isAlpha={isAlpha}
                    />
                  </div>
                {/* )} */}
              </div>
            </motion.div>
        )}

        {step === "login_verify" && (
          <ModalLogin2FA
            email={email}
            password={password}
            setStep={setStep}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
            setEmail2FAError={setEmail2FAError}
          />
        )}

        {(step === 'login_verify_email' || step === 'register_verify_email') && (
          <ModalLogin2FAEmail
            email={email}
            step={step}
            password={password}
            setStep={setStep}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
            email2FAError={email2FAError}
            setEmail2FAError={setEmail2FAError}
          />
        )}

        {step === 'register_qr' && (
          <ModalRegisterQR
            setStep={setStep}
            qrCodeUrl={qrCodeUrl}
            manualCode={manualCode}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
          />
        )}

        {step === 'register_verify' && (
          <ModalRegister2FA
            email={email}
            password={password}
            setStep={setStep}
            setIsOnline={setIsOnline}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
          />
        )}

        { step === 'register_welcome' && (
          <ModalRegisterWelcome 
            setShowMainModal={setShowMainModal} 
          />
        )}

        {step === 'reset_password' && (
          <ModalResetPassword
            setStep={setStep}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
          />
        )}

        {step === 'reset_password_confirmed' && (
          <ModalResetPasswordMsg
            setStep={setStep}
            setShowMainModal={setShowMainModal}
            isAlpha={isAlpha}
          />
        )}
      </div>
      <ToastContainer className={"op-notification op-notification-danger px-3 px-md-0"}/>
    </>
  );
}
export default ModalLoginRegister;
