import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './helpers/messageCounter'
import exploreReducer from './helpers/exploreSelection'
import imageReducer from "./helpers/imageState";
import serverTimeReducer from "./helpers/serverTime";

export default configureStore({
  reducer: {
    messagecounter:counterReducer,
    catandcountry:exploreReducer,
    imageReducer:imageReducer,
    serverTime:serverTimeReducer
  },
})