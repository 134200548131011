import { ToastContainer } from "react-toastify";

//Components
import ExitBtn from "./others/ExitBtn";

function Notification(props) {

   function CloseButton ({closeButton}) {
      return (
         <ExitBtn onClick={closeButton} />
      )
   }
  return <ToastContainer autoClose={false} closeButton={CloseButton} />;
}

export default Notification;
