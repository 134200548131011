import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { motion } from "framer-motion";
import { setSelectedCategory } from "../../helpers/exploreSelection";
import { useDispatch, useSelector } from "react-redux";

import categoryCacheHelper from "../../helpers/categoryCacheHelper";

function OPSelectCategory({options,select,placeholder,changestate}){
   const [selected,setSelected] = useState(null);
   const [inputState,setInputState] = useState(false);
   const dropOn = {
      height:"auto",
      duration:0.1
   }
   const categorystate = useSelector(state => {
      // console.log(state)
      return state.catandcountry.category
   })
   const dropOff = {
      height:"0",
      // overflow:"hidden"
   }
   const dispatch = useDispatch();
   
   // const [searchCategory, setSearchCategory] = useState(null);
   const pathname = window.location.pathname;
   let list = [];
   const selectThis = (data)=>{
    
         setSelected(data.text);
         setInputState(!inputState) 
         dispatch(setSelectedCategory(data.value))
         // console.log(data.value)
         // setSearchCategory(data.value)
         select.current.value = data.value
         changestate()
   }
   useEffect(()=>{

      let cgs = getCacheCategories();
      let paths_arr = window.location.pathname
        .split("/")
        .filter((str) => str.length > 0);
      let search_category = null;

      // let hasCategorySet = false;
      if (paths_arr.length > 1) {
        paths_arr.map((path) => {
          if (path !== "explore") {
            let category_obj = cgs.filter((ct) => ct.slug === path);
            if (category_obj.length > 0) {
              search_category = path;
            //   hasCategorySet = true;
            }
          }

          return path;
        });
      }

      if(options[0].value !== null) {
         options.unshift({value:null,text:placeholder})
      }
      // console.log(search_category)
      // console.log(categorystate);
      options.forEach((a,i)=>{
         if(search_category === a.value){
            // console.log(a)
            setSelected(a.text);
            dispatch(setSelectedCategory(a.value))
            select.current.value = a.value
         }
      })
      
   },
   // eslint-disable-next-line
   [pathname])

   
  function getCacheCategories() {
      let cacheCategory = categoryCacheHelper.get();
      if (categoryCacheHelper.get().length !== 0) {
      return cacheCategory;
      }
      return [pathname];
   }
 
   if(options[0].value !== null) {
      list.push(<li id={`cat-id-${''}`} key={''} className={placeholder === selected ? "active":""} onClick={(e)=>{e.stopPropagation(); selectThis({value: "", text: placeholder})}}>{placeholder}</li>)
   }
   options.forEach(function(a,i){
      list.push(<li id={`cat-id-${a.value}`} key={a.value} className={a.text === selected ? "active":""} onClick={(e)=>{e.stopPropagation(); selectThis(a)}}>{a.text}</li>)
   })
   return(<>
      <input type="hidden" ref={select} ></input>
      {/* <button onClick={()=>{console.log(select.current.value)}}>test</button> */}
      <div className="OPselectBox position-relative" onClick={()=>{setInputState(!inputState); categorystate && document.getElementById(`cat-id-`+categorystate).scrollIntoView({ behavior: 'smooth', block: 'nearest'})}}>
            <div className="selected-box text-nowrap">
               {selected == null? placeholder :selected}
               <span className="carret">
               <FontAwesomeIcon icon={solid("caret-down")}   />
               </span>
               
            </div>
            <motion.div onClick={(e)=>e.stopPropagation()} initial={{height:"0"}}  animate={inputState ? dropOn:dropOff} className="drop position-absolute mz-scroll">
               <ul className="p-0 m-0">
                  {list}
               </ul>
            </motion.div>
      </div>
   </>);
}

export default OPSelectCategory;