import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import auth from "../../helpers/auth";
import validator from "validator";
import { useNavigate, useSearchParams } from "react-router-dom";

import ModalHeader from "../others/ModalHeader";
import csrfHelper from "../../helpers/csrfHelper";
import axiosConfig from "../../helpers/axiosConfig";
import CountDown from "../others/CountDown";
import NotificationHelper from "../../helpers/notificationsHelper";
import serverTimeHelper from "../../helpers/serverTimeHelper";
import { useDispatch } from "react-redux";
import { setOrigServerTime } from "../../helpers/serverTime";
function ModalLogin2FA(props) {
  const { email, password, setStep, setIsOnline, setShowMainModal, isAlpha, setEmail2FAError } = props;
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();

  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  const [verifying, setVerifying] = useState(false);
  // eslint-disable-next-line
  const [csrf,setCSRF] = useState();
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [isCounting, setIsCounting] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  function handleChange(e) {
    let val = e.target.value;
    if (!validator.isNumeric(val) && val.length > 0) return;
    if (!validator.isLength(val, { min: 0, max: 6 })) {
      return;
    }
    setCode(val);
    setError(null);
  }

  function send2FA() {
    if(isSendingEmail || isCounting) return;
    setError(null);
    setIsSendingEmail(true);

    if(isEmailDisabled) {
      NotificationHelper.disabledEmailNotif(
        "We are unable to send you an email because there may be a problem with your email service provider.", 
        "Please verify your 2FA with the code provided in the authenticator app to sign in."
      );
      setError("Unable to send 2FA code");
      setIsSendingEmail(false);
      setIsCounting(true);
      return;
    }

    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3003/op/v1/get-otp?email=${email}`:`https://api.onepeople.online/op/v1/get-otp?email=${email}`;

    axiosConfig
      .get(url)
      .then((res) => {
        setStep("login_verify_email");
      })
      .catch((err) => {
        // console.log(err);
      
        let datas = err.response.data;
        let errs = datas.errors;
        let errorTracker = null;

        //move to 2fa via email modal if user is not disabled regardless of error
        //and display the error received to 2fa via email modal

        if(errs) {
          if(datas.disabled !== undefined && datas.disabled) {
            NotificationHelper.disabledEmailNotif(
              "We are unable to send you an email because there may be a problem with your email service provider.", 
              "Please verify your 2FA with the code provided in the authenticator app to sign in."
            );
            setIsEmailDisabled(true);
            errorTracker = "Unable to send 2FA code";
            setError(errorTracker);

          } else {
            let msg = errs[0].msg;
            if(msg === 'connect ETIMEDOUT') {
              msg = "Something went wrong. Please try again."
            }
            setEmail2FAError(msg);
            setStep("login_verify_email");
          }
        } else {
          setEmail2FAError("Something went wrong. Please try again.");
          setStep("login_verify_email");
        }

        // if (errs) {
        //   if(errs[0].msg === 'Unable to send 2FA code') {
        //     if(datas.disabled !== undefined && datas.disabled) {
        //       NotifcationHelper.disabledEmailNotif();
        //       setIsEmailDisabled(true);
        //       errorTracker = "Unable to send 2FA code";
        //       setError(errorTracker);
        //     } else {
        //       setEmail2FAError("Unable to send 2FA code");
        //       setStep("login_verify_email");
        //     }
        //   } else {
        //     let email2FAerror = "Unable to send 2FA code";
        //     if (errs[0].msg === "connect ETIMEDOUT") {
        //       email2FAerror = "Something went wrong. Please try again.";
        //     } else {
        //       email2FAerror = errs[0].msg;
        //     }
        //     setEmail2FAError(email2FAerror);
        //     setStep("login_verify_email");
        //   } 
        // } else {
        //   setEmail2FAError("Something went wrong. Please try again.");
        //   setStep("login_verify_email");
        // } 
      })
      .finally(() => {
        setIsSendingEmail(false);
        setIsCounting(true);
      })
  }

  function verifyEmail(e) {
    setStep('login_verify_email');
    // send2FA();
  }

  const toggleOffInContainer = () => {
    setEmail2FAError(null);
    if(isAlpha) {
      setStep(null)
    } else {
      setShowMainModal(false);
    }
  };

  function onSubmit(e) {
    e.preventDefault();

    let hasError = false;
    let errorTracker = error;

    if (
      validator.isEmpty(code) ||
      !validator.isLength(code, { min: 6, max: 6 })
    ) {
      errorTracker = "The provided code is incorrect";
      hasError = true;
    }

    setError(errorTracker);
    if (hasError) return;

    if (verifying) return;
    setVerifying(true);
    const userdata = {
      query: "login",
      email,
      password,
      otp: code,
    };
    let url = process.env.REACT_APP_ENVIRONMENT ==="development" ? "http://127.0.0.1:3003/op/v1/verify":"https://api.onepeople.online/op/v1/verify";
    axiosConfig
      .post(url, userdata)
      .then((res) => {
        if (res.data.success) {
          auth.setToken(res.data.token);
          auth.setFullName(res.data.user.fullname);
          auth.setId(res.data.user.id);
          serverTimeHelper.set(res.data.time)
        
          dispatch(setOrigServerTime(res.data.time));
          setIsOnline(auth.isOnline());
          toggleOffInContainer();
          let redirect_path = queryParameters.get("redirect");
          if(redirect_path) {
            window.location.href = redirect_path;
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
      
        let errs = err.response.data.errors;
        if (errs) {
          if (errs[0].msg === "connect ETIMEDOUT") {
            errorTracker = "Something went wrong. Please try again.";
          } else {
            errorTracker = "The provided code is incorrect";
          }
        } else {
          errorTracker = "Something went wrong. Please try again.";
        }
        setError(errorTracker);
      })
      .finally(() => {
        setVerifying(false);
      });
  }
useEffect(()=>{
  const loadcsrf = async()=>{
  
    await csrfHelper.get().then(function(e){
    
       setCSRF(e)
     });
   }
 
   loadcsrf();
},[]);


  return (
    <>
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="auth-container p-3 py-5 OPModalBox position-absolute mx-auto op-box"
      >
        <ModalHeader
          title="Enter your 2FA code below"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3  login-register align-items-center justify-content-center`}
        >
          <div className={`login-container col-12 col-md-6 col-lg-6 px-5`}>
            <form
              onSubmit={onSubmit}
              className="position-relative mt-5"
              autoComplete="off"
            >
              <h4
                className="position-absolute d-inline-block px-1 text-black modal-sub-header"
                style={{ top: "-1.875rem", left: "0" }}
              >
                <span className="me-2">&#128100;</span>
                Sign In
              </h4>
              <div className="pt-3">
                <div className="m-0 p-0 d-flex justify-content-between">
                  <p className="m-0 p-0 input-text input-error text-gray">
                    2FA code
                  </p>
                  {error && (
                    <p className="m-0 p-0 input-text text-red">{error}</p>
                  )}
                </div>
                <input
                  autoFocus
                  autoComplete="off"
                  value={code}
                  onChange={handleChange}
                  type="text"
                  name="code"
                  className="form-control hl-border"
                />
              </div>
              <div className="text-end mt-2">
                <button
                  type="button"
                  onClick={verifyEmail}
                  className={`op-link-btn input-text bounce ${ isSendingEmail || isCounting ? "disabled" : ""}`}
                >
                  {`Verify code via Email `}
                </button>
              </div>
              <div className="mt-5 text-center">
                <motion.input
                  initial={{}}
                  animate={
                    verifying
                      ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                      : {}
                  }
                  type="submit"
                  name="submit"
                  value="Confirm"
                  className="op-btn confirm-btn bounce"
                />
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </motion.div>
    </>
  );
}
export default ModalLogin2FA;
