function SearchGroup(props) {
  const { title, active, unicode, onSearchGrouping } = props;

  return (
    <li
      onClick={() => {
        onSearchGrouping(title);
      }}
      className={`${active ? "active" : ""} ref-group-opt`}
    >
      {unicode}
    </li>
  );
}

export default SearchGroup;
