import { toast } from "react-toastify";

const noAutoCloseSetting = { autoClose: false ,position:"bottom-right",closeButton:"x",closeOnClick: true,pauseOnFocusLoss:true };
var NotificationHelper = {
    disabledEmailNotif: (msg_1 = "We are unable to send you an email because there may be a problem with your email service provider.", msg_2 = null) => {
        return toast(
        <>
            <div className={`p-0 m-0 ${msg_2 ? 'mb-2': ''}`}>{msg_1}</div> 
            { msg_2 !== null && (
                <div className="p-0 m-0">{msg_2}</div>
            )}
        </>, 
        noAutoCloseSetting);
    }
}

export default NotificationHelper;