import exitBtn from "../../assets/images/others/cross.svg"

function ExitBtn(props) {
  const { onClick } = props;
  return (
    <button onClick={onClick} className="bounce notif-exit-btn">
      <img src={exitBtn} className="bounce" alt="Exit Btn" />
    </button>
  );
}

export default ExitBtn;
