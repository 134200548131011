import { motion } from "framer-motion";
import { useState } from "react";

//Components
import ModalHeader from "../../others/ModalHeader";
import ExtendSelect from "./ExtendSelect";

function ModalExtendVoting(props) {
  const { referendum, setReferendum, setShowExtendAction } = props;
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  const [isOpen, setIsOpen] = useState(true);
  const [step, setStep] = useState("extending");

  const toggleOffInContainer = () => {
    setIsOpen(false);
    setTimeout(function () {
      setShowExtendAction(false);
    }, 100);
  };

  const StepFunction = ({ step }) => {
    switch (step) {
      case "extended":
        return (
          <motion.div>
            <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="op-box p-3 py-5 update-voting OPModalBox position-absolute mx-auto"
              style={{ minHeight: "auto" }}
            >
              <ModalHeader
                title="Update Closing Date/Time"
                toggleOffInContainer={toggleOffInContainer}
              />
              <div
                className={`row pt-3 px-3 align-items-center justify-content-center`}
              >
                <div className={`col-12 px-5 text-center`}>
                  <p className="op-text mt-3 text-black">
                    Closing Date/Time has been updated.
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
      default:
        return (
          <motion.div>
            <motion.div
              initial={{ scale: 0.5, translateY: "-50%" }}
              animate={isOpen ? OpenningAnimation : ClosingAnimation}
              transition={{ type: "spring", duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="op-box p-3 py-5 update-voting OPModalBox position-absolute mx-auto"
            >
              <ModalHeader
                title="Update Closing Date/Time"
                toggleOffInContainer={toggleOffInContainer}
              />
              <div
                className={`row pt-3 px-3 align-items-center justify-content-center`}
              >
                <ExtendSelect
                  referendum={referendum}
                  setReferendum={setReferendum}
                  setStep={setStep}
                />
              </div>
            </motion.div>
          </motion.div>
        );
        // eslint-disable-next-line
        break;
    }
  };

  return (
    <>
      <div className="OPModal position-absolute" onClick={toggleOffInContainer}>
        <StepFunction step={step} />
      </div>
    </>
  );
}
export default ModalExtendVoting;
