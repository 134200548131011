import "./printCSS.css";
import Checkbox from "./checkButton";
import Radio from "./radioButton";
import screenshotIcon from "../../../assets/images/referendum/screenshot.svg";
import { useEffect, useRef, useState } from "react";
import domtoimage from "dom-to-image-more";
// eslint-disable-next-line
import { saveAs } from "file-saver";
import { useOnClickOutside } from "usehooks-ts";
// eslint-disable-next-line
import { motion, animate } from "framer-motion";

function PrintSetting({ referendum, handleFooter }) {
  const [showSetting, setShowSetting] = useState(false);

  const [showAll, setShowAll] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [activeChoice, setActiveChoice] = useState(undefined);
  const [followed, setFollowed] = useState(false);
  
  const ref = useRef(null)

  useEffect(() => {
    let optArr = referendum.options.filter((opt) => opt.active || opt.reason);
    if (optArr.length > 0) {
      setActiveChoice(optArr[0].option);
    }
    setFollowed(() => {
      return referendum.followed;
    });
  }, [referendum]);

  const handleRadio = () => {
    setShowAll(!showAll);
  };
  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handlePrintSetting = (activate = true) => {
    let edRoot = document.querySelector("#ed-root");
    let mainContent = document.getElementsByClassName("main-content")[0];
    let refMain = document.getElementsByClassName("ref-main")[0];
    let refMainContainer = document.querySelector(".ref-main-container");
    let refViewMainContent = document.getElementsByClassName(
      "ref-view-main-content"
    )[0];
    let refViewBody = document.getElementsByClassName("ref-view-body")[0];

    let result_body = document.getElementsByClassName("ref-res-bd")[0];
    let activeOption = document.querySelector(".ref-choice-label.active");

    let footer = document.querySelector(".outside-footer");
    let root = document.querySelector(":root");
    let printToggle = document.querySelector("#print-toggle");
    let dropContainer = document.getElementsByClassName(
      "dropping-container-parent"
    )[0];
    let refOwnerAction = document.querySelector(".ref-action");
    let refReviewFollowBtns = document.querySelectorAll(
      ".ref-actions > button"
    );

    let refVoting = document.querySelector(".ref-voting");
    let shareContainer = document.querySelector(".share-container");

    if (activate) {
      printToggle.style.setProperty("display", "none");
      mainContent.style.height = "100%";
      mainContent.style.maxHeight = "100%";
      mainContent.style.setProperty("overflow", "visible", "important");
      refMain.style.setProperty("overflow", "visible", "important");
      refViewBody.style.setProperty("overflow", "visible", "important");
      refViewMainContent.style.setProperty("overflow", "visible", "important");
      result_body?.classList.add("pb-5");

      if (!showInfo) {
        if (activeOption) {
          activeOption?.classList.remove("active");
        }
        if (followed) {
          refReviewFollowBtns[1].classList.remove("active");
        }
      }

      if (!result_body && (shareContainer || dropContainer)) {
        refMainContainer.style.setProperty("margin-top", "10%");
      }

      if(!result_body && window.innerWidth < 1000) {
        if(!showAll) {
          mainContent.style.backgroundColor = "#fff";
          mainContent?.classList.add(
            window.innerWidth < 1000 ? "bg-img-mobile" : "bg-img"
          );
          mainContent?.style.setProperty(
            "background-size",
            window.innerWidth < 1000 ? "60%" : "105%"
          );
        } else {
          edRoot.style.backgroundColor = "#fff";
        }
      } else {
        if(showAll) {
          edRoot.style.backgroundColor = "#fff";
        } else {
          mainContent.style.backgroundColor = "#fff";
        }
        
        if(window.innerWidth < 1000) {
          if(!showAll) {
            refMain?.classList.add("bg-img-mobile");
            refMain?.style.setProperty("background-size","60%");
          }
        } else {
          refMain?.classList.add("bg-img");
          refMain?.style.setProperty("background-size", "105%");
        }
      }

      dropContainer?.style.setProperty("display", "none");

      if (shareContainer) {
        shareContainer.classList.add("d-none");
        refVoting.classList.add("pb-5");
      }

      if (refOwnerAction) {
        refOwnerAction?.classList.add("d-none");
      }

      if (!showAll) {
      } else {
        root.style.setProperty("--copyright-height", undefined);
        footer.classList.remove("d-none");
      }
    } else {
      //revert to original status
      edRoot.style.removeProperty("background-color")
      printToggle.style.removeProperty("display");
      mainContent.style.removeProperty("height");
      mainContent.style.removeProperty("max-height");
      mainContent.style.removeProperty("overflow");
      refMain.style.removeProperty("overflow");
      refViewMainContent.style.removeProperty("overflow");
      refViewBody.style.removeProperty("overflow");
      result_body?.classList.remove("pb-5");
      mainContent.style.removeProperty("background-color");
      mainContent.classList.remove("bg-img");
      mainContent.classList.remove("bg-img-mobile");
      mainContent?.style.removeProperty("background-size");
      refMain.classList.remove("bg-img");
      refMain.classList.remove("bg-img-mobile");
      refMain?.style.removeProperty("background-size");
      dropContainer?.style.removeProperty("display");

      if (!showInfo) {
        //make sure that the "active" option label will return to its old state
        if (activeChoice) {
          let choices = document.getElementsByClassName("ref-choice-label");
          if (choices.length > 0) {
            for (let i = 0; i < choices.length; i++) {
              if (choices[i].innerText === activeChoice) {
                choices[i]?.classList.add("active");
              }
            }
          }
        }
        //show followed status
        if (followed) {
          refReviewFollowBtns[1].classList.add("active");
        }
      }

      //show referendum owner action button
      if (refOwnerAction) {
        refOwnerAction?.classList.remove("d-none");
      }

      //show share container if visible
      if (shareContainer) {
        shareContainer.classList.remove("d-none");
        refVoting.classList.remove("pb-5");
      }

      if (!result_body && (shareContainer || dropContainer)) {
        refMainContainer.style.setProperty("margin-top", "0.625rem");
      }

      if (!showAll) {
      } else {
        root.style.setProperty("--copyright-height", "0.9375rem");
        handleFooter();
      }
    }
    setShowSetting(false);
  };

  const handlePrint = () => {
    handlePrintSetting();

    let source = document.getElementsByClassName("main-content")[0];
    let root = document.querySelector("#ed-root");

    let resolution = {
      width: showAll ? root.offsetWidth : source.offsetWidth,
      height: showAll ? root.offsetHeight : source.offsetHeight,
    };

    let scale = 2;

    domtoimage
      .toPng(showAll ? root : source, {
        width: window.innerWidth < 1000 ? resolution.width : resolution.width * scale,
        height: window.innerWidth < 1000 ? resolution.height : resolution.height * scale,
        style: window.innerWidth < 1000 ? {} : {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }
      })
      .then(function (dataUrl) {
        var win = window.open();
        win.document.write(
          `<div style='text-align:center; transform: scale(${ window.innerWidth < 1000 ? 1 : 0.75 }); transform-origin: top left;'><img src='` + dataUrl + `' /></div>`
        );
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!");
      });
    domtoimage
      .toBlob(showAll ? root : source, {
        width: window.innerWidth < 1000 ? resolution.width : resolution.width * scale,
        height: window.innerWidth < 1000 ? resolution.height : resolution.height * scale,
        style: window.innerWidth < 1000 ? {} : {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }
      })
      .then(function (blob) {
        window.saveAs(blob, referendum.title + ".png");
        handlePrintSetting(false);
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!");
        handlePrintSetting(false);
      });
  };

  const handleClickOutside = () => {
    setShowSetting(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div id="print-toggle" ref={ref}>
      {showSetting && (
        <motion.div
          style={{}}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className="print-setting-container"
        >
          <Radio
            name="extract"
            value="full page"
            label="Full Page"
            active={showAll ? true : false}
            onChange={handleRadio}
          />
          <Radio
            name="extract"
            value="referendum"
            label="Referendum"
            active={showAll ? false : true}
            onChange={handleRadio}
          />
          <Checkbox
            name="my-info"
            label="Show My Info"
            active={showInfo}
            onChange={handleInfo}
          />
          <div className="text-center">
            <button
              onClick={handlePrint}
              type="button"
              className="show-btn bounce"
            >
              Export
            </button>
          </div>
        </motion.div>
      )}

      <button
        onClick={() => {
          setShowSetting(!showSetting);
        }}
        id="print-toggle-btn"
        type="button"
        className={`bounce ${showSetting ? "active" : ""}`}
      >
        <img src={screenshotIcon} alt="screenshot icon" />
      </button>
    </div>
  );
}

export default PrintSetting;
