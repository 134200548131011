import Card from "react-bootstrap/Card";
import { motion } from "framer-motion";
import { initDateForPassingByServer } from "../../../helpers/date";
import auth from "../../../helpers/auth";
import { setMessageValue } from "../../../helpers/messageCounter.js";
import { useDispatch } from "react-redux";
import messageHelper from "../../../helpers/messageHelper";

import { useState } from "react";

import PreviewForm from "../preview/PreviewForm";
import ReferendumHeader from "../ReferendumHeader";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../../helpers/axiosConfig";

function ReferendumPublish(props) {
  const dispatch = useDispatch();
  const { errors, setErrors } = props;

  const navigate = useNavigate();
  const [publishing, setPublishing] = useState(false);

  const {
    referendum,
    setReferendum,
    setShowPreview,
    showPreview,
  } = props;

  const isPublishing = publishing;

  function onPublish(e) {
    e.preventDefault();

    let errorTrackers = { ...errors };

    if (isPublishing) return;
    setPublishing(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums`:"https://api.onepeople.online/op/v1/referendums";
    axiosConfig
      .post(
        url,
        {
          title: referendum.title,
          choices: referendum.options.map((option) => option.option),
          category: referendum.categories[0].value,
          country: referendum.country.value,
          // closing_datetime: initDateForPassing(
          //   dateByTimezone(referendum.end_at)
          // ),
          closing_datetime: initDateForPassingByServer(referendum.end_at)
        },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          navigate(`/referendums/${res.data.referendum.slug}/`);
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        let errors = err.response.data.errors;
        if (errors) {
          if (errors[0].msg === "connect ETIMEDOUT") {
            errorTrackers.others = "Something went wrong. Please try again.";
          } else {
            errors.map((obj, index) => {
              let params = obj.path;
              if (params) {
                if (params === "title") {
                  errorTrackers.title = obj.msg;
                } else if (params === "choices") {
                  errorTrackers.choices = obj.msg;
                } else if (params === "category") {
                  errorTrackers.choices = obj.msg;
                } else if (params === "country") {
                  errorTrackers.country = obj.msg;
                } else if (params === "closing_datetime") {
                  errorTrackers.date = obj.msg;
                } else {
                  errorTrackers.others = obj.msg;
                }
              } else {
                errorTrackers.others = obj.msg;
              }
              return obj;
            });
          }
        } else {
          errorTrackers.others = "Something went wrong. Please try again.";
        }
        setErrors(errorTrackers);
        setShowPreview(false);
      })
      .finally(() => {
        messageHelper.getMessageCount(getMessageUpdate);
      });
  }

  
  function getMessageUpdate(ctr) {
    dispatch(setMessageValue(ctr))
  }

  function onBack() {
    if(isPublishing) return;

    setReferendum({});
    navigate(-1);
    setShowPreview(false);
  }

  return (
    <div className=" col-12 col-md-12 col-lg-12 col-xl-11 col-xxl-9 px-4">
      <Card
        style={{
          minHeight: "18.75rem",
          minWidth: "100%",
          borderRadius: "1.875rem",
          paddingTop: "1.875rem",
          paddingBottom: "1.25rem",
          height: "auto",
          marginTop: referendum.ended || showPreview ? "0.625rem" : "10rem",
        }}
        className="ref-publish border-0 op-box px-4 px-lg-5 overflow-hidden justify-content-center d-flex flex-column"
      >
        <ReferendumHeader
          referendum={referendum}
          setReferendum={setReferendum}
          isPreview={true}
        />
        <Card.Body className="p-0 ref-view-body row justify-content-center mz-scroll">
          <div className="col-12 col-md-12 col-lg-12">
            <h1 className="ref-title text-center mt-3 mt-md-3 mt-lg-5">
              <span className="ref-title-icon me-3">&#128499;</span>
              <span>{referendum && referendum.title && referendum.title}</span>
            </h1>
            <div className="ref-voting mt-5">
              <PreviewForm referendum={referendum} />
            </div>
          </div>
          
          <div className="col-11 col-md-11 col-lg-10 ">
            <hr className="mt-5" />
          </div>

          <div className="col-12 col-md-8 col-lg-6">
            <div className="mt-4 mb-4 text-center">
              <p className="text-black op-text">
                This is a preview of your referendum. Please note that the
                referendum may not be amended once it is published, but it can be
                deleted.
              </p>
              <p className="text-black op-text">
                Do you wish to proceed with the publishing of this referendum?
              </p>
              <form className="mt-3">
                <div className="mt-5 text-center d-flex justify-content-center">
                  <motion.input
                    initial={{}}
                    animate={
                      publishing
                        ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                        : {}
                    }
                    onClick={onPublish}
                    type="submit"
                    name="submit"
                    value="Publish"
                    className="op-btn confirm-btn bounce mx-3"
                  />

                  <button onClick={(e) => onBack()} type="button" className="back-btn mx-3" 
                    style={publishing ? {cursor: "not-allowed"} : undefined } 
                  >
                    Amend
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ReferendumPublish;
