import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { motion } from "framer-motion";
import { setSelectedCountry } from "../../helpers/exploreSelection";
import { useDispatch, useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";
import countryCacheHelper from "../../helpers/countryCacheHelper";
function OPSelectCountry({options,select,placeholder,changestate}){
   const [selected,setSelected] = useState(null);
   const [inputState,setInputState] = useState(false);
   const dropOn = {
      height:"auto",
      duration:0.1
   }
   const countrystate = useSelector(state => state.catandcountry.country)
   const dropOff = {
      height:"0",
      // overflow:"hidden"
   }
   // const [queryParameters] = useSearchParams();
  //  let search_country = queryParameters.get("country");
   // console.log(search_country)
   const dispatch = useDispatch();

   let list = [];
   const selectThis = (data)=>{
    
         setSelected(data.text);
         setInputState(!inputState) 
         dispatch(setSelectedCountry(data.value))
         // setSearchCountry(data.value)
         select.current.value = data.value
         changestate()
   }
   const pathname = window.location.pathname;
   useEffect(()=>{
      let cts = getCacheCountries();
      let paths_arr = window.location.pathname
        .split("/")
        .filter((str) => str.length > 0);
      let search_country = null;

      if (paths_arr.length > 1) {
        paths_arr.map((path) => {
          if (path !== "explore") {
            let country_obj = cts.filter((ct) => ct.slug === path);
            if (country_obj.length > 0) {
              search_country = path;
            }
          }

          return path;
        });
      }

      if(options[0].value !== null) {
         options.unshift({value:null,text:placeholder})
      }
      options.forEach((a,i)=>{
         if(search_country === a.value){
            setSelected(a.text);
            dispatch(setSelectedCountry(a.value))
            select.current.value = a.value
         }
       
      })

      // console.log(select.current.value);
      
   },
   // eslint-disable-next-line
   [pathname])

   
  function getCacheCountries() {
    let cacheCountry = countryCacheHelper.get();
    if (countryCacheHelper.get().length !== 0) {
      return cacheCountry;
    }
    return [pathname];
  }

  if(options[0].value !== null) {
     list.push(<li id={`cat-id-${''}`} key={''} className={placeholder === selected ? "active":""} onClick={(e)=>{e.stopPropagation(); selectThis({value: "", text: placeholder})}}>{placeholder}</li>)
  }
   options.forEach(function(a,i){
      list.push(<li id={`cat-id-${a.value}`} key={a.value} className={a.text === selected ? "active":""} onClick={(e)=>{e.stopPropagation(); selectThis(a)}}>{a.text}</li>)
   })

   // console.log(list)
   return(<>
      <input type="hidden" ref={select} ></input>
      {/* <button onClick={()=>{console.log(select.current.value)}}>test</button> */}
      <div className="OPselectBox position-relative" onClick={()=>{setInputState(!inputState); countrystate != null && document.getElementById(`cat-id-`+countrystate).scrollIntoView({ behavior: 'smooth', block: 'nearest'})}}>
            <div className="selected-box text-nowrap">
               {selected == null? placeholder :selected}
               <span className="carret">
               <FontAwesomeIcon icon={solid("caret-down")}   />
               </span>
               
            </div>
            <motion.div onClick={(e)=>e.stopPropagation()} initial={{height:"0"}}  animate={inputState ? dropOn:dropOff} className="drop position-absolute mz-scroll">
               <ul className="p-0 m-0">
                  {list}
               </ul>
            </motion.div>
      </div>
   </>);
}

export default OPSelectCountry;