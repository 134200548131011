import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import OPSpinner from "../../others/OPSpinner";
import auth from "../../../helpers/auth";
import axiosConfig from "../../../helpers/axiosConfig";

function EmailVerification(props) {
  let { email,userData } = props; 
  const [step, setStep] = useState("start");
  const [errorMsg,setErrorMsg] = useState(null);
  
  const sendEmailVerification = (e) => {
    e.preventDefault();
    setStep("loader");
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3000/op/v1/email_verification_send":"https://api.onepeople.online/op/v1/email_verification_send";
    axiosConfig
      .post(
        url,
        {
          id: auth.getId(),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((e) => {
       if( e.data !== undefined){
        if(["invalid","failed_to_sent","hard_bounce","soft_bounce"].includes(e.data.success)){
          setStep("invalid");
        }else{
          setStep("sent");
        }
       }
        

        
      })
      .catch((e) => {
        auth.checkErrors(e);
        let error;
        setStep('start');
        if(e.response.data !== undefined){
          error = e.response.data.errors[0].msg;
            setErrorMsg(error);
        }else{
           setErrorMsg('Oops! Something went wrong!');
        }
      });
  };
  useEffect(function(){
    if(userData.disable_email  === 1 ){
      setStep("invalid");
    }else{
      setStep("start");
    }
  },[userData]);

  const Step = () => {
    switch (step) {
      case "start":
        return (
          <div className="FS14 mt-5 fblack">
            <p className="fblack">Your email has not been verified.</p>
            <p className="fblack">
              Please verify your email so we can still send a 2FA code to your
              email, just in case you are unable to access your previously
              registered authenticator app.
            </p>
            <p className="fblack">
              Would you like to send a verification email to{" "}
              <a href="/#" className="fbold fblack">
                {email}
              </a>
              ?
            </p>
          


         
               <div className="mt-4 mb-3 OPGroupButton d-flex ">
               <div className="  mx-auto justify-content-center d-flex flex-wrap">
                  {errorMsg !== null && 
              <p className="text-red col-12">
                {errorMsg}
              </p>
             }
                 <button
                   className="default-button flex-fill img-fluid "
                   onClick={sendEmailVerification}
                 >
                   Send
                 </button>
               </div>
             </div>

          </div>
        );
        // eslint-disable-next-line
        break;

      case "loader":
        return (
          <>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "9.375rem" }}
            >
              <div>
                <div className="FS14 fblack mt-5">Sending Email Verification</div>
                <div className="d-flex justify-content-center">
                  <OPSpinner />
                </div>
                <div className="FS14 fblack">Please wait...</div>
              </div>
            </div>
          </>
        );
        // eslint-disable-next-line
        break;
      case "invalid":
          return (
            <div className="FS14 mt-5 fblack">
            
              <p className="fblack">
                We are unable to send you an email because there may be a problem with your email service provider.
              </p>
              <p className="fblack">
                Please indicate an alternative email address that we can send an email to.
              </p>
              <p className="fblack">
                You may change your email address by updating My Details.
              </p>
              {/* <button onClick={testVerified}>Test</button> */}
            </div>
          );
        // eslint-disable-next-line
        break;
      case "sent":
        return (
          <div className="FS14 mt-5 fblack">
            <p className="fblack">Email verification has been sent.</p>
            <p className="fblack">
              Please click on the link in the email to verify your email address.
            </p>
            {/* <button onClick={testVerified}>Test</button> */}
          </div>
        );
        // eslint-disable-next-line
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Card className="profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
        <Card.Body className="text-center">
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="mb-0 fw-semibold text-left text-black">📧 Email Verification</h5>
          </div>
          <Step />
        </Card.Body>
      </Card>
    </>
  );
}
export default EmailVerification;
