import { useEffect, useState } from "react";

function Counter({ min, max }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if(max !== 0) {
      setTimeout(() => {
        let i = 0;
        let increment = max % 2 === 1 ? 1 : 2;
        let timer = setInterval(() => {
          i = i + increment;
          setCounter(i);
          if (i === max) {
            clearInterval(timer);
          }
        });
      }, 100);
    }
  }, 
  // eslint-disable-next-line
  []);
  return <>{counter}</>;
}

export default Counter;
