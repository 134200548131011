import { useEffect, useState } from "react";

function CountDown ({timer, setStatus}) {
    const [counter, setCounter] = useState(timer ?? 60);

    useEffect(() => {
        if(counter > 0 ) {
            setTimeout(() => setCounter(counter - 1), 1000);
            setStatus(true)
        } else {
            setStatus(false);
        }
    }, 
    // eslint-disable-next-line
    [counter]);

    return (
        <>{counter > 0 ? `(${counter})` : ""}</>
    )
}

export default CountDown;