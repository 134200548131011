import { Card } from "react-bootstrap"
export default function EmailVerified(){
   return (<>
     <Card className="profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
        <Card.Body className="text-center">
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="mb-0 fw-semibold text-left text-black">📧 Email Verification</h5>
          </div>
          <div className="FS14 mt-5 fblack">
            Your email has been successfully verified!
          </div>
          <div className="FS14 mt-3 fblack">
            You will have to verify your email again if you change it in the future.
          </div>
          <div className="mb-5">

          </div>
       
        </Card.Body>
      </Card>
   </>)

}