var localStorageHelper = {
   setItem: function(key, value) {
     localStorage.setItem(key, JSON.stringify(value));
   },
 
   getItem: function(key) {
     var value = localStorage.getItem(key);
     if (value !== null) {
       return JSON.parse(value);
     }
     return null;
   },
 
   updateItem: function(key, value) {
     // Check if the item already exists
     if (localStorageHelper.getItem(key) !== null) {
       // Update the item with the new value
       localStorageHelper.setItem(key, value);
     }
   },
 
   removeItem: function(key) {
     // If the item exists, remove it from localStorage
     if (localStorageHelper.getItem(key) !== null) {
       localStorage.removeItem(key);
     }
   }
 };

 export default localStorageHelper;