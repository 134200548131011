import { motion } from "framer-motion";
import { useState } from "react";

import ModalHeader from "../others/ModalHeader";
import check from "../../assets/images/others/check.svg"

function ModalRegisterWelcome(props) {
  const { setShowMainModal } = props;
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);

  const toggleOffInContainer = () => {
    setShowMainModal(false);
  };

  return (
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="op-box p-3 py-5 welcome OPModalBox position-absolute mx-auto"
      >
        <ModalHeader
          title="Welcome to OnePeople"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3  login-register align-items-center justify-content-center`}
        >
          <div className="register-container col-12 col-md-6 col-lg-6 px-5">
            <div className="position-relative">
              <h4 className="d-block px-1 text-black text-center modal-sub-header">
                <span className="me-2">&#128221;</span>
                Account Created
              </h4>
              <div
                className="mt-5 text-center d-flex align-items-center justify-content-center mx-auto"
                style={{
                  width: "7.8125rem",
                  height: "7.8125rem",
                  backgroundColor: "#3FA778",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={check}
                  style={{ width: "4.6875rem", height: "4.6875rem" }}
                  alt="check mark"
                />
              </div>
              <div className="form-check mt-5">
                <p className="text-center auth-text">
                  Your account has been created and your 2FA has been set
                  up.
                </p>
              </div>
              <div className="form-check mt-3">
                <p className="text-center auth-text">
                  Welcom to OnePeople, and have fun exploring the wisdom
                  of the crowds!
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
export default ModalRegisterWelcome;
