import { useState, useRef, useEffect } from "react";
import validator from "validator";
import {
  Col,
} from "react-bootstrap";
import { motion } from "framer-motion";
import axiosConfig from "../../helpers/axiosConfig";
import InputCounter from "../referendums/InputCounter";
export default function ContactUsForm() {
  const [sendingStatus, setSending] = useState(false);
  const [submitted, setSubmit] = useState(false);
  const [screen, setScreen] = useState(0);
  // error
  const [email_err, setEmailError] = useState({});
  const [msg_err, setMsgError] = useState({});
  const email = useRef();
  const msg = useRef();

  const [enquiryMsg, setEnquiryMsg] = useState("");
  const validateEmail = () => {
    let temp_error = [];

    if (validator.isEmpty(email.current.value)) {
      temp_error.push("Email must not be empty");
    }
    if (!validator.isEmail(email.current.value)) {
      temp_error.push("Email is not valid!");
    }
    if (temp_error.length > 0) {
      setEmailError({ ...email_err, error: temp_error });
    } else {
      setEmailError({});
    }
    return temp_error;
  };

  const onChangeEnquiryMsg = (e) => {
    validateMSG(e.target.value);
    if (e.target.value.length <= 800) {
      setEnquiryMsg(e.target.value);
    }
  }
  const validateMSG = (str = null) => {
    let temp_error = [];
    str = msg.current.value;
    if (validator.isEmpty(str)) {
      temp_error.push("Enquiry must not be empty");
    }
    if (!validator.isLength(str, { min: 5 })) {
      temp_error.push("Minimum of 5 characters");
    }
    if (temp_error.length > 0) {
      setMsgError({ ...msg_err, error: temp_error });
    } else {
      setMsgError({});
    }
    return temp_error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let email_error = validateEmail();
    let msg_error = validateMSG();

    if (email_error.length > 0 || msg_error.length > 0) {
      return false;
    } else {
      setSending(!sendingStatus);
      //  setTimeout(function(){
      //    setSending(false);
      //    setSubmit(true)
      //  },2000)
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3001/op/v1/contact_us":  "https://api.onepeople.online/op/v1/contact_us";
      axiosConfig
        .post(url, {
          message: enquiryMsg,
          email: email.current.value,
        })
        .then(() => {
          setSending(false);
          setSubmit(true);
        });
    }
  };
  const onResize = () => {
    let width = document.documentElement.clientWidth;

    setScreen(width);
  };
  useEffect(() => {
    window.addEventListener("resize", onResize);

    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <>
      {screen > 1000 && (
        <Col md={12} lg={10} xl={9} className="cu-form-right mx-auto">
          {submitted ? (
            <>
              <div
                className="text-center d-flex flex-wrap justify-content-center align-items-center"
                style={{ minHeight: "200px" }}
              >
                <div>
                  <h4 className="mb-3">Thank you!</h4>
                  <div>
                    <p>We will get back to you shortly.</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <form className="contact-us-form mb-3" onSubmit={handleSubmit}>
              <div className="mb-3 OPinput-group d-flex flex-column fGray">
                <label
                  htmlFor="email"
                  className={
                    "mb-1 " +
                    (email_err.error !== undefined && email_err.error.length > 0
                      ? "text-red"
                      : "")
                  }
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className={
                    email_err.error !== undefined && email_err.error.length > 0
                      ? "border-red  hl-border"
                      : "hl-border"
                  }
                  ref={email}
                  disabled={sendingStatus}
                  onKeyUp={validateEmail}
                  autoComplete="off"
                />
                {email_err.error !== undefined && email_err.error.length > 0 && (
                  <div className="text-red FS12 mt-1">{email_err.error[0]}</div>
                )}
              </div>
              <div className="OPinput-group d-flex flex-column position-relative">
                <label
                  htmlFor="enquiry"
                  className={
                    "fGray " +
                    (msg_err.error !== undefined && msg_err.error.length > 0
                      ? "text-red"
                      : "")
                  }
                >
                  Enquiry
                </label>
                <textarea
                  id="enquiry"
                  name="enquiry"
                  value={enquiryMsg}
                  // style={{ minHeight: "5.5rem" }}
                  // onKeyUp={validateMSG}
                  onChange={onChangeEnquiryMsg}
                  className={
                    msg_err.error !== undefined && msg_err.error.length > 0
                      ? "border-red hl-border"
                      : "hl-border"
                  }
                  disabled={sendingStatus}
                  rows="5"
                  ref={msg}
                ></textarea>
                <InputCounter max={800} inputText={enquiryMsg} />
              </div>
                {msg_err.error !== undefined && msg_err.error.length > 0 && (
                  <div className="text-red FS12 mt-1">{msg_err.error[0]}</div>
                )}
              <div className="mb-4"></div>
              <div className="d-flex justify-content-center flex-wrap">
                {sendingStatus && (
                  <div className="col-12 text-center">
                    <p>Please wait...</p>
                  </div>
                )}
                <motion.button
                  className="btn btn-primary"
                  disabled={sendingStatus}
                >
                  {sendingStatus ? "Submitting..." : "Submit"}
                </motion.button>
                {/* <Button className=""  >Submit</Button> */}
              </div>
            </form>
          )}
        </Col>
      )}
    </>
  );
}
